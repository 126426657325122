import { useLazyQuery, useSubscription } from "@apollo/client";
import { MutationFetchPolicy } from "@apollo/client/core/watchQueryOptions";
import { addMonths, firstDayOfMonth, lastDayOfMonth } from "@progress/kendo-date-math";
import { Button, FileType, FileUploader, Sizes } from "@sede-x/shell-ds-react-framework";
import { Add } from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";
import { MackUser } from "auth";
import { useMackAuth } from "auth/AuthenticationProvider";
import { loader } from "graphql.macro";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ApolloErrorViewer } from "shared/components/ApolloErrorViewer";
import GlobalHeader from "shared/components/GlobalHeader";
import InlineLoadingPanel from "shared/components/InlineLoadingPanel";
import LoadingPanel from "shared/components/LoadingPanel";
import { usePicklists } from "ticketing/contexts/picklists/PicklistContextProvider";
import {
  GqlResponse,
  TBatch,
  TBatchSearchCriteria,
  TMovement,
  TMovementGroup,
  TPicklists,
  TicketStatus
} from "ticketing/ticketing.types";
import {
  MAX_FILE_SIZE,
  STCAN_LE,
  createMovementGroups,
  equalsIgnoreCase,
  getCancelledMovementStatusId,
  hasCreditRedLine,
  isActualizedByInfoSet,
  isActualizedExternally,
  isAutoActualizeInfoSet,
  isPlannedMovement,
  toBatchFilterInput,
  toMovementFilterInput,
  transformMovements
} from "ticketing/utils";
import EnterpriseSystemSelect from "../EnterpriseSystemSelect";
import SuccessNotification from "../SuccessNotification";
import "../Ticketing.css";
import AddOrUpdateTicket from "../tickets/AddOrUpdateTicket";
import BatchContainer from "./BatchContainer";
import MovementGroupContainer from "./MovementGroupContainer";
import SearchMovementsOrTicketsContainer from "./SearchMovementsOrTicketsContainer";
import TicketContainer from "./TicketContainer";
import { MovementsMainContext } from "./utils";

const firstDayOfPrevMonth = () => firstDayOfMonth(addMonths(firstDayOfMonth(new Date()), -1));
const lastDayOfNextMonth = () => lastDayOfMonth(addMonths(lastDayOfMonth(new Date()), 1));

const defaultLegalEntities = (picklists?: TPicklists, user?: MackUser | null) => {
  if (user?.mackUser?.defaultLegalEntityId) {
    return (
      picklists?.legalEntities?.filter(l => l.id === user.mackUser.defaultLegalEntityId) ?? []
    );
  }
  return picklists?.legalEntities?.filter(l => equalsIgnoreCase(l.name, STCAN_LE)) ?? [];
};

const initSearchCriteria = (
  picklists?: TPicklists,
  criteria?: TBatchSearchCriteria | null,
  user?: MackUser | null
): TBatchSearchCriteria => {
  return {
    statusId: getCancelledMovementStatusId(picklists?.movementStatuses),
    startDate: criteria?.startDate ?? firstDayOfPrevMonth(),
    endDate: criteria?.endDate ?? lastDayOfNextMonth(),
    products: criteria?.products ?? [],
    deliveryIds: criteria?.deliveryIds,
    counterParties: criteria?.counterParties ?? [],
    facilities: criteria?.facilities ?? [],
    batches: criteria?.batches ?? [],
    legalEntities: criteria?.legalEntities ?? defaultLegalEntities(picklists, user),
    carriers: criteria?.carriers ?? [],
    modeOfTransports: criteria?.modeOfTransports ?? [],
    logisticsSystems: [],
    railcarNumbers: criteria?.railcarNumbers,
    ticketNumbers: criteria?.ticketNumbers,
    ticketStatus: TicketStatus.Unlinked,
    shipFromCodes: criteria?.shipFromCodes ?? [],
    shipToCodes: criteria?.shipToCodes ?? [],
    includeNulls: criteria?.includeNulls ?? false
  };
};
const MOVEMENT_SUBSCRIPTION = loader(
  "../../ticketing-graphql/subscriptionUpdateMovement.graphql"
);
const BATCHES_SEARCH = loader("../../ticketing-graphql/batchSearch.graphql");
const MOVEMENTS_SEARCH = loader("../../ticketing-graphql/movementSearch.graphql");
const MOVEMENT_BY_ID = loader("../../ticketing-graphql/getMovementById.graphql");
const MOVEMENTS_OF_BATCH_SEARCH = loader("../../ticketing-graphql/movementsOfBatch.graphql");
const CHILDREN_OF_MOVEMENT = loader("../../ticketing-graphql/childrenOfMovement.graphql");

type SubscriptionMovementUpdate = {
  id: string;
  version: number;
};

type SubscriptionMovementUpdateResponse = GqlResponse<
  SubscriptionMovementUpdate,
  "movementUpdates"
>;
type BatchSearchResponse = GqlResponse<TBatch[], "batchesFilterBy">;
type MovementSearchResponse = GqlResponse<TMovement[], "movementsFilterBy">;

type MovementByIdResponse = GqlResponse<TMovement, "movement">;
type MovementWithChildResponse = GqlResponse<TMovement, "movement">;
type MovementOfBatchResponse = GqlResponse<Partial<TBatch>, "batch">;

const PADDING = 12;
const FETCH_POLICY_NO_CACHE = {
  fetchPolicy: "no-cache" as MutationFetchPolicy
};

type TNewTicketState = {
  pdfFile?: File;
  showNewTicket?: boolean;
  successMessage?: string;
  showSuccessMessage?: boolean;
};

const setChildren = (movement: Partial<TMovement>, groups?: TMovementGroup[] | null) => {
  const exMovement = groups?.flatMap(g => g.movements).find(m => m.id === movement.id);
  if (exMovement) {
    return groups?.map(mg => ({
      ...mg,
      movements: mg.movements.map(m =>
        exMovement.id === m.id ? Object.assign(m, { children: movement.children }) : m
      )
    }));
  }

  return groups;
};

const MovementsMainContainer = () => {
  const { mackUser } = useMackAuth();
  const {
    picklists,
    isLoading: picklistsLoading,
    isReady: picklistsReady,
    errors: picklistErrors
  } = usePicklists();
  const [batchSearchCriteria, setBatchSearchCriteria] = useState<TBatchSearchCriteria>();
  const [searchFormOpen, setSearchFormOpen] = useState(true);
  const [searchCalled, setSearchCalled] = useState(false);
  const searchFormRef = useRef<HTMLDivElement>(null);
  const [newTicketState, setNewTicketState] = useState<TNewTicketState>({
    showNewTicket: false
  });
  const [batches, setBatches] = useState<TBatch[]>();
  const [activeBatch, setActiveBatch] = useState<TBatch | null>();
  const [movementGroups, setMovementGroups] = useState<TMovementGroup[] | null>();
  const [activeMovementGroup, setActiveMovementGroup] = useState<TMovementGroup | null>(null);

  useSubscription<SubscriptionMovementUpdateResponse>(MOVEMENT_SUBSCRIPTION, {
    ignoreResults: true, //stop rerendring
    onData: response => {
      const sMovementUpdate = response.data.data?.movementUpdates;
      if (
        sMovementUpdate &&
        movementGroups
          ?.flatMap(g => g.movements)
          .some(m => m.id === sMovementUpdate.id && m.version !== sMovementUpdate.version)
      ) {
        getMovementById({ variables: { id: sMovementUpdate.id } });
      }
    }
  });

  const [searchBatches, { loading: batchesLoading, error: batchesSearchError }] =
    useLazyQuery<BatchSearchResponse>(BATCHES_SEARCH, {
      ...FETCH_POLICY_NO_CACHE,
      onCompleted: data => onBatchFetchCompleted(data.batchesFilterBy)
    });

  const [
    getMovementsOfBatch,
    { loading: movementsOfBatchLoading, error: movementsOfBatchError }
  ] = useLazyQuery<MovementOfBatchResponse>(MOVEMENTS_OF_BATCH_SEARCH, {
    ...FETCH_POLICY_NO_CACHE,
    onCompleted: data =>
      setBatches(bs => bs?.map(b => (b.id === data.batch.id ? { ...b, ...data.batch } : b)))
  });

  const [searchMovements, { loading: movementsLoading, error: movementsError }] =
    useLazyQuery<MovementSearchResponse>(MOVEMENTS_SEARCH, {
      ...FETCH_POLICY_NO_CACHE,
      onCompleted: data => setMovementGroups(createMovementGroups(data.movementsFilterBy))
    });

  const [getMovementById] = useLazyQuery<MovementByIdResponse>(MOVEMENT_BY_ID, {
    ...FETCH_POLICY_NO_CACHE,
    onCompleted: data => onMovementsUpdated(Array.of(data.movement))
  });

  const [childrenOfMovement, { loading: childrenLoading, error: childrenError }] =
    useLazyQuery<MovementWithChildResponse>(CHILDREN_OF_MOVEMENT, {
      ...FETCH_POLICY_NO_CACHE,
      onCompleted: data => setMovementGroups(setChildren(data.movement, movementGroups))
    });

  const hideSuccessNotification = () =>
    setNewTicketState(state => ({ ...state, showSuccessMessage: false }));

  const handleReset = () => setBatchSearchCriteria(undefined);

  useEffect(() => {
    const html = document.querySelector("html");
    if (html) {
      html.style.overflow = newTicketState.showNewTicket ? "hidden" : "auto";
    }
  }, [newTicketState.showNewTicket]);

  const onNewTicket = useCallback((acceptedFiles?: File[]) => {
    if (acceptedFiles && acceptedFiles[0] && acceptedFiles[0].size > MAX_FILE_SIZE) {
      //too large...
      return;
    }
    setNewTicketState({ showNewTicket: true, pdfFile: acceptedFiles?.[0] });
  }, []);

  const getBatchesForSearch = useCallback(
    (criteria: TBatchSearchCriteria) => {
      if (picklists?.enterpriseSystemId) {
        searchBatches({
          variables: {
            filter: toBatchFilterInput(criteria, picklists?.enterpriseSystemId)
          }
        });
      }
    },
    [searchBatches, picklists?.enterpriseSystemId]
  );

  const handleSearch = useCallback(
    (criteria: TBatchSearchCriteria) => {
      setSearchCalled(false);
      setBatches([]);
      setActiveBatch(null);
      setMovementGroups([]);
      setActiveMovementGroup(null);
      setBatchSearchCriteria(criteria);
      getBatchesForSearch(criteria);
    },
    [getBatchesForSearch]
  );

  const onBatchSelected = useCallback(
    (batch: TBatch) => {
      setActiveBatch(batch);
      setMovementGroups([]);
      setActiveMovementGroup(null);
      const cancelledStatusId = getCancelledMovementStatusId(picklists?.movementStatuses);

      if (batchSearchCriteria && picklists?.enterpriseSystemId && cancelledStatusId) {
        const movementFilter = toMovementFilterInput(
          batchSearchCriteria,
          batch.id,
          picklists?.enterpriseSystemId
        );
        movementFilter.statusId = { ne: cancelledStatusId };
        searchMovements({
          variables: {
            movementsFilter: movementFilter
          }
        });
      }

      if (!batch.movements) {
        getMovementsOfBatch({ variables: { batchId: batch.id } });
      }
    },
    [
      batchSearchCriteria,
      getMovementsOfBatch,
      searchMovements,
      picklists?.enterpriseSystemId,
      picklists?.movementStatuses
    ]
  );

  const onBatchFetchCompleted = useCallback(
    (fetchedBatches: TBatch[]) => {
      setBatches(fetchedBatches);
      setSearchCalled(true);
      if (fetchedBatches && fetchedBatches.length > 0) {
        onBatchSelected(fetchedBatches[0]);
      }
    },
    [onBatchSelected]
  );

  const onBatchUpdated = useCallback(
    (batch: TBatch) => {
      setBatches(batches?.map(b => (b.id === batch.id ? batch : b)));
      setActiveBatch(batch);
      //update batch in all movements
      setMovementGroups(
        movementGroups?.map(mg => ({
          ...mg,
          movements: mg.movements.map(m => ({ ...m, batch }))
        }))
      );
    },
    [batches, movementGroups]
  );

  const onMovementsUpdated = useCallback(
    (movements: TMovement[]) => {
      const transformedMovements = transformMovements(movements);
      const find = (movement: TMovement) =>
        transformedMovements.find(m => m.id === movement.id) ?? movement;
      const newMovementGroups = movementGroups?.map(mg => ({
        ...mg,
        movements: mg.movements.map(m => find(m)),
        activeMovement: find(mg.activeMovement)
      }));
      setMovementGroups(newMovementGroups);
      if (activeMovementGroup) {
        setActiveMovementGroup({
          ...activeMovementGroup,
          movements: activeMovementGroup.movements.map(m => find(m)),
          activeMovement: find(activeMovementGroup.activeMovement)
        });
      }
      //update batches so that Batch can show latest transitComplete status
      setBatches(bs =>
        bs?.map(batch => {
          const movementOfBatch = movements.filter(m => m.batch.id === batch.id);
          return movementOfBatch.length > 0
            ? {
                ...batch,
                movements: batch.movements?.map(
                  m => movementOfBatch.find(mb => mb.id === m.id) ?? m
                )
              }
            : batch;
        })
      );
    },
    [activeMovementGroup, movementGroups]
  );

  const onActiveMovementGroupChanged = useCallback(
    (movementGroup: TMovementGroup | null) => {
      const isModified = (a: TMovementGroup, b: TMovementGroup) =>
        a.groupId !== b.groupId ||
        a.activeMovement.id !== b.activeMovement.id ||
        a.activeChild?.id !== b.activeChild?.id;

      if (
        activeMovementGroup == null ||
        movementGroup == null ||
        isModified(activeMovementGroup, movementGroup)
      ) {
        setMovementGroups(mg =>
          mg?.map(g => (g.groupId === movementGroup?.groupId ? movementGroup : g))
        );
        setActiveMovementGroup(movementGroup);
        if (
          movementGroup?.activeMovement != null &&
          movementGroup?.activeMovement.children == null
        ) {
          //load children
          childrenOfMovement({ variables: { id: movementGroup.activeMovement.id } });
        }
      }
    },
    [activeMovementGroup, childrenOfMovement]
  );
  const [top, setTop] = useState<number>(0);

  useEffect(() => {
    setTop(
      (searchFormRef?.current?.getBoundingClientRect().top ?? 0) +
        (searchFormRef?.current?.getBoundingClientRect().height ?? 0) +
        PADDING
    );
  }, [searchFormRef, searchFormOpen, searchCalled]);

  const onCloseAddOrUpdateTicket = () => {
    setNewTicketState({});
  };
  const onSuccessAddOrUpdateTicket = (movements: TMovement[] | undefined): void => {
    setNewTicketState({ successMessage: "Save Successful", showSuccessMessage: true });
    if (movements && movements.length > 0) {
      onMovementsUpdated(movements);
    }
  };

  const getSearchCriteria = () => {
    return batchSearchCriteria ?? initSearchCriteria(picklists, null, mackUser);
  };

  const movementsMainContext = useMemo(
    () => ({
      onBatchSelected,
      onBatchUpdated,
      onMovementsUpdated,
      onActiveMovementGroupChanged
    }),
    [onBatchSelected, onBatchUpdated, onMovementsUpdated, onActiveMovementGroupChanged]
  );

  if (picklistsLoading || !picklistsReady) {
    return <LoadingPanel />;
  }

  if (picklistErrors?.length) {
    return <ApolloErrorViewer error={picklistErrors} />;
  }

  const isTransitComplete =
    activeMovementGroup?.activeMovement?.batch?.transitComplete ?? false;

  const disableNewTicket =
    !!activeMovementGroup &&
    [
      !!activeMovementGroup.activeChild,
      isTransitComplete,
      isPlannedMovement(activeMovementGroup),
      isActualizedExternally(activeMovementGroup),
      isAutoActualizeInfoSet(activeMovementGroup),
      isActualizedByInfoSet(activeMovementGroup),
      hasCreditRedLine(activeMovementGroup)
    ].some(r => r);

  return (
    <MovementsMainContext.Provider value={movementsMainContext}>
      <div id="Ticketing" className="tickets-page">
        <GlobalHeader
          pageName="Movements"
          buttonContent={[<EnterpriseSystemSelect key={1} />]}
        />
        <div
          style={{
            backgroundColor: "var(--nav-bg)",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingRight: "4px",
            gap: "8px"
          }}>
          <div style={{ alignItems: "center", flexGrow: 2 }}>
            <FileUploader
              acceptedFileTypes={[".pdf"]}
              dropFilesAction={onNewTicket}
              fileType={FileType.Single}
              size={Sizes.ExtraSmall}
              disabled={disableNewTicket}
              showIcon={false}
              showButton={false}
              showDescription={false}
              removeFileAction={onCloseAddOrUpdateTicket}
              //changing this key creates new component... which creates new state
              //Bad idea - but no option until we upgrade to the next version of SDS
              key={newTicketState.pdfFile?.name}
            />
          </div>
          <div>
            <Button
              icon={<Add />}
              sentiment="positive"
              onClick={_e => onNewTicket()}
              disabled={disableNewTicket}>
              Add Ticket
            </Button>
          </div>
        </div>
        <div ref={searchFormRef}>
          <SearchMovementsOrTicketsContainer
            searchCriteria={getSearchCriteria()}
            onSearch={handleSearch}
            searchFor={"Movements"}
            open={searchFormOpen}
            onCollapsed={collapsed => setSearchFormOpen(collapsed)}
            onReset={handleReset}
          />
        </div>
        {(batchesLoading || movementsOfBatchLoading) && <InlineLoadingPanel />}
        {batchesSearchError && <ApolloErrorViewer error={batchesSearchError} />}
        {movementsOfBatchError && <ApolloErrorViewer error={movementsOfBatchError} />}
        {childrenError && <ApolloErrorViewer error={childrenError} />}
        {searchCalled && (
          <div
            style={{
              height: `calc(100vh - ${top}px)`,
              boxSizing: "border-box"
            }}>
            <div className="movements-main-container">
              <div className="batch-container-wrapper">
                <BatchContainer batches={batches} selectedBatch={activeBatch} />
              </div>
              <div className="movement-group-container-warpper">
                <MovementGroupContainer
                  movementGroups={movementGroups}
                  activeMovementGroup={activeMovementGroup}
                  loading={movementsLoading || childrenLoading}
                  error={movementsError}
                />
              </div>
              {activeMovementGroup && (
                <div className="ticket-container-wrapper">
                  <TicketContainer activeMovementGroup={activeMovementGroup} />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {newTicketState.showNewTicket && (
        <AddOrUpdateTicket
          onCancel={onCloseAddOrUpdateTicket}
          activeMovementGroup={activeMovementGroup}
          selectedPdfFile={newTicketState.pdfFile}
          onSuccess={onSuccessAddOrUpdateTicket}
        />
      )}
      {newTicketState.showSuccessMessage && (
        <SuccessNotification
          success={newTicketState.successMessage ?? ""}
          hideAfterMs={4000}
          onHide={hideSuccessNotification}
        />
      )}
    </MovementsMainContext.Provider>
  );
};

export default MovementsMainContainer;
