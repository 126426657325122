import { toString as toKString } from "@progress/kendo-intl";
import { Button } from "@progress/kendo-react-buttons";
import { Label } from "@progress/kendo-react-labels";
import { usePicklists } from "ticketing/contexts/picklists/PicklistContextProvider";
import { TMovementGroup, TTicket, TValidatedTicket, TVolume } from "ticketing/ticketing.types";
import { findMatchingVolume } from "./volume_util";

export const AvailableTicket = ({
  activeMovementGroup,
  ticket,
  disableLinks,
  onShowTicketRevisions,
  onLinkTicket,
  onEditTicket,
  showDocument
}: {
  activeMovementGroup?: TMovementGroup | null;
  ticket: TValidatedTicket;
  disableLinks: boolean;
  onShowTicketRevisions: (ticketId: string) => void;
  onLinkTicket: (ticket: TValidatedTicket) => void;
  onEditTicket: (ticket: TTicket) => void;
  showDocument: (documentId: string) => void;
}) => {
  const { picklists } = usePicklists();
  const hasErrors = (ticket.validationResults?.filter(r => !!r.error).length ?? 0) > 0;
  const hasWarningsOrErrors =
    (ticket.validationResults?.filter(r => r.warning ?? r.error).length ?? 0) > 0;
  const matchingVolume: TVolume = findMatchingVolume(
    ticket,
    activeMovementGroup,
    picklists?.uomConversions
  );

  return (
    <div key={`${ticket.id}`} className="k-m-2">
      <div className="ticket-component" id="target">
        <div className="ticket-component-outter">
          <div style={{ display: "flex", flexWrap: "wrap", gap: "2px" }}>
            <span style={{ display: "flex", flexWrap: "nowrap", gap: "0" }}>
              <span style={{ color: "#09B0EC" }}>{`${ticket.ticketNumber}`}</span>
              {!!ticket.documentId && (
                <span
                  className="k-icon k-i-attachment k-icon-xs"
                  style={{
                    color: "limegreen",
                    fontSize: "xx-small",
                    verticalAlign: "super",
                    lineHeight: "1.0",
                    cursor: "pointer"
                  }}
                  role="none"
                  onClick={() => showDocument(ticket.documentId!)}
                  title={ticket.document?.fileName ?? "Ticket has document attached"}></span>
              )}
            </span>
            <span>{`(${toKString(ticket?.startDate, "yyyy-MM-dd")})`}</span>
          </div>
          <div>
            <Button
              title="Edit Ticket"
              fillMode={"flat"}
              icon={"edit"}
              onClick={() => onEditTicket(ticket)}
              style={{
                color: "#ff6358",
                padding: 0
              }}></Button>
            <Button
              title="Ticket Revisions"
              fillMode={"flat"}
              icon="clock"
              onClick={() => onShowTicketRevisions(ticket.id)}
              style={{ color: "var(--primary-icon-color)", padding: 2 }}
            />
            {hasErrors || (
              <Button
                disabled={disableLinks}
                fillMode={"flat"}
                rounded={"small"}
                icon="link-horizontal"
                className={"k-rotate-45"}
                style={{
                  color: `${disableLinks ? "gray" : "green"}`,
                  padding: 2
                }}
                onClick={() => onLinkTicket(ticket)}
                title="Link Ticket"></Button>
            )}

            {hasWarningsOrErrors && (
              <span
                className="k-icon k-i-warning-triangle tooltip"
                style={{
                  color: "var(--primary-icon-color)",
                  fontSize: "0.875rem"
                }}>
                <span className="tooltiptext">
                  <ul>
                    {ticket?.validationResults
                      ?.sort((r1, r2) => (r2.error ? 1 : 0) - (r1.error ? 1 : 0))
                      ?.map(r => {
                        return (
                          <li key={r.message}>
                            <span style={{ color: `${r.error ? "red" : "black"}` }}>
                              {r.message}
                            </span>
                          </li>
                        );
                      })}
                  </ul>
                </span>
              </span>
            )}
          </div>
        </div>

        <div className="ticket-component-label-wrapper">
          <Label>{`Net:${toKString(matchingVolume?.netVolume ?? 0.0, "0.0###")}`}</Label>
          <Label>{`Gross:${toKString(matchingVolume?.grossVolume ?? 0.0, "0.0###")}`}</Label>
          <Label>{matchingVolume?.unitOfMeasure?.name}</Label>
          <Label>{`${
            matchingVolume?.temperature
          }${matchingVolume?.temperatureUnitOfMeasure?.charAt(0)}`}</Label>
        </div>
      </div>
    </div>
  );
};
