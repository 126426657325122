import {
  Button,
  FormField,
  Modal,
  Select,
  TextInput,
  Variants
} from "@sede-x/shell-ds-react-framework";
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import { useState } from "react";
import { CIQOption, TCILookup, TWeightedAverageFilter } from "../carbonIQtypes";

const WeightedAverageLookup = ({
  onClose,
  onSubmit,
  terminals,
  components
}: {
  onClose: () => void;
  onSubmit: (data: TWeightedAverageFilter) => void;
  terminals: TCILookup[];
  components: TCILookup[];
}) => {
  dayjs.extend(weekday);
  dayjs.extend(localeData);
  const terminalsList = terminals.map(terminal => ({
    value: terminal.id.toString(), //'value` of Option should not use number type when `mode` is `tags` or `combobox`.
    label: terminal.displayName,
    key: terminal.id
  }));
  const componentsList = components.map(component => ({
    value: component.id.toString(), //'value` of Option should not use number type when `mode` is `tags` or `combobox`.
    label: component.name,
    key: component.id
  }));

  const [selectedComponents, setSelectedComponents] = useState<CIQOption[]>([]);
  const [selectedTerminals, setSelectedTerminals] = useState<CIQOption[]>([]);
  const [selectedYears, setSelectedYears] = useState("");

  return (
    <Modal
      title="Search for WeightedAverage(s)"
      width={"700px"}
      height={"600px"}
      mask={true}
      open={true}
      maskClosable={false}
      onClose={onClose}>
      <form
        onSubmit={event => {
          event.preventDefault();
          onSubmit({
            components: selectedComponents,
            terminals: selectedTerminals,
            years: selectedYears.split(",")
          });
        }}>
        <FormField size={"medium"} id="component-name-label" label="Component">
          <Select
            options={componentsList}
            size={"medium"}
            id="component-name-input"
            placeholder="Select a Component"
            optionLabelProp="label"
            filterOption={true}
            optionFilterProp="label"
            mode="tags"
            labelInValue={true}
            onChange={(data: CIQOption[]) =>
              setSelectedComponents(data.filter(o => Boolean(o.key)))
            }
          />
        </FormField>
        <FormField size={"medium"} id="terminal-name-label" label="Terminal">
          <Select
            options={terminalsList}
            size={"medium"}
            id="terminal-name-input"
            placeholder="Select a Terminal"
            optionLabelProp="label"
            filterOption={true}
            optionFilterProp="label"
            mode="tags"
            labelInValue={true}
            onChange={(data: CIQOption[]) =>
              setSelectedTerminals(data.filter(o => Boolean(o.key)))
            }
          />
        </FormField>
        <FormField size={"medium"} id="year-label" label="Year">
          <TextInput
            onChange={event => {
              setSelectedYears(event.target.value.trim());
            }}
            value={selectedYears ?? ""}
          />
        </FormField>

        <div style={{ paddingBottom: "200px" }}></div>
        <div className="from-button-wrap">
          <Button variant={Variants.Outlined} onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit">Search</Button>
        </div>
      </form>
    </Modal>
  );
};

export default WeightedAverageLookup;
