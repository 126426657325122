import { Modal, Sizes, Spacings } from "@sede-x/shell-ds-react-framework";
import { TBatchSearchCriteria } from "ticketing/ticketing.types";
import { SearchMovementsOrTicketsForm } from "./SearchMovementsOrTicketsForm";

type TSearchAvailableTicketsContainerProps = {
  searchCriteria?: TBatchSearchCriteria;
  onSearch: (criteria: TBatchSearchCriteria) => void;
  onClose: () => void;
};
export default function SearchAvailableTicketsContainer(
  props: TSearchAvailableTicketsContainerProps
) {
  return (
    <Modal
      onClose={props.onClose}
      title="Filter Available Tickets"
      padding={Spacings.Tight}
      bodyPadding={false}
      open={true}
      width={1024}
      height={340}
      centered={true}
      maskClosable={false}
      size={Sizes.Small}>
      {/**Only hide on collpase other wise the search form will be dismounted and the search criteria will be reset as the state is lost */}
      <div
        style={{
          margin: "5px",
          maxWidth: "1024px",
          backgroundColor: "#444",
          padding: "5px",
          borderRadius: "5px"
        }}>
        <SearchMovementsOrTicketsForm
          searchCriteria={props.searchCriteria}
          onSearch={props.onSearch}
          searchFor={"AvailableTickets"}
        />
      </div>
    </Modal>
  );
}
