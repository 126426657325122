import {
  Button,
  Flexbox,
  Label,
  RangeDatePicker,
  Select,
  Sizes
} from "@sede-x/shell-ds-react-framework";
import dayjs, { Dayjs } from "dayjs";
import { useMemo } from "react";
import { usePicklists } from "ticketing/contexts/picklists/PicklistContextProvider";
import { TMovementGroup } from "ticketing/ticketing.types";
import { equalsIgnoreCase } from "ticketing/utils";

export type TMovementFilter = {
  dateRange: [Dayjs | null, Dayjs | null] | null;
  selectedStatuses: number[] | null;
  selectedProducts: string[] | null;
  selectedFacilities: string[] | null;
  selectedActivityTypes: string[] | null;
  selectedDelContracts: string[] | null;
  selectedRecContracts: string[] | null;
};

type TMovementFilterProps = {
  movementGroups?: TMovementGroup[] | null;
  filter: TMovementFilter;
  onFilterChange: (filter: TMovementFilter) => void;
  onClose: () => void;
};

const EXCLUDED_MOVEMENT_STATUSES = ["Approved", "Cancelled", "Confirmed"];

export const MovementFilter = ({
  movementGroups,
  filter,
  onFilterChange,
  onClose
}: TMovementFilterProps) => {
  const { picklists } = usePicklists();

  const filteredStatuses = useMemo(
    () =>
      picklists?.movementStatuses
        ?.filter(s => !EXCLUDED_MOVEMENT_STATUSES.some(ex => equalsIgnoreCase(ex, s.name)))
        ?.map(s => ({
          value: s.id,
          label: s.name
        }))
        ?.sort((s1, s2) => s1.value - s2.value),
    [picklists?.movementStatuses]
  );

  const minDate = useMemo(
    () =>
      movementGroups
        ?.flatMap(g => g.movements.map(m => m.startDate as Date))
        .reduce((a, b) => (a < b ? a : b)) ?? new Date(),
    [movementGroups]
  );

  const maxDate = useMemo(
    () =>
      movementGroups
        ?.flatMap(g => g.movements.map(m => m.startDate as Date))
        .reduce((a, b) => (a > b ? a : b)) ?? new Date(),
    [movementGroups]
  );

  const disabledDate = useMemo(
    () => (current: Dayjs) =>
      current ? current.isBefore(minDate, "date") || current.isAfter(maxDate, "date") : false,
    [minDate, maxDate]
  );

  const products = useMemo(
    () =>
      [
        ...new Set(
          movementGroups?.flatMap(g => g.movements.map(m => m.product.name).filter(Boolean))
        )
      ]
        .sort((a, b) => a.localeCompare(b))
        .map(s => ({
          value: s,
          label: s
        })),
    [movementGroups]
  );

  const facilities = useMemo(
    () =>
      [
        ...new Set(
          movementGroups?.flatMap(g =>
            g.movements.map(m => m.titleTransferFacility.name).filter(Boolean)
          )
        )
      ]
        .sort((a, b) => a.localeCompare(b))
        .map(s => ({
          value: s,
          label: s
        })),
    [movementGroups]
  );

  const delContracts = useMemo(
    () =>
      [
        ...new Set(
          movementGroups?.flatMap(g =>
            g.movements
              .map(m => m.delDeal?.contractNumber)
              .filter((e): e is string => Boolean(e))
          )
        )
      ]
        .sort((a, b) => a.localeCompare(b))
        .map(s => ({
          value: s,
          label: s
        })),
    [movementGroups]
  );

  const recContracts = useMemo(
    () =>
      [
        ...new Set(
          movementGroups?.flatMap(g =>
            g.movements
              .map(m => m.recDeal?.contractNumber)
              .filter((e): e is string => Boolean(e))
          )
        )
      ]
        .sort((a, b) => a.localeCompare(b))
        .map(s => ({
          value: s,
          label: s
        })),
    [movementGroups]
  );

  const activityTypes = useMemo(
    () =>
      [
        ...new Set(
          movementGroups?.flatMap(g =>
            g.movements.map(m => m.activityType?.name).filter((e): e is string => Boolean(e))
          )
        )
      ]
        .sort((a, b) => a.localeCompare(b))
        .map(s => ({
          value: s,
          label: s
        })),
    [movementGroups]
  );

  return (
    <Flexbox
      flexDirection={"column"}
      gap="8px"
      style={{ padding: "8px", width: "320px" }}
      allowClear={false}>
      <Label size={Sizes.Medium}>Movement&nbsp;Date</Label>
      <RangeDatePicker
        size={Sizes.Small}
        placeholder={["From", "To"]}
        allowClear={true}
        showTime={false}
        disabledDate={disabledDate}
        value={filter.dateRange}
        defaultValue={[dayjs(minDate), dayjs(maxDate)]}
        onChange={v => onFilterChange({ ...filter, dateRange: v })}
        showToday={false}
        picker="date"
      />
      <Label size={Sizes.Medium}>Status</Label>
      <Select
        size={Sizes.Small}
        mode={"multiple"}
        allowClear={true}
        options={filteredStatuses}
        optionLabelProp="label"
        maxTagCount={2}
        value={filter?.selectedStatuses}
        onChange={value => onFilterChange({ ...filter, selectedStatuses: value })}
      />
      <Label size={Sizes.Medium}>Products</Label>
      <Select
        size={Sizes.Small}
        mode={"multiple"}
        allowClear={true}
        options={products}
        optionLabelProp="label"
        maxTagCount={2}
        value={filter?.selectedProducts}
        onChange={value => onFilterChange({ ...filter, selectedProducts: value })}
      />
      <Label size={Sizes.Medium}>Facilities</Label>
      <Select
        size={Sizes.Small}
        mode={"multiple"}
        allowClear={true}
        options={facilities}
        optionLabelProp="label"
        maxTagCount={2}
        value={filter?.selectedFacilities}
        onChange={value => onFilterChange({ ...filter, selectedFacilities: value })}
      />
      <Label size={Sizes.Medium}>Activity Types</Label>
      <Select
        size={Sizes.Small}
        mode={"multiple"}
        allowClear={true}
        options={activityTypes}
        optionLabelProp="label"
        maxTagCount={2}
        value={filter?.selectedActivityTypes}
        onChange={value => onFilterChange({ ...filter, selectedActivityTypes: value })}
      />
      <Flexbox flexDirection={"row"} gap="8px">
        <Flexbox flexDirection={"column"} gap="0" flex={"1"}>
          <Label size={Sizes.Medium}>Rec Contracts</Label>
          <Select
            size={Sizes.Small}
            mode={"multiple"}
            allowClear={true}
            options={recContracts}
            optionLabelProp="label"
            maxTagCount={2}
            value={filter?.selectedRecContracts}
            onChange={value => onFilterChange({ ...filter, selectedRecContracts: value })}
          />
        </Flexbox>
        <Flexbox flexDirection={"column"} gap="0" flex={"1"}>
          <Label size={Sizes.Medium}>Del Contracts</Label>
          <Select
            size={Sizes.Small}
            mode={"multiple"}
            allowClear={true}
            options={delContracts}
            optionLabelProp="label"
            maxTagCount={2}
            value={filter?.selectedDelContracts}
            onChange={value => onFilterChange({ ...filter, selectedDelContracts: value })}
          />
        </Flexbox>
      </Flexbox>
      <Flexbox flexDirection={"row"} gap="8px">
        <Button
          sentiment="negative"
          style={{ flex: 1 }}
          size={Sizes.Medium}
          onClick={() =>
            onFilterChange({
              dateRange: null,
              selectedStatuses: null,
              selectedProducts: null,
              selectedFacilities: null,
              selectedActivityTypes: null,
              selectedDelContracts: null,
              selectedRecContracts: null
            })
          }>
          Reset
        </Button>
        <Button sentiment="positive" onClick={onClose} style={{ flex: 1 }} size={Sizes.Medium}>
          Close
        </Button>
      </Flexbox>
    </Flexbox>
  );
};
