import { ApolloError, useMutation, useQuery } from "@apollo/client";
import {
  Drawer,
  FormField,
  Heading,
  Select,
  Sentiments,
  Sizes,
  TextInput,
  TextTypes,
  Variants
} from "@sede-x/shell-ds-react-framework";
import { loader } from "graphql.macro";
import { useCallback, useEffect, useState, WheelEvent } from "react";
import { ApolloErrorViewer } from "shared/components/ApolloErrorViewer";
import LoadingPanel from "shared/components/LoadingPanel";
import { GqlResponse } from "types";
import {
  CIQOption,
  TCILookup,
  TCIWeightedAverage,
  TProductBlendFetchComponentsData
} from "../carbonIQtypes";

const ComponentsList = loader("../graphql/lookupComponents.graphql");

const TerminalsList = loader("../graphql/lookupTerminals.graphql");

const ciAddNewWeightedAverage = loader(
  "../graphql/mutation-CarbonAddNewWeightedAverage.graphql"
);

const ciUpdateWeightedAverage = loader(
  "../graphql/mutation-CarbonUpdateWeightedAverage.graphql"
);

type TAddCIWeightedAverageResponse = GqlResponse<TCIWeightedAverage, "updateCIWeightedAverage">;
type TUpdateCIWeightedAverageResponse = GqlResponse<TCIWeightedAverage, "addCIWeightedAverage">;

const WeightedAverageCrud = ({
  details,
  onClose,
  onSubmit,
  weightedAverageData
}: {
  details: Partial<TCIWeightedAverage> | null;
  onClose: () => void;
  onSubmit: (data: TCIWeightedAverage) => void;
  weightedAverageData: Partial<TCIWeightedAverage>[];
}) => {
  const numberInputOnWheelPreventChange = (e: WheelEvent<HTMLInputElement>) => {
    // Prevent the input value change
    (e.target as HTMLInputElement).blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    // Refocus immediately, on the next tick (after the current function is done)
    setTimeout(() => (e.target as HTMLInputElement).focus(), 0);
  };
  const [components, setComponents] = useState<CIQOption[]>([]);
  const [terminals, setTerminals] = useState<CIQOption[]>([]);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [preFillWeightedAverage, setPreFillWeightedAverage] = useState<
    Partial<TCIWeightedAverage>
  >(details ?? {});

  const isStateAdd = details === null || details === undefined;

  // for a year - a duplicate row is not allowed
  const validateForDuplicate = useCallback(
    (activeWeightedAverage: Partial<TCIWeightedAverage> | undefined) => {
      if (activeWeightedAverage === undefined || activeWeightedAverage?.year === undefined) {
        return;
      }
      let dataForYear = weightedAverageData.filter(
        (wa: Partial<TCIWeightedAverage>) => wa?.year === activeWeightedAverage?.year
      );
      if (!isStateAdd) {
        // in edit mode we don't want to compare the active record with itself
        dataForYear = dataForYear.filter(
          (d: Partial<TCIWeightedAverage>) => d.id !== activeWeightedAverage?.id
        );
      }
      const matchFound = dataForYear.filter(
        (data: Partial<TCIWeightedAverage>) =>
          data?.component?.id === preFillWeightedAverage?.component?.id &&
          data?.terminal?.id === preFillWeightedAverage?.terminal?.id &&
          data?.averageCI === preFillWeightedAverage?.averageCI &&
          data?.feedStock?.trim() === preFillWeightedAverage?.feedStock?.trim() &&
          data?.countryOfOrigin === preFillWeightedAverage?.countryOfOrigin
      );
      setIsDuplicate(matchFound?.length > 0);
    },
    [
      isStateAdd,
      preFillWeightedAverage?.averageCI,
      preFillWeightedAverage?.component?.id,
      preFillWeightedAverage?.countryOfOrigin,
      preFillWeightedAverage?.feedStock,
      preFillWeightedAverage?.terminal?.id,
      weightedAverageData
    ]
  );

  const disableSave =
    !preFillWeightedAverage?.component ||
    !preFillWeightedAverage?.terminal ||
    !preFillWeightedAverage?.year ||
    !preFillWeightedAverage?.averageCI ||
    !preFillWeightedAverage?.feedStock ||
    !preFillWeightedAverage?.countryOfOrigin ||
    isDuplicate;

  //Fetching Components List
  const { loading: ciqComponentLoading, error: eCIQComponent } = useQuery<
    GqlResponse<TProductBlendFetchComponentsData[], "ciComponentsFilterBy">
  >(ComponentsList, {
    onCompleted: data => {
      setComponents(
        data.ciComponentsFilterBy.map(component => ({
          id: component.id!,
          value: component.name!,
          label: component.name!,
          key: component.id!
        }))
      );
    }
  });

  const { loading: ciqTerminalLoading, error: eCIQTerminal } = useQuery<
    GqlResponse<TCILookup[], "ciTerminalsFilterBy">
  >(TerminalsList, {
    onCompleted: data => {
      setTerminals(
        data.ciTerminalsFilterBy.map(terminal => ({
          value: terminal.displayName!,
          label: terminal.displayName as string,
          key: terminal.id!,
          id: terminal.id!
        }))
      );
    }
  });

  const [
    addNewWeightedAverage,
    { loading: addNewWeightedAverageLoading, error: addNewWeightedAveragelError }
  ] = useMutation<TAddCIWeightedAverageResponse>(ciAddNewWeightedAverage, {
    onCompleted: data => onSubmit(data.updateCIWeightedAverage)
  });

  useEffect(() => {
    validateForDuplicate(preFillWeightedAverage);
  }, [preFillWeightedAverage, validateForDuplicate]);

  // Update Weighted Average
  const [
    updateWeightedAverage,
    { loading: updateWeightedAverageLoading, error: updateWeightedAverageError }
  ] = useMutation<TUpdateCIWeightedAverageResponse>(ciUpdateWeightedAverage, {
    onCompleted: data => onSubmit(data.addCIWeightedAverage)
  });

  const isLoading = [
    updateWeightedAverageLoading,
    addNewWeightedAverageLoading,
    ciqTerminalLoading,
    ciqComponentLoading
  ].some(elm => elm);

  const onSubmitClick = () => {
    const tempdata = {
      comments: preFillWeightedAverage?.comments || "",
      componentId: preFillWeightedAverage?.component?.id,
      terminalId: preFillWeightedAverage?.terminal?.id,
      year: preFillWeightedAverage?.year,
      averageCI: preFillWeightedAverage?.averageCI,
      feedStock: preFillWeightedAverage?.feedStock?.trim(),
      countryOfOrigin: preFillWeightedAverage?.countryOfOrigin?.trim()
    };
    isStateAdd
      ? addNewWeightedAverage({
          variables: {
            weightedAverage: tempdata
          }
        })
      : updateWeightedAverage({
          variables: {
            weightedAverage: {
              ...tempdata,
              id: preFillWeightedAverage?.id,
              version: preFillWeightedAverage?.version
            }
          }
        });
  };

  const isError = [
    addNewWeightedAveragelError,
    updateWeightedAverageError,
    eCIQComponent,
    eCIQTerminal
  ].some(e => e);
  const errors = [
    addNewWeightedAveragelError,
    updateWeightedAverageError,
    eCIQComponent,
    eCIQTerminal
  ].filter((e): e is ApolloError => Boolean(e));
  return (
    <Drawer
      header={
        <Heading className="setting-form-heading" type={TextTypes.H2}>
          {(isStateAdd ? "Add New" : "Update") + " Weighted Average"}
        </Heading>
      }
      borders={false}
      closeButton={false}
      sticky
      mask={true}
      open={true}
      maskClosable={false}
      size={Sizes.Medium}
      onClose={onClose}
      actions={[
        {
          label: "Cancel",
          action: onClose,
          props: {
            variant: Variants.Outlined
          }
        },
        {
          label: "Save",
          action: onSubmitClick,
          props: {
            disabled: disableSave
          }
        }
      ]}>
      {isLoading && <LoadingPanel />}
      {isError && (
        <ApolloErrorViewer
          error={errors}
          customErrorMessage={isDuplicate ? "Duplicate record" : undefined}
        />
      )}
      <form>
        <FormField
          size={"medium"}
          id="component-name-label"
          label="Component"
          mandatory={true}
          bottomLeftHelper={{
            content: !preFillWeightedAverage?.component ? (
              <b>Component can not be blank.</b>
            ) : (
              ""
            ),
            sentiment: Sentiments.Negative
          }}>
          <Select
            options={components}
            size={"medium"}
            invalid={!preFillWeightedAverage?.component}
            id="component-name-input"
            placeholder="Select a Component"
            optionLabelProp="label"
            filterOption={true}
            optionFilterProp="label"
            labelInValue={true}
            value={preFillWeightedAverage?.component?.name}
            onChange={data => {
              setPreFillWeightedAverage({
                ...preFillWeightedAverage,
                component: !data ? data : { id: data.key, name: data.value }
              });
            }}
          />
        </FormField>
        <FormField
          size={"medium"}
          id="terminal-name-label"
          label="Terminal"
          mandatory={true}
          bottomLeftHelper={{
            content: !preFillWeightedAverage?.terminal ? <b>Terminal can not be blank.</b> : "",
            sentiment: Sentiments.Negative
          }}>
          <Select
            options={terminals}
            size={"medium"}
            invalid={!preFillWeightedAverage?.terminal}
            id="terminal-name-input"
            placeholder="Select a Terminal"
            optionLabelProp="label"
            filterOption={true}
            optionFilterProp="label"
            labelInValue={true}
            value={preFillWeightedAverage?.terminal?.displayName}
            onChange={data => {
              setPreFillWeightedAverage({
                ...preFillWeightedAverage,
                terminal: !data ? data : { id: data.key, displayName: data.value }
              });
            }}
          />
        </FormField>
        <FormField
          size={"medium"}
          id="year-label"
          mandatory={true}
          label="Year"
          bottomLeftHelper={{
            content: !preFillWeightedAverage?.year ? <b>Year can not be blank.</b> : "",
            sentiment: Sentiments.Negative
          }}>
          <TextInput
            onChange={event => {
              setPreFillWeightedAverage({
                ...preFillWeightedAverage,
                year: +event.target.value.trim()
              });
            }}
            value={preFillWeightedAverage?.year ?? ""}
            invalid={!preFillWeightedAverage?.year}
          />
        </FormField>
        <FormField
          size={"medium"}
          id="average-ci-label"
          mandatory={true}
          label="Average CI"
          bottomLeftHelper={{
            content: !preFillWeightedAverage?.averageCI ? (
              <b>Average CI can not be blank.</b>
            ) : (
              ""
            ),
            sentiment: Sentiments.Negative
          }}>
          <TextInput
            type={"number"}
            step=".01"
            onWheel={numberInputOnWheelPreventChange}
            max={100.0}
            min={-100.0}
            onChange={event => {
              setPreFillWeightedAverage({
                ...preFillWeightedAverage,
                averageCI: +event.target.value.trim()
              });
            }}
            value={preFillWeightedAverage?.averageCI ?? ""}
            invalid={!preFillWeightedAverage?.averageCI}
          />
        </FormField>
        <FormField
          size={"medium"}
          id="feedstock-label"
          mandatory={true}
          label="Feedstock"
          bottomLeftHelper={{
            content: !preFillWeightedAverage?.feedStock ? (
              <b>Feedstock can not be blank.</b>
            ) : (
              ""
            ),
            sentiment: Sentiments.Negative
          }}>
          <TextInput
            onChange={event => {
              setPreFillWeightedAverage({
                ...preFillWeightedAverage,
                feedStock: event.target.value
              });
            }}
            value={preFillWeightedAverage?.feedStock ?? ""}
            invalid={!preFillWeightedAverage?.feedStock}
          />
        </FormField>

        <FormField
          size={"medium"}
          id="country-label"
          mandatory={true}
          label="Country of Origin"
          bottomLeftHelper={{
            content: !preFillWeightedAverage?.countryOfOrigin ? (
              <b>Country of Origin can not be blank.</b>
            ) : (
              ""
            ),
            sentiment: Sentiments.Negative
          }}>
          <TextInput
            onChange={event => {
              setPreFillWeightedAverage({
                ...preFillWeightedAverage,
                countryOfOrigin: event.target.value
              });
            }}
            value={preFillWeightedAverage?.countryOfOrigin ?? ""}
            invalid={!preFillWeightedAverage?.countryOfOrigin}
          />
        </FormField>

        <FormField size={"medium"} id="comments-label" label="Comments">
          <TextInput
            onChange={event => {
              setPreFillWeightedAverage({
                ...preFillWeightedAverage,
                comments: event.target.value
              });
            }}
            value={preFillWeightedAverage?.comments ?? ""}
          />
        </FormField>
      </form>
    </Drawer>
  );
};

export default WeightedAverageCrud;
