import {
  ZonedDate,
  addDays,
  addMonths,
  firstDayOfMonth,
  getDate,
  lastDayOfMonth
} from "@progress/kendo-date-math";
import "@progress/kendo-date-math/tz/America/Chicago";
import { parseDate, toString as toKString } from "@progress/kendo-intl";
import {
  IntegerQueryOperatorInput,
  LongQueryOperatorInput,
  Picklists,
  QueryOperatorInput,
  StringQueryOperatorInput,
  TAllocationMovement,
  TBatchFilterInput,
  TBatchSearchCriteria,
  TDeal,
  TLinkedTicketFilterInput,
  TMovement,
  TMovementFilterInput,
  TMovementGroup,
  TNullableMovementGroup,
  TNullableString,
  TTicketFilterInput,
  TTicketInput,
  TTicketInputAdd,
  TTicketInputUpdate,
  TTicketSearchCriteria,
  TUserQueryCode,
  TVolumeInput,
  TVolumeInputAdd,
  TVolumeInputUpdate,
  TicketSource,
  TicketSourceFilterInput,
  TicketStatus
} from "./ticketing.types";

export const MAX_FILE_SIZE = 20_971_520; //PBI 2504803  - MAX FILE SIZE IS 20mb
export const DAYS_IN_WEEK = 7;
export const isValidTicketNumber = (ticketNumber: string) =>
  ticketNumber === null || ticketNumber.match(/[a-zA-Z0-9\-_ ]/);

export const getCurrentCSTDate = (startDate: Date): Date => {
  return ZonedDate.fromLocalDate(startDate).toTimezone("America/Chicago").toLocalDate();
};

export const trim = (value: string | undefined | null): string | undefined => {
  if (value == null) {
    return undefined;
  }
  return value.trim().length === 0 ? undefined : value.trim();
};

export const ENDUR_FAHRENHEIT_TEMPERATURE_MEASURE = "Degrees Farenheit";
export const ENDUR_CELSIUS_TEMPERATURE_MEASURE = "Degrees Celsius";
export const TEMPERATURE_MEASURE_NAME = "Temperature";
export const FAHRENHEIT_TEMPERATURE_MEASURE = "FAHRENHEIT";
export const CELSIUS_TEMPERATURE_MEASURE = "CELSIUS";
export const DEFAULT_TEMPERATURE_MEASURE = FAHRENHEIT_TEMPERATURE_MEASURE;

export const STCAN_LE = "STCAN - LE";
export const STUSCO_LE = "STUSCO - LE";
export const SOPUS_LE = "SOPUS - LE";
export const SOPUS_SANDBOX_LE = "SOPUS_SANDBOX-LE";
export const SHELL_CHEM_LE = "SHELL_CHEM-LE";

export const MOVEMENT_STATUS_PLANNED = "Planned";
export const MOVEMENT_STATUS_CANCELLED = "Cancelled";
export const STCAN_DEFAULT_CELSIUS_TEMPERATURE = 15;
export const NON_STCAN_DEFAULT_FAHRENHEIT_TEMPERATURE = 60;
export const MOVEMENT_INFO_FIELD_NAME_AUTO_ACTUALIZE = "Auto-Actualize";
export const MOVEMENT_INFO_FIELD_NAME_ACTUALIZED_BY = "Actualized By";
export const LOGISTIC_SYSTEM_PIPELINE_EMISSION_CREDITS = "Emission Credits";

export const INS_TYPE_COMM_PHYS_SA_IB = "COMM_PHYS_SA_IB";
export const INS_TYPE_COMM_STOR = "COMM-STOR";
export const INS_TYPE_COMM_STOR_LIGHTERING = "COMM-STOR-LIGHTERING";

export const isSTCan = (movementGroup: TMovementGroup): boolean => {
  return movementGroup?.movements?.some(m =>
    equalsIgnoreCase(m.internalLegalEntity?.name, STCAN_LE)
  );
};
export const isSTUSCO = (movementGroup: TMovementGroup): boolean => {
  return movementGroup?.movements?.some(m =>
    equalsIgnoreCase(m.internalLegalEntity?.name, STUSCO_LE)
  );
};
export const isSOPUS = (movementGroup: TMovementGroup): boolean => {
  return movementGroup?.movements?.some(m =>
    equalsIgnoreCase(m.internalLegalEntity?.name, SOPUS_LE)
  );
};

export const isRins = (movementGroup: TMovementGroup | null): boolean => {
  return (
    movementGroup?.movements?.some(m =>
      equalsIgnoreCase(m.logisticsSystem?.name, LOGISTIC_SYSTEM_PIPELINE_EMISSION_CREDITS)
    ) ?? false
  );
};

export const isTruckOrRail = (t: TTicketInput): boolean =>
  !!t.modeOfTransport &&
  (t.modeOfTransport?.name === "Rail" || t.modeOfTransport?.name === "Truck");

export const isPlannedMovement = (movementGroup: TNullableMovementGroup) =>
  movementGroup?.movements?.find(m =>
    equalsIgnoreCase(m.status?.name, MOVEMENT_STATUS_PLANNED)
  ) !== undefined;

export const isCancelledMovement = (movementGroup: TNullableMovementGroup) =>
  movementGroup?.movements?.find(m =>
    equalsIgnoreCase(m.status?.name, MOVEMENT_STATUS_CANCELLED)
  ) !== undefined;

export const isActualizedExternally = (movementGroup: TNullableMovementGroup) =>
  movementGroup?.activeMovement?.isActualizedExternally;

/***
 * if any nom with in the group has a Auto-Actualize nom info set to Yes
 */
export const isAutoActualizeInfoSet = (movementGroup: TNullableMovementGroup) =>
  movementGroup?.movements
    ?.flatMap(m => m.infoFields)
    ?.some(
      field =>
        field &&
        equalsIgnoreCase(field.name, MOVEMENT_INFO_FIELD_NAME_AUTO_ACTUALIZE) &&
        equalsIgnoreCase(field.value, "Yes")
    );

/***
 * if all noms with in the group has a Auto-Actualize nom info set to Yes
 */
export const isActualizedByInfoSet = (movementGroup: TNullableMovementGroup) => {
  const actualizedByFields = movementGroup?.movements?.map(
    m =>
      m.infoFields?.find(field =>
        equalsIgnoreCase(field.name, MOVEMENT_INFO_FIELD_NAME_ACTUALIZED_BY)
      )?.value
  );

  return (
    //make sure there is atleast one
    Boolean(actualizedByFields?.length) &&
    //and not all of them are null or undefined or empty
    actualizedByFields?.some(v => Boolean(v?.trim())) &&
    //and not one side has `GSAP` and the other side is null or undefined or empty
    !(
      actualizedByFields?.some(v => !Boolean(v?.trim())) &&
      actualizedByFields?.some(val => equalsIgnoreCase(val, "GSAP"))
    )
  );
};

export const isMovementActualizedByGSAP = (movement: TMovement) =>
  movement.infoFields?.some(
    field =>
      field &&
      equalsIgnoreCase(field.name, MOVEMENT_INFO_FIELD_NAME_ACTUALIZED_BY) &&
      field.value != null &&
      field.value === "GSAP"
  );

export const hasCreditRedLine = (movementGroup: TNullableMovementGroup) =>
  movementGroup?.movements
    ?.flatMap(m => m.alerts)
    .find(
      a => equalsIgnoreCase(a.code, "credit") && equalsIgnoreCase(a.category, "redline")
    ) !== undefined;

export const mapTempUOM = (uom: string | undefined) => {
  if (uom) {
    switch (uom) {
      case "Degrees Farenheit":
        return "FAHRENHEIT";
      case "Degrees Celsius":
        return "CELSIUS";
      default:
        return uom;
    }
  }
  return uom;
};

export const DEFAULT_UNIT_OF_MEASURE = "GAL";

export const MODE_OF_TRANSPORT_TRUCK = "Truck";
export const MODE_OF_TRANSPORT_RAIL = "Rail";
export const MODE_OF_TRANSPORT_OTHER = "Other";
export const MODE_OF_TRANSPORT_BARGE = "Barge";
export const MODE_OF_TRANSPORT_BATCH_PIPELINE = "Batch Pipeline"; //please note the space
export const MODE_OF_TRANSPORT_TANKER = "Tanker";
export const COMM_PHYS_SA_IB = "COMM-PHYS-SA-IB";
const SERVICE_PROVIDER_ID_3 = 3;

export const isValidMOT = (modeOfTransport: string) => {
  return (
    equalsIgnoreCase(modeOfTransport, MODE_OF_TRANSPORT_TRUCK) ||
    equalsIgnoreCase(modeOfTransport, MODE_OF_TRANSPORT_RAIL) ||
    equalsIgnoreCase(modeOfTransport, MODE_OF_TRANSPORT_OTHER)
  );
};

export const isMarineMOT = (modeOfTransport: string) => {
  return (
    equalsIgnoreCase(modeOfTransport, MODE_OF_TRANSPORT_BARGE) ||
    equalsIgnoreCase(modeOfTransport, MODE_OF_TRANSPORT_TANKER)
  );
};

export const isPipeLineMOT = (modeOfTransport: string) => {
  return equalsIgnoreCase(modeOfTransport, MODE_OF_TRANSPORT_BATCH_PIPELINE);
};

export const isPhysicalDeal = (deal?: TDeal) =>
  equalsIgnoreCase(deal?.contractType?.category, "Physical");

export const isStorageDeal = (deal?: TDeal) =>
  equalsIgnoreCase(deal?.contractType?.category, "Storage");

export const isTransitDeal = (deal?: TDeal) =>
  equalsIgnoreCase(deal?.insType?.baseInstrument?.name, "COMM-TRANSIT");

export const isPhysicalAndStorage = (group: TMovementGroup) =>
  group.movements.some(m => isPhysicalDeal(m.recDeal) && isStorageDeal(m.delDeal));

export const isTransitMovement = (group: TMovementGroup | null) => !isNonTransitMovement(group);

export const isNonTransitMovement = (group: TMovementGroup | null) =>
  group?.movements.some(
    m =>
      (isPhysicalDeal(m.recDeal) || isStorageDeal(m.recDeal)) &&
      (isPhysicalDeal(m.delDeal) || isStorageDeal(m.delDeal))
  );

export const isMovementTransitToPhysOrPhysToTransit = (movement: TMovement) =>
  (isTransitDeal(movement.recDeal) && isPhysicalDeal(movement.delDeal)) ||
  (isPhysicalDeal(movement.recDeal) && isTransitDeal(movement.delDeal));

export const recDealUomName = (movement: TMovement) => movement.recDeal.qtyUnitOfMeasure?.name;

export const recFinUomName = (movement: TMovement) =>
  movement.recDeal.pricingUnitOfMeasure?.name;

export const delFinUomName = (movement: TMovement) =>
  movement.delDeal.pricingUnitOfMeasure?.name;

export const delDealUomName = (movement: TMovement) => movement.delDeal.qtyUnitOfMeasure?.name;

export const recFinUomTypeName = (movement: TMovement) =>
  movement.recDeal.pricingUnitOfMeasure?.unitOfMeasureClass?.name;

export const recDealUomTypeName = (movement: TMovement) =>
  movement.recDeal.qtyUnitOfMeasure?.unitOfMeasureClass?.name;

export const delFinUomTypeName = (movement: TMovement) =>
  movement.delDeal.pricingUnitOfMeasure?.unitOfMeasureClass?.name;

export const delDealUomTypeName = (movement: TMovement) =>
  movement.delDeal.qtyUnitOfMeasure?.unitOfMeasureClass?.name;

export const isDealFinUomTypeNameIsMass = (movement: TMovement) =>
  equalsIgnoreCase(recFinUomTypeName(movement), "Mass") ||
  equalsIgnoreCase(delFinUomTypeName(movement), "Mass");

export const isDealFinUomTypeNameIsVolume = (movement: TMovement) =>
  equalsIgnoreCase(recFinUomTypeName(movement), "Volume") ||
  equalsIgnoreCase(delFinUomTypeName(movement), "Volume");

export const finUomName = (movement: TMovement) =>
  isPhysicalDeal(movement.recDeal)
    ? movement.recDeal.pricingUnitOfMeasure?.name
    : movement.delDeal.pricingUnitOfMeasure?.name;

export const finUomNameForStorage = (movement: TMovement) =>
  isStorageDeal(movement.recDeal)
    ? movement.delDeal.pricingUnitOfMeasure?.name
    : movement.recDeal.pricingUnitOfMeasure?.name;

export const finUomTypeNameForStorage = (movement: TMovement) =>
  isStorageDeal(movement.recDeal)
    ? movement.delDeal.pricingUnitOfMeasure?.unitOfMeasureClass?.name
    : movement.recDeal.pricingUnitOfMeasure?.unitOfMeasureClass?.name;

export const finUomTypeName = (movement: TMovement) =>
  isPhysicalDeal(movement.recDeal)
    ? movement.recDeal.pricingUnitOfMeasure?.unitOfMeasureClass?.name
    : movement.delDeal.pricingUnitOfMeasure?.unitOfMeasureClass?.name;

export const isDealComStorORComStorLightening = (deal: TDeal) =>
  equalsIgnoreCase(deal.insType?.name, INS_TYPE_COMM_STOR) ||
  equalsIgnoreCase(deal.insType?.name, INS_TYPE_COMM_STOR_LIGHTERING);

export const isMeasureHavingZeroVolume = (movement: TMovement, measureName: string) =>
  movement.measures
    .filter(mm => equalsIgnoreCase(mm.measurementType?.name, measureName))
    .some(mm => mm.value <= 0.0);

export const isMovementTransitOrStorage = (movement: TMovement) =>
  isMovementTransitToPhysOrPhysToTransit(movement) || isMovementStorageToStorage(movement);

export const isMovementStorageToStorage = (movement: TMovement) =>
  (!isTransitDeal(movement.delDeal) && isDealComStorORComStorLightening(movement.recDeal)) ||
  (!isTransitDeal(movement.recDeal) && isDealComStorORComStorLightening(movement.delDeal));

export const isMovementPhysToPhys = (movement: TMovement) =>
  isPhysicalDeal(movement.recDeal) && isPhysicalDeal(movement.delDeal);

export const isMovementTansitToStorageOrStorageToTransit = (movement: TMovement) =>
  (isStorageDeal(movement.recDeal) && isTransitDeal(movement.delDeal)) ||
  (isStorageDeal(movement.delDeal) && isTransitDeal(movement.recDeal));

export const isTicketAndFinUomNameNotMatching = (
  movement: TMovement,
  ticketUomName: string | undefined
) =>
  !isBlank(ticketUomName) &&
  (!equalsIgnoreCase(ticketUomName, recFinUomName(movement)) ||
    !equalsIgnoreCase(ticketUomName, delFinUomName(movement)));

export const isTicketAndFinUomTypeNameNotMatching = (
  movement: TMovement,
  ticketUomTypeName: string | undefined
) =>
  !isBlank(ticketUomTypeName) &&
  (!equalsIgnoreCase(ticketUomTypeName, recFinUomTypeName(movement)) ||
    !equalsIgnoreCase(ticketUomTypeName, delFinUomTypeName(movement)));

export const isMassMeasureHavingZeroVolume = (movement: TMovement) =>
  isDealFinUomTypeNameIsMass(movement) && isMeasureHavingZeroVolume(movement, "Mass(Air)");

export const isVolumeMeasureHavingZeroVolume = (movement: TMovement) =>
  isDealFinUomTypeNameIsVolume(movement) && isMeasureHavingZeroVolume(movement, "Volume");

export const isTicketAndFinUomNotMatching = (
  movement: TMovement,
  ticketUomName: string | undefined,
  ticketUomTypeName: string | undefined
) =>
  !isBlank(ticketUomName) &&
  !equalsIgnoreCase(ticketUomName, finUomName(movement)) &&
  !isBlank(ticketUomTypeName) &&
  !equalsIgnoreCase(ticketUomTypeName, finUomTypeName(movement));

export const isMovementNotHavingMatchingUomOnBothDeals = (movement: TMovement) =>
  !equalsIgnoreCase(recDealUomName(movement), delDealUomName(movement)) &&
  !equalsIgnoreCase(recDealUomTypeName(movement), delDealUomTypeName(movement));

export const isMovementPhysicalToStorage = (movement: TMovement) =>
  isPhysicalDeal(movement.recDeal) &&
  isStorageDeal(movement.delDeal) &&
  equalsIgnoreCase(movement.delDeal.insType?.name, INS_TYPE_COMM_STOR);

export const isMovementStorageToPhysical = (movement: TMovement) =>
  isStorageDeal(movement.recDeal) &&
  equalsIgnoreCase(movement.recDeal.insType?.name, INS_TYPE_COMM_STOR) &&
  isPhysicalDeal(movement.delDeal);

export const skipValidationForSpecificLocations = (movement: TMovement) =>
  movement.titleTransferFacility?.name.includes("PUERTO") &&
  movement.titleTransferFacility?.name.includes("RICO");

export const skipValidationForInterBook = (movement: TMovement, input: string) =>
  equalsIgnoreCase(input, "shipTo")
    ? equalsIgnoreCase(movement.delDeal.insType?.name, INS_TYPE_COMM_PHYS_SA_IB)
    : equalsIgnoreCase(movement.recDeal.insType?.name, INS_TYPE_COMM_PHYS_SA_IB);

export const isMovementHavingActivityTypeBackToBack = (movement: TMovement) =>
  (movement.activityType?.serviceProviderId ?? -1) === SERVICE_PROVIDER_ID_3;

export const isDealHavingRule11 = (movement: TMovement) =>
  equalsIgnoreCase(movement?.recDeal?.commitment?.rule11, "YES") ||
  equalsIgnoreCase(movement?.delDeal?.commitment?.rule11, "YES");

export const isDealHavingIncoTermDestination = (movement: TMovement) =>
  equalsIgnoreCase(movement.recDeal.commitment?.incoterm?.name, "DES") &&
  equalsIgnoreCase(movement.delDeal.commitment?.incoterm?.name, "DES");

export const isDestinationRule11Incoterm = (movement: TMovement) =>
  isDealHavingIncoTermDestination(movement) || isDealHavingRule11(movement);

export const skipValidationForSpecificProductGroups = (
  movement: TMovement,
  userQueryCodes: TUserQueryCode[],
  qryCode: string
) =>
  userQueryCodes
    .filter(uQc => equalsIgnoreCase(uQc.code, qryCode))
    .some(fuQc => fuQc.valueInt1 === movement.product.userProductGroup?.productGroupId);

export const skipValidationForMOTsOtherThanTruckAndRail = (movement: TMovement) =>
  !equalsIgnoreCase(movement.batch.modeOfTransport?.name, MODE_OF_TRANSPORT_TRUCK) &&
  !equalsIgnoreCase(movement.batch.modeOfTransport?.name, MODE_OF_TRANSPORT_RAIL);

export const isBlank = (str: string | undefined) =>
  str === null || str === "" || str === undefined;

export const equalsIgnoreCase = (str1: TNullableString, str2: TNullableString): boolean => {
  return (
    str1 != null &&
    str2 != null &&
    str1.localeCompare(str2, undefined, { sensitivity: "accent" }) === 0
  );
};

export const minDates = (...dates: (Date | undefined)[]) => {
  const cleanDates = dates.filter((d): d is Date => Boolean(d)).map(d => d.getTime());
  if (cleanDates.length > 0) {
    return new Date(Math.min(...cleanDates));
  }
  return undefined;
};

export const maxDates = (...dates: (Date | undefined)[]) => {
  const cleanDates = dates.filter((d): d is Date => Boolean(d)).map(d => d.getTime());
  if (cleanDates.length > 0) {
    return new Date(Math.max(...cleanDates));
  }
  return undefined;
};

export const splitByComma = (value: string | undefined | null) => {
  if (value?.trim()) {
    return [
      ...new Set(
        value
          .trim()
          .replaceAll(" ", ",")
          .split(",")
          .filter(Boolean)
          .map(v => v.trim())
      )
    ];
  }
  return null;
};

export const getCancelledMovementStatusId = (
  movementStatuses?: Picklists.TMovementStatus[]
) => {
  return movementStatuses?.find(ms => equalsIgnoreCase(ms.name, "Cancelled"))?.id;
};

export const transformMovements = (movements: TMovement[]): TMovement[] => {
  return movements
    .map(m => ({
      ...m,
      startDate: fromISODateString(m.startDate)!,
      endDate: fromISODateString(m.endDate)!,
      recDeal: {
        ...m?.recDeal,
        commitment: {
          ...m?.recDeal?.commitment,
          startDate: fromISODateString(m.recDeal?.commitment?.startDate),
          endDate: fromISODateString(m.recDeal?.commitment?.endDate)
        }
      },
      delDeal: {
        ...m?.delDeal,
        commitment: {
          ...m.delDeal?.commitment,
          startDate: fromISODateString(m.delDeal?.commitment?.startDate),
          endDate: fromISODateString(m.delDeal?.commitment?.endDate)
        }
      }
    }))
    .sort((m1, m2) => m1.startDate.getTime() - m2.startDate.getTime());
};

const compareMovementGroups = (g1: TMovementGroup, g2: TMovementGroup) => {
  return (
    //@ts-ignore
    Math.min(...g1.movements.map(m => m.startDate)) -
    //@ts-ignore
    Math.min(...g2.movements.map(m => m.startDate))
  );
};

export const createMovementGroups = (movements: TMovement[]): TMovementGroup[] => {
  if (movements && movements.length > 0) {
    //transform and sort movements
    const tMovements = transformMovements(movements);

    const groupByGroupId = tMovements.reduce<Record<string, TMovement[]>>((group, movement) => {
      const groupId = movement.groupId && +movement.groupId ? movement.groupId : movement.id;
      group[groupId] = group[groupId] ?? [];
      group[groupId].push(movement);
      group[groupId].sort((m1, m2) =>
        m1.enterpriseSystemCode.localeCompare(m2.enterpriseSystemCode)
      );
      return group;
    }, {});
    return Object.keys(groupByGroupId)
      .map(groupId => ({
        groupId,
        movements: [...groupByGroupId[groupId]],
        activeMovement: groupByGroupId[groupId][0]
      }))
      .sort(compareMovementGroups);
  }
  return [];
};
export const fromUSDateFormat = (val?: string | null) => {
  if (val != null && typeof val === "string") {
    return parseDate(val.trim(), "MM/dd/yyyy");
  }
  return null;
};
export const fromISODateString = (val?: string | Date) =>
  val == null || (val instanceof Date && !isNaN(val.getTime()))
    ? val
    : new Date(val + "T00:00:00");

export const fromISODateTimeString = (val?: string | Date) =>
  val == null || val instanceof Date ? val : new Date(val);

export const toISODateString = (val: Date) => toKString(val, "yyyy-MM-dd");
export const toISODateTimeString = (val: Date) => toKString(val, "yyyy-MM-ddTHH:mm:ss");

export const firstDayOfPrevMonth = (val: Date) => firstDayOfMonth(addMonths(val, -1));

export const lastDayOfNextMonth = (val: Date) => lastDayOfMonth(addMonths(val, 1));

export const isGainLoss = (movementGroup: TMovementGroup) =>
  movementGroup?.activeMovement?.activityType?.name &&
  (movementGroup.activeMovement.activityType.name.toLocaleLowerCase().includes("loss") ||
    movementGroup.activeMovement.activityType.name.toLocaleLowerCase().includes("gain"));

export const isInventoryGainLoss = (movement?: TMovement) =>
  equalsIgnoreCase(movement?.activityType?.name, "Inventory Gain") ||
  equalsIgnoreCase(movement?.activityType?.name, "Inventory Loss");

// prettier-ignore
export const toBatchFilterInput = (
  filter: TBatchSearchCriteria,
  esId: number
): TBatchFilterInput => {
  return {
    enterpriseSystemId: esId,
    statusId: filter.statusId ? { ne: filter.statusId } : undefined,
    startDate: { goe: toISODateString(filter.startDate) },
    endDate: { loe: toISODateString(filter.endDate) },
    batchId: toEqOrInQueryOperator<string, LongQueryOperatorInput>(
      filter.batches?.map((b) => b.id)
    ),
    carrierId: toEqOrInQueryOperator<string, LongQueryOperatorInput>(
      filter.carriers?.map((b) => b.id)
    ),
    counterPartyId: toEqOrInQueryOperator<number, IntegerQueryOperatorInput>(
      filter.counterParties?.map((b) => +b.id)
    ),
    facilityId: toEqOrInQueryOperator<string, LongQueryOperatorInput>(
      filter.facilities?.map((b) => b.id)
    ),
    internalLegalEntityId: toEqOrInQueryOperator<
      number,
      IntegerQueryOperatorInput
    >(filter.legalEntities?.map((b) => +b.id)),
    logisticsSystemId: toEqOrInQueryOperator<number, IntegerQueryOperatorInput>(
      filter.logisticsSystems?.map((b) => b.id)
    ),
    modeOfTransportId: toEqOrInQueryOperator<number, IntegerQueryOperatorInput>(
      filter.modeOfTransports?.map((b) => b.id)
    ),
    productId: toEqOrInQueryOperator<number, IntegerQueryOperatorInput>(
      filter.products?.map((b) => b.id)
    ),
    movementEnterpriseSystemCode: toEqOrInQueryOperator<
      string,
      StringQueryOperatorInput
    >(splitByComma(filter?.deliveryIds)),
    railcarNumber: toEqOrInQueryOperator<string, StringQueryOperatorInput>(
      splitByComma(filter?.railcarNumbers)
    ),
  };
};

export const toMovementFilterInputFromMovement = (
  movement: TMovement,
  startDate: Date,
  endDate: Date
): TMovementFilterInput => {
  return {
    batchId: toEqOrInQueryOperator<string, LongQueryOperatorInput>(movement.batch?.id),
    activityTypeId: toEqOrInQueryOperator<number, IntegerQueryOperatorInput>(
      movement.activityType?.id
    ),
    customsNumber: toEqOrInQueryOperator<string, StringQueryOperatorInput>(
      movement?.customsNumber
    ),
    delDealId: toEqOrInQueryOperator<string, LongQueryOperatorInput>(movement?.delDeal?.id),
    aggregationType: toEqOrInQueryOperator<string, StringQueryOperatorInput>(
      movement?.aggregationType
    ),
    facilityId: toEqOrInQueryOperator<string, LongQueryOperatorInput>(
      movement?.titleTransferFacility?.id
    ),
    internalLegalEntityId: toEqOrInQueryOperator<number, IntegerQueryOperatorInput>(
      +movement?.internalLegalEntity?.id
    ),
    logisticsSystemId: toEqOrInQueryOperator<number, IntegerQueryOperatorInput>(
      movement?.logisticsSystem?.id
    ),
    modeOfTransportId: toEqOrInQueryOperator<number, IntegerQueryOperatorInput>(
      movement?.batch.modeOfTransport?.id
    ),
    delStrategy: toEqOrInQueryOperator<string, StringQueryOperatorInput>(movement?.delStrategy),
    recStrategy: toEqOrInQueryOperator<string, StringQueryOperatorInput>(movement?.recStrategy),
    productId: toEqOrInQueryOperator<number, IntegerQueryOperatorInput>(movement?.product?.id),
    recDealId: toEqOrInQueryOperator<string, StringQueryOperatorInput>(movement?.recDeal?.id),
    unitOfMeasureId: toEqOrInQueryOperator<number, IntegerQueryOperatorInput>(
      movement?.unitOfMeasure?.id
    ),
    startDate: { goe: toISODateString(startDate) },
    endDate: { loe: toISODateString(endDate) },
    enterpriseSystemId: movement?.enterpriseSystem?.id
  };
};

export const toMovementFilterInput = (
  batchFilter: TBatchSearchCriteria,
  batchId: string,
  esId: number
): TMovementFilterInput => {
  const filter = toBatchFilterInput(batchFilter, esId);
  const movementEnterpriseSystemCode = filter.movementEnterpriseSystemCode;
  (filter as TMovementFilterInput).enterpriseSystemCode = movementEnterpriseSystemCode;
  filter.batchId = toEqOrInQueryOperator<string, LongQueryOperatorInput>([batchId]);
  delete filter.movementEnterpriseSystemCode;
  return filter;
};

export const toSearchLikedTicketFilterInput = (
  filter: TTicketSearchCriteria,
  esId: number
): TLinkedTicketFilterInput => {
  return {
    enterpriseSystemId: esId,
    enterpriseSystemCode: toEqOrInQueryOperator<string, StringQueryOperatorInput>(
      splitByComma(filter?.deliveryIds)
    )
  };
};

export const toSearchTicketFilterInput = (
  filter: TTicketSearchCriteria
): TTicketFilterInput => {
  return {
    startDate: { goe: toISODateString(filter.startDate) },
    endDate: { loe: toISODateString(filter.endDate) },
    batchId: toEqOrInQueryOperator<string, LongQueryOperatorInput>(
      filter.batches?.map(b => b.id)
    ),
    modeOfTransportId: toEqOrInQueryOperator<number, IntegerQueryOperatorInput>(
      filter.modeOfTransports?.map(b => b.id)
    ),
    ticketNumber: toEqOrInQueryOperator<string, StringQueryOperatorInput>(
      splitByComma(filter?.ticketNumbers)
    ),
    source: toEqOrInQueryOperator<TicketSource, TicketSourceFilterInput>(
      filter.ticketSource?.map(s => TicketSource[s as keyof typeof TicketSource])
    ),
    status: filter.ticketStatus ? filter.ticketStatus : undefined,
    lastModifiedBy: toEqOrInQueryOperator<string, StringQueryOperatorInput>(
      splitByComma(filter?.lastModifiedBy)
    )
  };
};

export const toTicketFilterInput = (filter: TBatchSearchCriteria): TTicketFilterInput => {
  return {
    startDate: { goe: toISODateString(filter.startDate) },
    endDate: { loe: toISODateString(filter.endDate) },
    batchId: toEqOrInQueryOperator<string, LongQueryOperatorInput>(
      filter.batches?.map(b => b.id),
      filter.includeNulls
    ),
    carrierId: toEqOrInQueryOperator<string, LongQueryOperatorInput>(
      filter.carriers?.map(b => b.id),
      filter.includeNulls
    ),
    facilityId: toEqOrInQueryOperator<string, LongQueryOperatorInput>(
      filter.facilities?.map(b => b.id),
      filter.includeNulls
    ),
    logisticsSystemId: toEqOrInQueryOperator<number, IntegerQueryOperatorInput>(
      filter.logisticsSystems?.map(b => b.id),
      filter.includeNulls
    ),
    modeOfTransportId: toEqOrInQueryOperator<number, IntegerQueryOperatorInput>(
      filter.modeOfTransports?.map(b => b.id),
      filter.includeNulls
    ),
    productId: toEqOrInQueryOperator<number, IntegerQueryOperatorInput>(
      filter.products?.map(b => b.id),
      filter.includeNulls
    ),
    ticketNumber: toEqOrInQueryOperator<string, StringQueryOperatorInput>(
      splitByComma(filter?.ticketNumbers),
      filter.includeNulls
    ),
    railcarNumber: toEqOrInQueryOperator<string, StringQueryOperatorInput>(
      splitByComma(filter?.railcarNumbers),
      filter.includeNulls
    ),
    source: toEqOrInQueryOperator<TicketSource, TicketSourceFilterInput>(
      filter.ticketSource?.map(s => TicketSource[s as keyof typeof TicketSource])
    ),
    shipFromCodeId: toEqOrInQueryOperator<string, LongQueryOperatorInput>(
      filter.shipFromCodes?.map(s => s.id)
    ),
    shipToCodeId: toEqOrInQueryOperator<string, LongQueryOperatorInput>(
      filter.shipToCodes?.map(s => s.id)
    ),
    status: filter.ticketStatus ? filter.ticketStatus : undefined
  };
};

export const toAvailableTicketFilterInput = (movement: TMovement): TTicketFilterInput => {
  return {
    startDate: {
      goe: toISODateString(movement.startDate as Date) //2659979
    },
    endDate: {
      loe: toISODateString(addDays(movement.startDate as Date, DAYS_IN_WEEK)) //2659979
    },
    batchId: toInOrNullQueryOperator<string, LongQueryOperatorInput>(
      Array.of(movement.batch?.id)
    ),
    facilityId: toInOrNullQueryOperator<string, LongQueryOperatorInput>(
      Array.of(movement.titleTransferFacility?.id)
    ),
    logisticsSystemId: toInOrNullQueryOperator<number, IntegerQueryOperatorInput>(
      Array.of(movement.logisticsSystem?.id)
    ),
    modeOfTransportId: toInOrNullQueryOperator<number, IntegerQueryOperatorInput>(
      Array.of(movement.batch.modeOfTransport?.id)
    ),
    productId: toInOrNullQueryOperator<number, IntegerQueryOperatorInput>(
      Array.of(movement.product?.id)
    ),
    status: TicketStatus.Unlinked
  };
};

export const toInOrNullQueryOperator = <T, R extends QueryOperatorInput<T>>(
  values: T[] | undefined | null
): R | undefined => {
  if (values && Array.isArray(values) && values.filter(Boolean).length > 0) {
    return {
      inOrNull: values
    } as R;
  }
  return undefined;
};
type TNullableArrayOrElement<T> = T | T[] | null | undefined;

export const toEqOrInQueryOperator = <T, R extends QueryOperatorInput<T>>(
  values: TNullableArrayOrElement<T>,
  includeNulls: boolean = false
): R | undefined => {
  if (values) {
    const valuesT = Array.isArray(values) ? values.filter(Boolean) : Array.of(values);
    if (valuesT && valuesT.length > 0) {
      return {
        eq: !includeNulls && valuesT.length === 1 ? valuesT.at(0) : undefined,
        in: !includeNulls && valuesT.length > 1 ? valuesT : undefined,
        inOrNull: includeNulls ? values : undefined
      } as R;
    }
  }
  return undefined;
};

export const buildMovementAllocationsPayload = (
  srcMovement: TMovement,
  destination: TAllocationMovement[] | null,
  reason: string
) => {
  return {
    variables: {
      srcId: srcMovement.id,
      version: srcMovement.version,
      volume: destination?.reduce((n, { quantityAllocated }) => n + quantityAllocated, 0) ?? 0,
      target: destination?.map(m => ({
        movementId: m.id,
        version: m.version,
        volume: m.quantityAllocated
      })) ?? [{ movementId: srcMovement.id, version: srcMovement.version, volume: 0 }],
      reason
    }
  };
};

export const isRailOrTruck = (movementGroup: TMovementGroup) => {
  return !!movementGroup.movements
    .map(m => m.batch.modeOfTransport.name)
    .find(
      mot =>
        equalsIgnoreCase(mot, MODE_OF_TRANSPORT_TRUCK) ||
        equalsIgnoreCase(mot, MODE_OF_TRANSPORT_RAIL)
    );
};

export const isMotRailOrTruck = (mot: string | undefined) => {
  return (
    equalsIgnoreCase(mot, MODE_OF_TRANSPORT_TRUCK) ||
    equalsIgnoreCase(mot, MODE_OF_TRANSPORT_RAIL)
  );
};

const isBatchMotRailOrTruck = (movement: TMovement | undefined) =>
  isMotRailOrTruck(movement?.batch?.modeOfTransport?.name);

export const dealRequiresGrossVolume = (movement: TMovement | undefined) =>
  (movement?.delDeal?.commitment?.requiredGrossVolume ?? false) ||
  (movement?.recDeal?.commitment?.requiredGrossVolume ?? false);

export const ticketRequiresGrossVolume = (
  movement: TMovement | undefined,
  motSelected?: Picklists.TModeOfTransport
) =>
  (dealRequiresGrossVolume(movement) && isBatchMotRailOrTruck(movement)) ||
  isMotRailOrTruck(motSelected?.name);

const movementRequiresVolAndMassUnit = (movement?: TMovement) => {
  return (
    (movement?.delDeal?.requireTicketVolAndMassUnit ?? false) ||
    (movement?.recDeal?.requireTicketVolAndMassUnit ?? false)
  );
};

export const hasVolumeRow = (ticket: TTicketInput) => {
  return ticket.volumes.some(v =>
    equalsIgnoreCase(v.unitOfMeasure?.unitOfMeasureClass.name, "Volume")
  );
};

export const hasMassRow = (ticket: TTicketInput) => {
  return ticket.volumes.some(v =>
    equalsIgnoreCase(v.unitOfMeasure?.unitOfMeasureClass.name, "Mass")
  );
};
//exclude Inventory Gain or Loss. https://sede-ds-adp.visualstudio.com/MACk/_workitems/edit/2997072
export const requireVolAndMassUnit = (movement?: TMovement) => {
  return (
    movementRequiresVolAndMassUnit(movement) ||
    (!isInventoryGainLoss(movement) &&
      movement?.recDeal?.qtyUnitOfMeasure?.unitOfMeasureClass.name !==
        movement?.delDeal?.qtyUnitOfMeasure?.unitOfMeasureClass.name)
  );
};

const isNumeric = (input: string): boolean => !Number.isNaN(input); // you may also check if the value is a nonzero positive integer
const isOrdered = (start: string, end: string): boolean =>
  parseInt(start, 10) <= parseInt(end, 10);

const MIN_RANGE_LENGTH = 2;
const isRangeValid = (range: string[], max: number): boolean =>
  range.length === MIN_RANGE_LENGTH &&
  range.every(isNumeric) &&
  isOrdered(range[0], range[1]) &&
  +range[1] <= max;

const isSingleValid = (single: string[], max: number): boolean =>
  single.length === 1 && isNumeric(single[0]) && +single[0] <= max;

export const isValidPageRange = (input: string, max: number): boolean => {
  const inputs = input.split(",").map(x => x.trim());
  for (const x of inputs) {
    if (!x) {
      return false;
    }
    const pages = x.split("-");
    if (pages.length === 1) {
      return isSingleValid(pages, max);
    }
    if (!isRangeValid(pages, max)) {
      return false;
    }
  }
  return true;
};

export const toTicketInputAdd = (
  t: TTicketInput,
  documentId: string | null
): TTicketInputAdd => {
  const ticketInput: TTicketInputAdd = {
    ticketNumber: t.ticketNumber!,
    startDate: toISODateTimeString(getDate(t.startDate!)),
    endDate: toISODateTimeString(getDate(t.startDate!)),
    batchId: t.batch?.id,
    productId: t.product?.id,
    facilityId: t.facility?.id,
    logisticsSystemId: t.logisticsSystem?.id,
    carrierId: t.carrier?.id,
    carrierScacCode: t.carrierScacCode?.trim(),
    modeOfTransportId: t.modeOfTransport?.id,
    shipFromCodeId: t.shipFromCode?.id,
    shipToCodeId: t.shipToCode?.id,
    source: TicketSource.Manual,
    pageNumbers: trim(t.pageNumbers),
    invoiceComment: trim(t.invoiceComment),
    extSourceTicketRef: trim(t.extSourceTicketRef),
    borderCrossingDate:
      t.borderCrossingDate != null
        ? toISODateTimeString(getDate(t.borderCrossingDate))
        : undefined,
    poNumber: trim(t.poNumber),
    documentId: documentId?.toString() ?? undefined,
    volumes: toVolumeInputAdd(t.volumes),
    railcars: trim(t.railcars)
      ? trim(t.railcars)
          ?.split(",")
          .map((r: string) => ({ railcarNumber: r }))
      : undefined
  };
  return ticketInput;
};

const toVolumeInputAdd = (volumes: TVolumeInput[]): TVolumeInputAdd[] => {
  return volumes.map((v, i) => ({
    sequence: i + 1,
    netVolume: v.netVolume!,
    grossVolume: v.grossVolume,
    unitOfMeasureId: v.unitOfMeasure?.id!,
    temperature: v.temperature!,
    temperatureUnitOfMeasure: v.temperatureUnitOfMeasure?.id!
  }));
};

export const toTicketInputUpdate = (
  t: TTicketInput,
  documentId: string | null,
  showZeroOutTicketConfirmation: boolean
): TTicketInputUpdate => {
  const ticketInput: TTicketInputUpdate = {
    id: t.id!,
    version: t.version!,
    ticketNumber: t.ticketNumber!,
    startDate: toISODateTimeString(getDate(t.startDate!)),
    endDate: toISODateTimeString(getDate(t.startDate!)),
    batchId: t.batch?.id ?? null,
    productId: t.product?.id ?? null,
    facilityId: t.facility?.id ?? null,
    carrierId: t.carrier?.id ?? null,
    carrierScacCode: t.carrierScacCode?.trim() ?? null,
    logisticsSystemId: t.logisticsSystem?.id ?? null,
    modeOfTransportId: t.modeOfTransport?.id ?? null,
    shipFromCodeId: t.shipFromCode?.id ?? null,
    shipToCodeId: t.shipToCode?.id ?? null,
    pageNumbers: trim(t.pageNumbers) ?? null,
    invoiceComment: trim(t.invoiceComment) ?? null,
    extSourceTicketRef: trim(t.extSourceTicketRef) ?? null,
    borderCrossingDate:
      t.borderCrossingDate != null ? toISODateTimeString(getDate(t.borderCrossingDate)) : null,
    poNumber: trim(t.poNumber) ?? null,
    documentId: documentId?.toString() ?? null,
    volumes: toVolumeInputUpdate(t.volumes, showZeroOutTicketConfirmation),
    railcars: trim(t.railcars)
      ? trim(t.railcars)
          ?.split(",")
          .map((r: string) => ({ railcarNumber: r })) ?? null
      : null
  };
  return ticketInput;
};

const toVolumeInputUpdate = (
  volumes: TVolumeInput[],
  showZeroOutTicketConfirmation: boolean
): TVolumeInputUpdate[] => {
  return volumes.map((v, i) => ({
    id: v.isNew ? null : v.id,
    sequence: i + 1,
    netVolume: showZeroOutTicketConfirmation ? 0.0000001 : v.netVolume!,
    grossVolume: showZeroOutTicketConfirmation ? 0.0000001 : v.grossVolume ?? null,
    unitOfMeasureId: v.unitOfMeasure?.id!,
    temperature: v.temperature!,
    temperatureUnitOfMeasure: v.temperatureUnitOfMeasure?.id!
  }));
};

export const truncateTo = (num: number, places: number) => {
  return Math.trunc(num * Math.pow(10, places)) / Math.pow(10, places);
};

export const isCarrierRequired = (
  movementGroup: TMovementGroup,
  ticket: TTicketInput
): boolean => (isSOPUS(movementGroup) || isSTUSCO(movementGroup)) && isTruckOrRail(ticket);

export const isMovementDaily = (
  activeMovementGroup: TMovementGroup | undefined | null
): boolean => {
  return (
    activeMovementGroup?.movements.some(m => !equalsIgnoreCase(m.aggregationType, "monthly")) ??
    false
  );
};

export const findPrimaryMovement = (movementGroup: TMovementGroup) => {
  return movementGroup.movements
    .toSorted((m1, m2) => m1.enterpriseSystemCode.localeCompare(m2.enterpriseSystemCode))
    .at(0)!;
};

export const isDateAfterToday = (ticketDate: Date) => {
  const ticketDateWithOutTime = new Date(ticketDate.getTime());
  ticketDateWithOutTime.setHours(0, 0, 0, 0);
  const currentCstDate = new Date(
    new Date().toLocaleDateString("en-US", { timeZone: "America/Chicago" })
  );
  currentCstDate.setHours(0, 0, 0, 0); // Set time to 00:00:00 to compare only dates
  return ticketDateWithOutTime > currentCstDate;
};
