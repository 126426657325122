//import React from 'react'

import { Icon } from "@progress/kendo-react-common";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import {
  DropDownList,
  DropDownListChangeEvent,
  DropDownListFilterChangeEvent,
  ListItemProps
} from "@progress/kendo-react-dropdowns";
import { GridCellProps } from "@progress/kendo-react-grid";
import { InputClearValue } from "@progress/kendo-react-inputs";
import React, { ReactNode } from "react";
import { TBulkImportTicketRefField, TFieldConfig, TPdfFile, TRefData } from "../types";

const itemRender = (li: React.ReactElement<HTMLLIElement>, _itemProps: ListItemProps) => {
  const itemChildren = (
    <span style={{ fontSize: "0.75rem" }}>{li.props.children as ReactNode}</span>
  );
  return React.cloneElement(li, li.props, itemChildren);
};
const INVALID_ITEM_ID = -9999;
const valueRender = (
  element: React.ReactElement<HTMLSpanElement>,
  value: TRefData | null,
  clear: (e: React.MouseEvent<HTMLSpanElement>) => void
) => {
  if (!value) {
    return element;
  }
  const children = [
    <span key="1" className="k-input-inner bulk-import-cell-input">
      {value.name}
    </span>,
    <InputClearValue key="2" onClick={clear}>
      <Icon name="x" />
    </InputClearValue>
  ];

  return React.cloneElement(element, { ...element.props }, children);
};

type DropDownListCellProps = GridCellProps & {
  fieldConfig: TFieldConfig;
  data?: TRefData[] | TPdfFile[];
  onSearch?: (val: string) => void;
  onDone?: () => void;
  loading?: boolean;
};
const DEFULT_FILTER_LENGTH = 3;
export const DropDownListCell = (props: DropDownListCellProps) => {
  const {
    ariaColumnIndex,
    columnIndex,
    dataItem,
    field,
    loading,
    data,
    fieldConfig,
    onChange,
    onSearch,
    onDone,
    render
  } = props;
  const isInEdit = field === dataItem.inEdit;

  const refFieldValue: TBulkImportTicketRefField | null = field && dataItem[field];
  const navigationAttributes = useTableKeyboardNavigation(props.id);

  const onFilterChange = (e: DropDownListFilterChangeEvent) => {
    if (e.filter.value.trim().length >= DEFULT_FILTER_LENGTH) {
      onSearch?.(e.filter.value.trim());
    }
  };

  const handleOnChange = (e: DropDownListChangeEvent) => {
    changeValue(e.syntheticEvent, { ...(refFieldValue ?? {}), value: e.value });
  };
  const changeValue = React.useCallback(
    (e: React.SyntheticEvent<HTMLElement>, changedValue: TBulkImportTicketRefField | null) => {
      onDone?.();
      if (onChange) {
        onChange({
          dataIndex: 0,
          dataItem,
          field,
          value: changedValue,
          syntheticEvent: e
        });
      }
    },
    [onDone, onChange, dataItem, field]
  );

  const clear = (e: React.MouseEvent<HTMLSpanElement>) => {
    changeValue(e, { initial: "", value: null });
  };

  const isValid = refFieldValue?.value != null;
  const defaultRendering = (
    <td
      aria-colindex={ariaColumnIndex}
      data-grid-col-index={columnIndex}
      role={"gridcell"}
      {...navigationAttributes}>
      {isInEdit ? (
        <div>
          <DropDownList
            loading={loading}
            data={data}
            value={refFieldValue?.value}
            filterable={fieldConfig?.typeAhead}
            onFilterChange={onFilterChange}
            onChange={handleOnChange}
            textField="name"
            className="bulk-import-cell-input"
            itemRender={itemRender}
            valueRender={(e, v) => valueRender(e, v, clear)}
            size={"small"}
            onBlur={onDone}
            opened={true}
            defaultItem={
              isValid ? undefined : { id: INVALID_ITEM_ID, name: refFieldValue?.initial }
            }
          />
        </div>
      ) : (
        <span className="txt-preserve-whitespace">
          {!isValid && fieldConfig?.isRequired && (
            <span className="k-icon-xs k-icon bt-i-warning k-color-error"></span>
          )}
          {!isValid && !fieldConfig?.isRequired && refFieldValue?.initial && (
            <span className="k-icon-xs k-icon bt-i-warning k-color-warning"></span>
          )}
          <span>{refFieldValue?.value?.name ?? refFieldValue?.initial ?? ""}</span>
        </span>
      )}
    </td>
  );

  return render?.(defaultRendering, props) ?? <td></td>;
};
