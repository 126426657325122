import { Button, FormField, Modal, Select, Variants } from "@sede-x/shell-ds-react-framework";
import { CIQOption, TProductBlendFetchData } from "carbonIQ/carbonIQtypes";
import { useState } from "react";

const ProductBlendSearch = ({
  onClose,
  onSubmit,
  masterData
}: {
  onClose: () => void;
  onSubmit: (data: CIQOption[]) => void;
  masterData: TProductBlendFetchData[] | undefined;
}) => {
  const productList = masterData?.map(elem => {
    return {
      value: elem.name,
      label: elem.name,
      key: elem.id
    };
  });

  const [selectedProducts, setSelectedProducts] = useState<CIQOption[]>([]);

  return (
    <Modal
      title="Search Generic Product"
      width={"700px"}
      height={"600px"}
      mask={true}
      open={true}
      maskClosable={false}
      onClose={onClose}>
      <form
        onSubmit={event => {
          event.preventDefault();
          onSubmit(selectedProducts);
        }}>
        <FormField size={"medium"} id="customer-name-label" label="Generic Product name">
          <Select
            options={productList}
            size={"medium"}
            id="product-name"
            placeholder="Select Generic Product name"
            optionLabelProp="label"
            filterOption={true}
            optionFilterProp="label"
            mode="tags"
            labelInValue={true}
            onChange={(data: CIQOption[]) =>
              setSelectedProducts(data.filter(o => Boolean(o.key)))
            }
          />
        </FormField>
        <div style={{ paddingBottom: "200px" }}></div>

        <div className="from-button-wrap">
          <Button variant={Variants.Outlined} onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit">Search</Button>
        </div>
      </form>
    </Modal>
  );
};

export default ProductBlendSearch;
