import { ApolloError } from "@apollo/client";
import {
  Button,
  Flexbox,
  Label,
  Popover,
  Positions,
  Sizes,
  Variants
} from "@sede-x/shell-ds-react-framework";
import { Filter } from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { useCallback, useContext, useEffect, useState } from "react";
import { ApolloErrorViewer } from "shared/components/ApolloErrorViewer";
import InlineLoadingPanel from "shared/components/InlineLoadingPanel";
import { TMovementGroup } from "ticketing/ticketing.types";
import { MovementFilter, TMovementFilter } from "./MovementFilter";
import MovementGroup from "./MovementGroup";
import MovementGroupContainerLabel from "./MovementGroupContainerLabel";
import "./movements.css";
import { MovementsMainContext } from "./utils";
dayjs.extend(isBetween);

type MovementGroupContainerProps = {
  movementGroups?: TMovementGroup[] | null;
  activeMovementGroup: TMovementGroup | null;
  loading?: boolean;
  error?: ApolloError | null;
};

/**
 *
 * @param param0
 * @returns
 */
const MovementGroupContainer = ({
  movementGroups,
  activeMovementGroup,
  loading,
  error
}: MovementGroupContainerProps) => {
  const { onActiveMovementGroupChanged } = useContext(MovementsMainContext);
  const [filteredMovemenentGroups, setFilteredMovemenentGroups] = useState<
    TMovementGroup[] | null | undefined
  >();

  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState<TMovementFilter>({
    dateRange: null,
    selectedStatuses: null,
    selectedProducts: null,
    selectedFacilities: null,
    selectedActivityTypes: null,
    selectedDelContracts: null,
    selectedRecContracts: null
  });

  const applyFilters = useCallback(
    (movementFilter: TMovementFilter, groups: TMovementGroup[] | null | undefined) => {
      setFilteredMovemenentGroups(
        groups
          ?.filter(
            g =>
              movementFilter.selectedStatuses == null ||
              movementFilter.selectedStatuses.length === 0 ||
              movementFilter.selectedStatuses.includes(g.activeMovement.status.id)
          )
          .filter(
            g =>
              movementFilter.dateRange == null ||
              g.movements.some(m =>
                dayjs(m.startDate).isBetween(
                  movementFilter.dateRange![0],
                  movementFilter.dateRange![1],
                  "day",
                  "[]"
                )
              )
          )
          .filter(
            g =>
              movementFilter.selectedProducts == null ||
              movementFilter.selectedProducts.length === 0 ||
              g.movements.some(m => movementFilter.selectedProducts?.includes(m.product.name))
          )
          .filter(
            g =>
              movementFilter.selectedFacilities == null ||
              movementFilter.selectedFacilities.length === 0 ||
              g.movements.some(m =>
                movementFilter.selectedFacilities?.includes(m.titleTransferFacility.name)
              )
          )
          .filter(
            g =>
              movementFilter.selectedActivityTypes == null ||
              movementFilter.selectedActivityTypes.length === 0 ||
              g.movements.some(
                m =>
                  m.activityType?.name &&
                  movementFilter.selectedActivityTypes?.includes(m.activityType?.name)
              )
          )
          .filter(
            g =>
              movementFilter.selectedDelContracts == null ||
              movementFilter.selectedDelContracts.length === 0 ||
              g.movements.some(
                m =>
                  m.delDeal.contractNumber &&
                  movementFilter.selectedDelContracts?.includes(m.delDeal.contractNumber)
              )
          )
          .filter(
            g =>
              movementFilter.selectedRecContracts == null ||
              movementFilter.selectedRecContracts.length === 0 ||
              g.movements.some(
                m =>
                  m.recDeal.contractNumber &&
                  movementFilter.selectedRecContracts?.includes(m.recDeal.contractNumber)
              )
          )
      );
    },
    []
  );

  useEffect(() => {
    applyFilters(filter, movementGroups);
  }, [movementGroups, applyFilters, filter]);

  useEffect(() => {
    if (
      activeMovementGroup &&
      !filteredMovemenentGroups?.map(g => g.groupId).includes(activeMovementGroup.groupId)
    ) {
      onActiveMovementGroupChanged?.(null);
    }
  }, [activeMovementGroup, filteredMovemenentGroups, onActiveMovementGroupChanged]);

  return (
    <>
      <Flexbox
        className="container-component-title"
        justifyContent="space-between"
        alignItems="center">
        <span>Movements</span>
        <MovementGroupContainerLabel movementGroups={filteredMovemenentGroups} />
        {(movementGroups?.length ?? 0) > 0 && (
          <Flexbox flexDirection="row" alignItems="center" gap="8px">
            {filteredMovemenentGroups?.length !== movementGroups?.length && (
              <Label
                size="small"
                prominence="subtle">{`Showing ${filteredMovemenentGroups?.length} of ${movementGroups?.length}`}</Label>
            )}
            <Popover
              popup={
                <MovementFilter
                  movementGroups={movementGroups}
                  filter={filter}
                  onFilterChange={f => setFilter(f)}
                  onClose={() => setShowFilter(false)}
                />
              }
              popupAlign={{
                points: ["tr", "tl"],
                offset: [0, -100]
              }}
              popupVisible={showFilter}
              arrow={false}
              onPopupVisibleChange={setShowFilter}>
              <Button
                variant={Variants.Transparent}
                size={Sizes.ExtraSmall}
                icon={<Filter />}
                onClick={() => setShowFilter(true)}
                sentiment={
                  filteredMovemenentGroups?.length === movementGroups?.length
                    ? "primary"
                    : "negative"
                }
                iconPosition={Positions.Right}></Button>
            </Popover>
          </Flexbox>
        )}
      </Flexbox>

      <div className="movement-batch-container-wrapper">
        {loading && <InlineLoadingPanel />}
        {error && <ApolloErrorViewer error={error} />}
        {(filteredMovemenentGroups?.length ?? 0) === 0 && (
          <div>
            <span className="availabel-tkt-container-wrapper">No data available.</span>
          </div>
        )}
        {filteredMovemenentGroups?.map(m => (
          <MovementGroup
            key={`${m.groupId}`}
            movementGroup={m}
            activeMovementGroup={activeMovementGroup}
          />
        ))}
      </div>
    </>
  );
};

export default MovementGroupContainer;
