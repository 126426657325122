import { ApolloError, useMutation } from "@apollo/client";
import {
  Checkbox,
  Drawer,
  FormField,
  Heading,
  Sentiments,
  Sizes,
  TextInput,
  TextTypes,
  Variants
} from "@sede-x/shell-ds-react-framework";
import { TComponentsFilter } from "carbonIQ/carbonIQtypes";
import { loader } from "graphql.macro";
import { useState, WheelEvent } from "react";
import { ApolloErrorViewer } from "shared/components/ApolloErrorViewer";
import LoadingPanel from "shared/components/LoadingPanel";

const ciAddNewBlend = loader("../graphql/mutation-CarbonAddNewBlend.graphql");

const ciUpdateBlend = loader("../graphql/mutation-CarbonUpdateBlend.graphql");

const AddOrUpdateBlendComponents = ({
  details,
  onClose,
  onSubmit
}: {
  details: TComponentsFilter | null;
  onClose: () => void;
  onSubmit: () => void;
}) => {
  const numberInputOnWheelPreventChange = (e: WheelEvent<HTMLInputElement>) => {
    // Prevent the input value change
    (e.target as HTMLInputElement).blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    // Refocus immediately, on the next tick (after the current function is done)
    setTimeout(() => (e.target as HTMLInputElement).focus(), 0);
  };

  const isStateAdd = details === null || details === undefined;

  const [preFillBlendDetails, setPreFillBlendDetails] = useState<TComponentsFilter | null>(
    details
  );

  // Add New Blend
  const [addNewBlend, { loading: addNewBlendLoading, error: addNewBlendlError }] =
    useMutation(ciAddNewBlend);

  // Update New Blend
  const [updateBlend, { loading: updateBlendLoading, error: updateBlendError }] =
    useMutation(ciUpdateBlend);

  const onSubmitClick = () => {
    const tempdata = {
      energyDensity: preFillBlendDetails?.energyDensity,
      isRenewable: !!preFillBlendDetails?.isRenewable,
      name: preFillBlendDetails?.name?.trim()
    };

    isStateAdd
      ? addNewBlend({
          variables: {
            component: tempdata
          },
          onCompleted: onSubmit
        })
      : updateBlend({
          variables: {
            component: {
              ...tempdata,
              id: details?.id,
              version: details?.version
            }
          },
          onCompleted: onSubmit
        });
  };

  const isLoading = [updateBlendLoading, addNewBlendLoading].some(elm => elm);
  const isError = [updateBlendError, addNewBlendlError].some(e => e);
  const errors = [updateBlendError, addNewBlendlError].filter((e): e is ApolloError =>
    Boolean(e)
  );
  return (
    <Drawer
      header={
        <Heading className="setting-form-heading" type={TextTypes.H2}>
          {(isStateAdd ? "Add New" : "Update") + " Component"}
        </Heading>
      }
      borders={false}
      closeButton={false}
      sticky
      mask={true}
      open={true}
      maskClosable={false}
      size={Sizes.Medium}
      onClose={onClose}
      actions={[
        {
          label: "Cancel",
          action: onClose,
          props: {
            variant: Variants.Outlined
          }
        },
        {
          label: "Save",
          action: onSubmitClick,
          props: {
            disabled:
              preFillBlendDetails?.name === "" ||
              (!!preFillBlendDetails?.energyDensity &&
                (preFillBlendDetails?.energyDensity > 100.0 ||
                  preFillBlendDetails?.energyDensity < -100.0))
          }
        }
      ]}>
      {isLoading && <LoadingPanel />}
      {isError && <ApolloErrorViewer error={errors} />}
      {!isStateAdd && <p>Updating product - Warrning message</p>}
      <form>
        <FormField
          size={"medium"}
          id="blend-name-label"
          label="Blend Name"
          mandatory={true}
          bottomLeftHelper={{
            content: preFillBlendDetails?.name === "" ? "Field can't be blank." : "",
            sentiment: Sentiments.Negative
          }}>
          <TextInput
            onChange={event => {
              const tempObj = Object.create(preFillBlendDetails);
              tempObj.name = event.target.value;
              setPreFillBlendDetails(tempObj);
            }}
            invalid={preFillBlendDetails?.name === ""}
            value={preFillBlendDetails?.name ?? ""}
            required
          />
        </FormField>
        <FormField
          size={"medium"}
          key={"key"}
          mandatory={true}
          id="blend-energy-density"
          label="Energy Density"
          bottomLeftHelper={{
            content:
              preFillBlendDetails?.energyDensity &&
              (preFillBlendDetails?.energyDensity > 100.0 ||
                preFillBlendDetails?.energyDensity < -100.0)
                ? "Value should be on range -100 to 100."
                : "",
            sentiment: Sentiments.Negative
          }}>
          <TextInput
            type={"number"}
            value={preFillBlendDetails?.energyDensity ? +preFillBlendDetails?.energyDensity : 0}
            max={100.0}
            min={-100.0}
            invalid={
              !!preFillBlendDetails?.energyDensity &&
              (preFillBlendDetails?.energyDensity > 100.0 ||
                preFillBlendDetails?.energyDensity < -100.0)
            }
            step=".0001"
            onWheel={numberInputOnWheelPreventChange}
            onChange={event => {
              const tempObj = Object.create(preFillBlendDetails);
              tempObj.energyDensity =
                Math.round((Number(event.target.value) + Number.EPSILON) * 10000) / 10000;
              setPreFillBlendDetails(tempObj);
            }}
          />
        </FormField>

        <Checkbox
          label="Renewable"
          size={Sizes.Large}
          checked={preFillBlendDetails?.isRenewable}
          onChange={() => {
            const tempObj = Object.create(preFillBlendDetails);
            tempObj.isRenewable = !preFillBlendDetails?.isRenewable;
            setPreFillBlendDetails(tempObj);
          }}
        />
      </form>
    </Drawer>
  );
};

export default AddOrUpdateBlendComponents;
