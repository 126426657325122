import { toString as toKString } from "@progress/kendo-intl";
import { Flexbox } from "@sede-x/shell-ds-react-framework";
import { TMovementGroup } from "ticketing/ticketing.types";
import { isGainLoss } from "ticketing/utils";

const MovementGroupContainerLabel = ({
  movementGroups
}: {
  movementGroups: TMovementGroup[] | null | undefined;
}) => {
  if (movementGroups == null || movementGroups.length === 0) {
    return <></>;
  }

  const numUom = [...new Set(movementGroups.map(m => m.activeMovement.unitOfMeasure.id))]
    .length;

  if (numUom !== 1) {
    return <></>;
  }

  const isTransitComplete =
    movementGroups.some(g => Boolean(g.activeMovement.batch.transitComplete)) ?? false;

  const isGainLossNom = (movementGroup: TMovementGroup) =>
    movementGroup?.activeMovement?.isActualizedExternally && //2575178
    isGainLoss(movementGroup);

  const movementScheduledQuantity = (movementGroup: TMovementGroup) =>
    isGainLossNom(movementGroup)
      ? movementGroup.activeMovement.netQuantity
      : movementGroup.activeMovement.scheduledQuantity;

  const movementRemainingQuantity = (movementGroup: TMovementGroup) =>
    movementGroup?.activeMovement?.actualizationComplete
      ? 0
      : Math.max(
          0,
          movementScheduledQuantity(movementGroup) -
            (movementGroup.activeMovement.actualizedQuantity ?? 0)
        );

  const totalScheduledQuantity = movementGroups.reduce(
    (accumulator, currentValue) => movementScheduledQuantity(currentValue) + accumulator,
    0
  );

  const totlaActualizedQuantity = movementGroups.reduce(
    (accumulator, currentValue) =>
      accumulator + (currentValue.activeMovement.actualizedQuantity ?? 0),
    0
  );

  const totalRemainingQuantity = movementGroups.reduce(
    (accumulator, currentValue) => accumulator + movementRemainingQuantity(currentValue),
    0
  );

  return (
    <Flexbox gap="16px">
      <span className="k-white-space-pre" style={{ textDecoration: "underline" }}>
        Sch:&nbsp;
        <span style={{ color: "var(--primary-icon-color)" }}>
          {toKString(totalScheduledQuantity, "0.0###")}
        </span>
      </span>
      <span className="k-white-space-pre" style={{ textDecoration: "underline" }}>
        Act:&nbsp;
        <span style={{ color: "limegreen" }}>
          {toKString(totlaActualizedQuantity, "0.0###")}
        </span>
      </span>
      {!isTransitComplete && (
        <span className="k-white-space-pre" style={{ textDecoration: "underline" }}>
          Rem:&nbsp;
          <span style={{ color: "orangered" }}>
            {toKString(totalRemainingQuantity, "0.0###")}
          </span>
        </span>
      )}
    </Flexbox>
  );
};

export default MovementGroupContainerLabel;
