import "@progress/kendo-date-math/tz/America/Chicago";
import {
  Button,
  Flexbox,
  FormField,
  Grid,
  Label,
  RangeDatePicker,
  RangePickerValueType,
  Select,
  Text,
  TextInput,
  Toggle
} from "@sede-x/shell-ds-react-framework";

import dayjs, { Dayjs } from "dayjs";
import { debounce } from "lodash";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import LoadingPanel from "shared/components/LoadingPanel";
import { usePicklists } from "ticketing/contexts/picklists/PicklistContextProvider";
import useSearchBatches from "ticketing/hooks/useSearchBatches";
import useSearchCarriers from "ticketing/hooks/useSearchCarriers";
import useSearchCounterParties from "ticketing/hooks/useSearchCounterParties";
import useSearchFacilities from "ticketing/hooks/useSearchFacilities";
import useSearchProducts from "ticketing/hooks/useSearchProducts";
import useSearchShipCodes from "ticketing/hooks/useSearchShipCodes";
import { TBatchSearchCriteria, TicketSource, TicketStatus } from "ticketing/ticketing.types";
import "./SearchBatches.css";

type TSearchMovementsOrTicketsProps = {
  searchCriteria?: TBatchSearchCriteria;
  onSearch: (criteria: TBatchSearchCriteria) => void;
  searchFor: "Movements" | "Tickets" | "AvailableTickets";
  onReset?: () => void;
};

const labelStyle = {
  fontWeight: "bold",
  whiteSpace: "nowrap",
  textAlign: "right" as const
};
/**
 *
 * @param props
 * @returns
 */
const MIN_FILTER_LENGTH = 3;
const MIN_WAIT_MILLISEC = 500;
const firstDayOfPrevMonth = dayjs().add(-1, "month").startOf("month");
const lastDayOfNextMonth = dayjs().add(1, "month").endOf("month");

export const SearchMovementsOrTicketsForm = (props: TSearchMovementsOrTicketsProps) => {
  const { picklists, isLoading, isReady } = usePicklists();
  const [searchBatches, batchSearchResults, bLoading] = useSearchBatches();
  const [searchProducts, productsSearchResults, pLoading] = useSearchProducts();
  const [searchCounterparties, cpSearchResults, cpLoading] = useSearchCounterParties();
  const [searchFacilities, facilitiesSearchResults, fLoading] = useSearchFacilities();
  const [searchCarriers, carrierSearchResults, cLoading] = useSearchCarriers();
  const [searchShipFromCodes, shipFromCodes, sfLoading] = useSearchShipCodes();
  const [searchShipToCodes, shipToCodes, stoLoading] = useSearchShipCodes();
  const [searchDateRange, setSearchDateRange] = useState<RangePickerValueType<Dayjs>>([
    firstDayOfPrevMonth,
    lastDayOfNextMonth
  ]);

  const { control, handleSubmit, reset, setValue } = useForm<TBatchSearchCriteria>({
    defaultValues: props.searchCriteria
  });

  if (isLoading || !isReady) {
    return <LoadingPanel />;
  }
  const lookupShipToCodes = (code: string) =>
    searchShipToCodes({ shipCodeType: "SHIP_TO", code });

  const lookupShipFromCodes = (code: string) =>
    searchShipFromCodes({ shipCodeType: "SHIP_FROM", code });

  const resetForm = () => {
    setSearchDateRange([firstDayOfPrevMonth, lastDayOfNextMonth]);
    reset();
    props.onReset?.();
  };
  const onSubmit = (data: TBatchSearchCriteria) => props.onSearch?.(data);
  const debounceSearch = (searchFn: (e: string) => void) =>
    debounce((e: string) => {
      if (e.length >= MIN_FILTER_LENGTH) {
        searchFn(e.toString());
      }
    }, MIN_WAIT_MILLISEC);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid columns={"repeat(3, 6rem 1fr)"} columnGap="8px" rowGap="0px">
        <Grid.Cell centerContent={true}>
          <Label style={labelStyle}>Start/End Date</Label>
        </Grid.Cell>
        <Grid.Cell centerContent={true}>
          <FormField size="small" style={{ marginBottom: "4px" }}>
            <RangeDatePicker
              value={searchDateRange}
              size="small"
              picker="date"
              placeholder={["Start date", "End date"]}
              allowClear={false}
              style={{ zIndex: "9999" }}
              onChange={dates => {
                if (dates?.[0]) {
                  setValue("startDate", dates[0].toDate());
                }
                if (dates?.[1]) {
                  setValue("endDate", dates[1].toDate());
                }
                setSearchDateRange(dates);
              }}
            />
          </FormField>
        </Grid.Cell>
        <Grid.Cell centerContent={true}>
          <Label style={labelStyle}>{"Products"}</Label>
        </Grid.Cell>
        <Grid.Cell centerContent={true}>
          <FormField size="small" style={{ marginBottom: "4px" }}>
            <Controller
              control={control}
              name="products"
              render={({ field }) => (
                <Select
                  value={field.value?.map(v => v.id) ?? []}
                  onChange={(_, selOpts) => field.onChange(selOpts)}
                  onClear={() => field.onChange([])}
                  size="small"
                  fieldNames={{ label: "name", value: "id" }}
                  optionLabelProp="name"
                  optionFilterProp="name"
                  //@ts-ignore
                  options={productsSearchResults}
                  loading={pLoading}
                  mode="multiple"
                  showSearch={true}
                  onSearch={e => debounceSearch(searchProducts)(e)}
                />
              )}
            />
          </FormField>
        </Grid.Cell>
        <Grid.Cell centerContent={true}>
          <Label style={labelStyle}>{"Facilities"}</Label>
        </Grid.Cell>
        <Grid.Cell centerContent={true}>
          <FormField size="small" style={{ marginBottom: "4px" }}>
            <Controller
              control={control}
              name="facilities"
              render={({ field }) => (
                <Select
                  value={field.value?.map(v => v.id) ?? []}
                  onChange={(_, selectedOptions) => field.onChange(selectedOptions)}
                  onClear={() => field.onChange([])}
                  size={"small"}
                  fieldNames={{ label: "name", value: "id" }}
                  optionLabelProp="name"
                  optionFilterProp="name"
                  //@ts-ignore
                  options={facilitiesSearchResults}
                  loading={fLoading}
                  mode="multiple"
                  showSearch={true}
                  onSearch={e => debounceSearch(searchFacilities)(e)}
                />
              )}
            />
          </FormField>
        </Grid.Cell>
        <Grid.Cell centerContent={true}>
          <Label style={labelStyle}>{"Batch Name"}</Label>
        </Grid.Cell>
        <Grid.Cell centerContent={true}>
          <FormField size="small" style={{ marginBottom: "4px" }}>
            <Controller
              control={control}
              name="batches"
              render={({ field }) => (
                <Select
                  value={field.value?.map(v => v.id)}
                  onChange={(_, selOpts) => field.onChange(selOpts)}
                  onClear={() => field.onChange([])}
                  size="small"
                  fieldNames={{ label: "name", value: "id" }}
                  optionLabelProp="name"
                  optionFilterProp="name"
                  //@ts-ignore
                  options={batchSearchResults.length ? batchSearchResults : field.value}
                  loading={bLoading}
                  mode="multiple"
                  showSearch={true}
                  onSearch={e => debounceSearch(searchBatches)(e)}
                />
              )}
            />
          </FormField>
        </Grid.Cell>

        {props.searchFor === "AvailableTickets" && (
          <>
            <Grid.Cell centerContent={true}>
              <Label style={labelStyle}>{"Status"}</Label>
            </Grid.Cell>
            <Grid.Cell centerContent={true}>
              <FormField size="small" style={{ marginBottom: "4px" }}>
                <Controller
                  control={control}
                  name="ticketStatus"
                  render={({ field }) => (
                    <Select
                      value={field.value ?? ""}
                      onChange={field.onChange}
                      onClear={() => field.onChange([])}
                      options={Object.values(TicketStatus)
                        .filter(v => isNaN(Number(v)))
                        .map(status => ({
                          value: status,
                          label: status
                        }))}
                      size={"small"}
                      mode="single"
                    />
                  )}
                />
              </FormField>
            </Grid.Cell>
          </>
        )}

        {props.searchFor === "Movements" && (
          <>
            <Grid.Cell centerContent={true}>
              <Label style={labelStyle}>{"Counterparty"}</Label>
            </Grid.Cell>
            <Grid.Cell centerContent={true}>
              <FormField size="small" style={{ marginBottom: "4px" }}>
                <Controller
                  control={control}
                  name="counterParties"
                  render={({ field }) => (
                    <Select
                      value={field.value?.map(v => v.id) ?? []}
                      onChange={(_, selOpts) => field.onChange(selOpts)}
                      onClear={() => field.onChange([])}
                      size="small"
                      fieldNames={{ label: "name", value: "id" }}
                      optionLabelProp="name"
                      optionFilterProp="name"
                      //@ts-ignore
                      options={cpSearchResults}
                      loading={cpLoading}
                      mode="multiple"
                      showSearch={true}
                      onSearch={e => debounceSearch(searchCounterparties)(e)}
                    />
                  )}
                />
              </FormField>
            </Grid.Cell>
          </>
        )}

        <Grid.Cell centerContent={true}>
          <Label style={labelStyle}>{"Carrier"}</Label>
        </Grid.Cell>
        <Grid.Cell centerContent={true}>
          <FormField size="small" style={{ marginBottom: "4px" }}>
            <Controller
              control={control}
              name="carriers"
              render={({ field }) => (
                <Select
                  value={field.value?.map(v => v.id) ?? []}
                  onChange={(_, selOpts) => field.onChange(selOpts)}
                  onClear={() => field.onChange([])}
                  size="small"
                  fieldNames={{ label: "name", value: "id" }}
                  optionLabelProp="name"
                  optionFilterProp="name"
                  //@ts-ignore
                  options={carrierSearchResults}
                  loading={cLoading}
                  mode="multiple"
                  showSearch={true}
                  onSearch={e => debounceSearch(searchCarriers)(e)}
                />
              )}
            />
          </FormField>
        </Grid.Cell>

        {props.searchFor === "AvailableTickets" && (
          <>
            <Grid.Cell centerContent={true}>
              <Label style={labelStyle}>Ship From</Label>
            </Grid.Cell>
            <Grid.Cell centerContent={true}>
              <FormField size="small" style={{ marginBottom: "4px" }}>
                <Controller
                  control={control}
                  name="shipFromCodes"
                  render={({ field }) => (
                    <Select
                      value={field.value?.map(v => v.code) ?? []}
                      onChange={(_, selOpts) => field.onChange(selOpts)}
                      onClear={() => field.onChange([])}
                      size="small"
                      fieldNames={{ label: "code", value: "code" }}
                      optionLabelProp="code"
                      optionFilterProp="code"
                      //@ts-ignore
                      options={shipFromCodes}
                      loading={sfLoading}
                      mode="multiple"
                      onSearch={e => debounceSearch(lookupShipFromCodes)(e)}
                    />
                  )}
                />
              </FormField>
            </Grid.Cell>

            <Grid.Cell centerContent={true}>
              <Label style={labelStyle}>Ship To</Label>
            </Grid.Cell>
            <Grid.Cell centerContent={true}>
              <FormField size="small" style={{ marginBottom: "4px" }}>
                <Controller
                  control={control}
                  name="shipToCodes"
                  render={({ field }) => (
                    <Select
                      value={field.value?.map(v => v.code) ?? []}
                      onChange={(_, selOpts) => field.onChange(selOpts)}
                      onClear={() => field.onChange([])}
                      size="small"
                      fieldNames={{ label: "code", value: "code" }}
                      optionLabelProp="code"
                      optionFilterProp="code"
                      //@ts-ignore
                      options={shipToCodes}
                      loading={stoLoading}
                      mode="multiple"
                      onSearch={e => debounceSearch(lookupShipToCodes)(e)}
                    />
                  )}
                />
              </FormField>
            </Grid.Cell>

            <Grid.Cell centerContent={true}>
              <Label style={labelStyle}>Include Nulls</Label>
            </Grid.Cell>
            <Grid.Cell centerContent={true}>
              <FormField size="small" style={{ marginBottom: "4px" }}>
                <Controller
                  control={control}
                  name="includeNulls"
                  render={({ field }) => (
                    <Flexbox gap="0.5rem">
                      <Text size="small">No</Text>
                      <Toggle onChange={field.onChange} size="small" />
                      <Text size="small">Yes</Text>
                    </Flexbox>
                  )}
                />
              </FormField>
            </Grid.Cell>
          </>
        )}

        {props.searchFor === "Movements" && (
          <>
            <Grid.Cell centerContent={true}>
              <Label style={labelStyle}>Ext Src Id</Label>
            </Grid.Cell>
            <Grid.Cell centerContent={true}>
              <FormField size="small" style={{ marginBottom: "4px" }}>
                <Controller
                  control={control}
                  name="deliveryIds"
                  render={({ field }) => (
                    <TextInput
                      value={field.value ?? ""}
                      size="small"
                      placeholder="Comma Seperated"
                      onChange={field.onChange}
                      onClear={() => field.onChange("")}
                    />
                  )}
                />
              </FormField>
            </Grid.Cell>
          </>
        )}
        {props.searchFor === "AvailableTickets" && (
          <>
            <Grid.Cell centerContent={true}>
              <Label style={labelStyle}>Ticket #</Label>
            </Grid.Cell>
            <Grid.Cell centerContent={true}>
              <FormField size="small" style={{ marginBottom: "4px" }}>
                <Controller
                  control={control}
                  name="ticketNumbers"
                  render={({ field }) => (
                    <TextInput
                      value={field.value ?? ""}
                      size="small"
                      placeholder="Comma Seperated"
                      onChange={field.onChange}
                      onClear={() => field.onChange("")}
                    />
                  )}
                />
              </FormField>
            </Grid.Cell>
          </>
        )}

        <Grid.Cell centerContent={true}>
          <Label style={labelStyle}>Logistics</Label>
        </Grid.Cell>
        <Grid.Cell centerContent={true}>
          <FormField size="small" style={{ marginBottom: "4px" }}>
            <Controller
              control={control}
              name="logisticsSystems"
              render={({ field }) => (
                <Select
                  value={field.value?.map(v => v.id) ?? []}
                  onChange={(_, selOpts) => field.onChange(selOpts)}
                  onClear={() => field.onChange([])}
                  size="small"
                  fieldNames={{ label: "name", value: "id" }}
                  optionLabelProp="name"
                  optionFilterProp="name"
                  //@ts-ignore
                  options={picklists?.logisticsSystems}
                  mode="multiple"
                />
              )}
            />
          </FormField>
        </Grid.Cell>
        <Grid.Cell centerContent={true}>
          <Label style={labelStyle}>Railcar #</Label>
        </Grid.Cell>
        <Grid.Cell centerContent={true}>
          <FormField size="small" style={{ marginBottom: "4px" }}>
            <Controller
              control={control}
              name="railcarNumbers"
              render={({ field }) => (
                <TextInput
                  value={field.value ?? ""}
                  size="small"
                  placeholder="Comma Seperated"
                  onChange={field.onChange}
                  onClear={() => field.onChange("")}
                />
              )}
            />
          </FormField>
        </Grid.Cell>
        {props.searchFor === "AvailableTickets" && (
          <>
            <Grid.Cell centerContent={true}>
              <Label style={labelStyle}> Source</Label>
            </Grid.Cell>
            <Grid.Cell centerContent={true}>
              <FormField size="small" style={{ marginBottom: "4px" }}>
                <Controller
                  control={control}
                  name="ticketSource"
                  render={({ field }) => (
                    <Select
                      value={field.value}
                      onChange={field.onChange}
                      onClear={() => field.onChange([])}
                      options={Object.values(TicketSource)
                        .filter(v => isNaN(Number(v)))
                        .map((source: string) => ({
                          value: source,
                          label: source
                        }))}
                      size={"small"}
                      mode="multiple"
                    />
                  )}
                />
              </FormField>
            </Grid.Cell>
          </>
        )}

        {props.searchFor === "Movements" && (
          <>
            <Grid.Cell centerContent={true}>
              <Label style={labelStyle}>Legal Entity</Label>
            </Grid.Cell>
            <Grid.Cell centerContent={true}>
              <FormField size="small" style={{ marginBottom: "4px" }}>
                <Controller
                  control={control}
                  name="legalEntities"
                  render={({ field }) => (
                    <Select
                      value={field.value?.map(v => v.id) ?? []}
                      onChange={(_, selOpts) => field.onChange(selOpts)}
                      onClear={() => field.onChange([])}
                      size="small"
                      fieldNames={{ label: "name", value: "id" }}
                      optionLabelProp="name"
                      optionFilterProp="name"
                      //@ts-ignore
                      options={picklists?.legalEntities}
                      mode="multiple"
                      showSearch={true}
                    />
                  )}
                />
              </FormField>
            </Grid.Cell>
          </>
        )}

        <Grid.Cell centerContent={true}>
          <Label style={labelStyle}>MoT</Label>
        </Grid.Cell>
        <Grid.Cell centerContent={true}>
          <FormField size="small" style={{ marginBottom: "4px" }}>
            <Controller
              control={control}
              name="modeOfTransports"
              render={({ field }) => (
                <Select
                  value={field.value?.map(v => v.id) ?? []}
                  onChange={(_, selOpts) => field.onChange(selOpts)}
                  onClear={() => field.onChange([])}
                  size="small"
                  fieldNames={{ label: "name", value: "id" }}
                  optionLabelProp="name"
                  optionFilterProp="name"
                  //@ts-ignore
                  options={picklists?.modeOfTransports}
                  mode="multiple"
                />
              )}
            />
          </FormField>
        </Grid.Cell>

        <Grid.Cell centerContent={true}>&nbsp;</Grid.Cell>
        <Grid.Cell centerContent={true}>
          <FormField size="small" style={{ width: "100%", marginBottom: "4px" }}>
            <Flexbox justifyContent="center" alignItems="center" gap="0.5rem">
              <Button size="small" style={{ marginTop: "5px", flex: 1 }} type={"submit"}>
                Search
              </Button>
              <Button size="small" style={{ marginTop: "5px", flex: 1 }} onClick={resetForm}>
                Reset
              </Button>
            </Flexbox>
          </FormField>
        </Grid.Cell>
      </Grid>
    </form>
  );
};

/* CSS moved to a separate file */
