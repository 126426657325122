import { useMutation } from "@apollo/client";
import { toString as toKString } from "@progress/kendo-intl";
import { Button } from "@progress/kendo-react-buttons";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { Menu, Popover, Button as SDSButton, Variants } from "@sede-x/shell-ds-react-framework";
import {
  ArrowCwCircle,
  EllipsisVertical,
  SwapHorizontalCricle
} from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";
import { loader } from "graphql.macro";
import { MenuInfo, MenuItemType } from "rc-menu/lib/interface";
import { useContext, useState } from "react";
import { ApolloErrorViewer } from "shared/components/ApolloErrorViewer";
import InlineLoadingPanel from "shared/components/InlineLoadingPanel";
import VolumeAllocationModal from "ticketing/components/movements/VolumeAllocationModal";
import { validateMovementGroup } from "ticketing/movement-validations";
import {
  GqlResponse,
  TAllocationMovement,
  TMovement,
  TMovementGroup
} from "ticketing/ticketing.types";
import {
  TEMPERATURE_MEASURE_NAME,
  buildMovementAllocationsPayload,
  equalsIgnoreCase,
  findPrimaryMovement,
  isActualizedByInfoSet,
  isAutoActualizeInfoSet,
  isGainLoss,
  isMarineMOT,
  isPipeLineMOT as isPipelineMOT,
  isPlannedMovement,
  isValidMOT,
  mapTempUOM,
  truncateTo
} from "ticketing/utils";
import MovementAspectContainer from "./MovementAspectContainer";
import RevisionsMovement from "./RevisionsMovement";
import { MovementsMainContext } from "./utils";

const UPDATE_MOVEMENTS = loader("../../ticketing-graphql/updateMovements.graphql");
const REVALIDATE_MOVEMENTS = loader("../../ticketing-graphql/revalidateMovements.graphql");
const ALLOCATE_MOVEMENTS = loader("../../ticketing-graphql/allocateMovementVolume.graphql");
const EMISSION_CREDITS = "Emission Credits";

const isPastDate = (startDate?: string | Date) => {
  if (startDate) {
    if (startDate instanceof Date) {
      return startDate < new Date();
    }
    if (typeof startDate === "string") {
      return new Date(startDate) < new Date();
    }
  }
  return true;
};

type AllocateVolumeResponse = GqlResponse<TMovement[], "allocateMovementVolume">;
type UpdateMovementsResponse = GqlResponse<TMovement[], "updateMovements">;

export const MovementGroupHeader = ({
  movementGroup,
  activeMovementGroup,
  showOrHideAll
}: {
  movementGroup: TMovementGroup;
  activeMovementGroup: TMovementGroup | null;
  showOrHideAll: (showAll: boolean) => void;
}) => {
  const { onMovementsUpdated, onActiveMovementGroupChanged } = useContext(MovementsMainContext);
  const [showDeliveryEvents, setShowDeliveryEvents] = useState(false);
  const [showNomRevisions, setShowNomRevisions] = useState(false);
  const [showVolumeAllocationModal, setShowVolumeAllocationModal] = useState(false);
  const [quantityToAllocate, setQuantityToAllocate] = useState(0);
  const [showMenu, setShowMenu] = useState(false);

  const [updateMovements, { loading, error }] =
    useMutation<UpdateMovementsResponse>(UPDATE_MOVEMENTS);

  const [revalidateMovements, { loading: revalidateLoading, error: revalidateError }] =
    useMutation<UpdateMovementsResponse>(REVALIDATE_MOVEMENTS);

  const [allocateMovementVolume, { loading: allocateLoading, error: allocateError }] =
    useMutation<AllocateVolumeResponse>(ALLOCATE_MOVEMENTS);

  if (!movementGroup?.movements?.[0]) {
    return <div>No Movements to display</div>;
  }

  const toggleReadyForInvoice = () => {
    updateMovements({
      variables: {
        movements: movementGroup.movements.map(m => ({
          id: m.id,
          version: m.version,
          readyToInvoice: !m.readyToInvoice
        }))
      },
      onCompleted: data => onMovementsUpdated?.(data.updateMovements)
    });
  };

  const toggleActualizationComplete = (movement: TMovement) => {
    if (!shouldAllocateOnToggleActualizaton(movement)) {
      updateMovements({
        variables: {
          movements: movementGroup.movements.map(m => ({
            id: m.id,
            version: m.version,
            actualizationComplete: !m.actualizationComplete
          }))
        },
        onCompleted: data => onMovementsUpdated?.(data.updateMovements)
      });
    }
  };

  const shouldAllocateOnToggleActualizaton = (movement: TMovement) => {
    if (isValidMOT(movement.batch.modeOfTransport.name)) {
      const remainingQuantity = truncateTo(
        movement.scheduledQuantity - (movement.actualizedQuantity ?? 0),
        4
      );
      //false --> true
      if (!actualizationComplete && remainingQuantity !== 0) {
        setQuantityToAllocate(remainingQuantity);
        setShowVolumeAllocationModal(true);
        return true;
      }
      //true --> false
      if (actualizationComplete && remainingQuantity > 0) {
        setQuantityToAllocate(-1 * remainingQuantity);
        setShowVolumeAllocationModal(true);
        return true;
      }
    }
    return false;
  };

  const onCancelAllocationModal = () => {
    setShowVolumeAllocationModal(false);
  };

  const onAllocationSave = (allocationMovements: TAllocationMovement[] | null) => {
    setShowVolumeAllocationModal(false);

    updateMovements({
      variables: {
        movements: movementGroup.movements.map(m => ({
          id: m.id,
          version: m.version,
          actualizationComplete: !m.actualizationComplete
        }))
      },
      onCompleted: data => {
        saveAllocations(allocationMovements, alcMovements => {
          onMovementsUpdated?.([...data.updateMovements, ...(alcMovements ?? [])]);
        });
      }
    });
  };

  const saveAllocations = (
    allocationMovements: TAllocationMovement[] | null,
    onSaveCompleted: (movements?: TMovement[]) => void
  ) => {
    if (activeMovementGroup) {
      const allocateVolumeInput = buildMovementAllocationsPayload(
        activeMovementGroup?.activeMovement,
        allocationMovements,
        "Actualization complete toggled"
      );

      allocateMovementVolume({
        ...allocateVolumeInput,
        onCompleted: data => onSaveCompleted(data.allocateMovementVolume)
      });
    }
    onSaveCompleted();
  };

  const isTransitComplete = movementGroup.activeMovement.batch.transitComplete ?? false;
  const isAutoActualized = isAutoActualizeInfoSet(movementGroup) ?? false;
  const hasActualizedBy = isActualizedByInfoSet(movementGroup) ?? false;
  const hasTickets = movementGroup.activeMovement.tickets.length > 0;
  const modeOfTransport = movementGroup.activeMovement.batch.modeOfTransport.name;

  const isGainLossNom =
    movementGroup?.activeMovement?.isActualizedExternally && //2575178
    isGainLoss(movementGroup);

  // if negative value show 0
  const scheduledQuantity = Math.max(
    0,
    (isGainLossNom
      ? movementGroup?.activeMovement?.netQuantity
      : movementGroup?.activeMovement?.scheduledQuantity) ?? 0
  );

  const actualizedQuantity = movementGroup.activeMovement.actualizedQuantity ?? 0;

  const remainingQuantityMax = Math.max(0, scheduledQuantity - actualizedQuantity);

  const isEmissionCredits = movementGroup.movements?.some(m =>
    m.logisticsSystem?.name?.includes(EMISSION_CREDITS)
  );

  const temperatureMeasure = movementGroup.movements
    .flatMap(m => m.measures)
    .find(measure => equalsIgnoreCase(measure.measurementType.name, TEMPERATURE_MEASURE_NAME));

  const temperatureData = `${temperatureMeasure?.value ?? "?"} ${mapTempUOM(
    temperatureMeasure?.unitOfMeasure?.name ?? "?"
  )?.charAt(0)}`;
  const readyToInvoice = movementGroup.movements.some(md => md.readyToInvoice);
  const isActualizedExternally = movementGroup.movements.some(md => md.isActualizedExternally);
  const actualizationComplete = movementGroup.movements.some(md => md.actualizationComplete);

  //2127575
  const isMarineOrPipelineMOT = isMarineMOT(modeOfTransport) || isPipelineMOT(modeOfTransport);

  const hideReadyToInvoice = [
    isEmissionCredits,
    !isValidMOT(modeOfTransport),
    isTransitComplete,
    isAutoActualized,
    hasActualizedBy,
    isMarineOrPipelineMOT
  ].some(r => r);

  const disableActualization = [
    isPlannedMovement(movementGroup),
    isTransitComplete,
    isAutoActualized,
    hasActualizedBy,
    isActualizedExternally,
    isMarineOrPipelineMOT
  ].some(r => r);

  const disableReadyToInvoice = disableActualization || actualizationComplete || !hasTickets;

  const disableEditAspects =
    isPlannedMovement(movementGroup) ||
    isTransitComplete ||
    isAutoActualized ||
    hasActualizedBy;

  const readyToInvoiceThemeColor = isPastDate(movementGroup.activeMovement.startDate)
    ? "primary"
    : "warning";

  const movementValidations = validateMovementGroup(movementGroup);

  const MovementGroupMenu = (
    <Menu
      items={[
        {
          label: "Revalidate Noms",
          key: "Revalidate Noms",
          itemIcon: <ArrowCwCircle />,
          disabled: isTransitComplete,
          onClick: (e: MenuInfo) => {
            revalidateMovements({
              variables: {
                movements: movementGroup.movements.map(m => m.id)
              }
            });
            setShowMenu(false);
            e.domEvent.stopPropagation();
          }
        },
        {
          label: "Delivery Events",
          key: "Delivery Events",
          itemIcon: <SwapHorizontalCricle />,
          onClick: (e: MenuInfo) => {
            setShowDeliveryEvents(true);
            setShowMenu(false);
            e.domEvent.stopPropagation();
          }
        }
      ].filter((i: MenuItemType) => !i.disabled)}
    />
  );
  return (
    <>
      {showNomRevisions && (
        <RevisionsMovement
          onClose={() => setShowNomRevisions(false)}
          enterpriseSystemCode={movementGroup.activeMovement.enterpriseSystemCode}
          movementId={movementGroup.activeMovement.id}></RevisionsMovement>
      )}
      {showDeliveryEvents && (
        <MovementAspectContainer
          movementGroup={movementGroup}
          onClose={() => setShowDeliveryEvents(false)}
          editDisabled={disableEditAspects}
        />
      )}
      <div
        className="movement-group-header-container"
        role="none"
        onClick={e => {
          if (activeMovementGroup !== movementGroup) {
            onActiveMovementGroupChanged?.({
              ...movementGroup
            });
          }
          e.stopPropagation();
        }}>
        <div style={{ display: "flex" }}>
          {movementGroup.movements.map(movement => {
            return (
              <div className="delivery-id-label-container" key={`${movement.id}`}>
                <span
                  role="none"
                  onClick={e => {
                    if (activeMovementGroup?.activeMovement !== movement) {
                      onActiveMovementGroupChanged?.({
                        ...movementGroup,
                        activeMovement: movement
                      });
                    }
                    e.stopPropagation();
                  }}
                  className={
                    activeMovementGroup?.activeMovement.id === movement.id
                      ? "active-movement k-cursor-pointer"
                      : "k-cursor-pointer"
                  }
                  style={{ borderRadius: "0", userSelect: "none" }}>
                  {`${movement.enterpriseSystemCode} ${movement.internalLegalEntity?.name}`}
                </span>
                <Button
                  fillMode={"flat"}
                  icon="copy"
                  className="copy-btn"
                  onClick={e => {
                    navigator.clipboard.writeText(movement.enterpriseSystemCode);
                    e.stopPropagation();
                  }}
                />
              </div>
            );
          })}

          {movementGroup.movements.length > 1 && (
            <div style={{ display: "flex" }}>
              <Checkbox onChange={e => showOrHideAll(e.value)} label={"Show All"} />
            </div>
          )}
          {loading && <InlineLoadingPanel />}
        </div>

        <div className="movement-group-label-container">
          <Label>
            <span className="k-white-space-pre">{"Scheduled: "}</span>
            <span style={{ color: "var(--primary-icon-color)" }}>
              {toKString(scheduledQuantity, "0.0###")}
            </span>
          </Label>

          <Label>
            <span className="k-white-space-pre">{"Actualized: "}</span>
            <span style={{ color: "limegreen" }}>
              {toKString(actualizedQuantity, "0.0###")}
            </span>
          </Label>

          {/* 1477660 - if actualization complete is true hide the remaining quantity */}
          {!movementGroup?.activeMovement?.actualizationComplete && (
            <Label>
              <span className="k-white-space-pre">{"Remaining: "}</span>
              <span style={{ color: "orangered" }}>
                {toKString(remainingQuantityMax, "0.0###")}
              </span>
            </Label>
          )}
          <Label>{movementGroup.activeMovement.unitOfMeasure?.name}</Label>
          <Label>{temperatureData}</Label>
          <Label style={{ textDecoration: "underline", textTransform: "uppercase" }}>
            {movementGroup.activeMovement.status?.name}
          </Label>

          {movementValidations && movementValidations.length > 0 && (
            <span
              className="k-icon k-i-warning-triangle tooltip"
              style={{
                color: "var(--primary-icon-color)",
                fontSize: "0.875rem"
              }}>
              {
                <span className="tooltiptext">
                  <ul>
                    {movementValidations?.map(r => {
                      return (
                        <li key={r.message}>
                          <span style={{ color: `${r.error ? "red" : "black"}` }}>
                            {r.message}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                  ;
                </span>
              }
            </span>
          )}
        </div>

        <div style={{ display: "flex", flexWrap: "nowrap" }}>
          {(allocateLoading || revalidateLoading) && <InlineLoadingPanel />}
          <Button
            fillMode={"flat"}
            icon="checkbox-checked"
            title={`Actualization Complete: ${actualizationComplete ? "Yes" : "No"}`}
            hidden={disableActualization}
            themeColor={actualizationComplete ? "light" : "primary"}
            onClick={() => toggleActualizationComplete(movementGroup.activeMovement)}></Button>
          <Button
            fillMode={"flat"}
            title={`Ready to Invoice:${actualizationComplete || readyToInvoice ? "Yes" : "No"}`}
            disabled={disableReadyToInvoice}
            hidden={hideReadyToInvoice}
            icon={"track-changes-enable"}
            themeColor={readyToInvoice ? "light" : readyToInvoiceThemeColor}
            onClick={() => toggleReadyForInvoice()}></Button>
          <Button
            title="Movement Revisions"
            fillMode={"flat"}
            icon="clock"
            style={{ color: "var(--primary-icon-color)" }}
            onClick={() => setShowNomRevisions(true)}></Button>
          {/* PBI 2157998  */}
          <Popover
            popup={MovementGroupMenu}
            popupVisible={showMenu}
            onPopupVisibleChange={setShowMenu}
            arrow
            popupPlacement="bottomLeft">
            <SDSButton
              variant={Variants.Transparent}
              iconOnly
              onClick={e => e.stopPropagation()}
              icon={<EllipsisVertical />}></SDSButton>
          </Popover>
        </div>
      </div>
      {error && <ApolloErrorViewer error={error} />}
      {allocateError && <ApolloErrorViewer error={allocateError} />}
      {revalidateError && <ApolloErrorViewer error={revalidateError} />}
      {showVolumeAllocationModal && movementGroup.activeMovement && (
        <VolumeAllocationModal
          quantityToAllocate={quantityToAllocate}
          onCancel={onCancelAllocationModal}
          onSave={onAllocationSave}
          sourceMovement={findPrimaryMovement(movementGroup)}></VolumeAllocationModal>
      )}
    </>
  );
};
