import { ApolloError, useMutation } from "@apollo/client";
import {
  Drawer,
  FormField,
  Heading,
  Sentiments,
  Sizes,
  TextInput,
  TextTypes,
  Variants
} from "@sede-x/shell-ds-react-framework";
import { TTerminalsFilter } from "carbonIQ/carbonIQtypes";
import { loader } from "graphql.macro";
import { useState } from "react";
import { ApolloErrorViewer } from "shared/components/ApolloErrorViewer";
import LoadingPanel from "shared/components/LoadingPanel";

const ciAddNewTerminal = loader("../graphql/mutation-CarbonAddNewTerminal.graphql");

const ciUpdateTerminal = loader("../graphql/mutation-CarbonUpdateTerminal.graphql");

const AddOrUpdateTerminal = ({
  details,
  onClose,
  onSubmit
}: {
  details: TTerminalsFilter | null;
  onClose: () => void;
  onSubmit: () => void;
}) => {
  // Add New Terminal
  const [addNewTerminal, { loading: addTerminalLoading, error: addTerminalError }] =
    useMutation(ciAddNewTerminal);

  // Update New Terminal
  const [updateTerminal, { loading: updateTerminalLoading, error: updateTerminalError }] =
    useMutation(ciUpdateTerminal);

  const isStateAdd = !details?.id;

  const [preFillTerminalDetails, setPreFillTerminalDetails] = useState<TTerminalsFilter | null>(
    isStateAdd ? null : details
  );

  const isLoading = [updateTerminalLoading, addTerminalLoading].some(elm => elm);

  const onSubmitClick = () => {
    const tempdata = {
      displayName: preFillTerminalDetails?.displayName?.trim(),
      gsapCode: preFillTerminalDetails?.gsapCode?.trim(),
      gsapName: preFillTerminalDetails?.gsapName?.trim()
    };
    if (isStateAdd) {
      addNewTerminal({
        variables: {
          terminal: tempdata
        },
        onCompleted: onSubmit
      });
    } else {
      updateTerminal({
        variables: {
          terminal: {
            ...tempdata,
            id: preFillTerminalDetails?.id,
            version: preFillTerminalDetails?.version
          }
        },
        onCompleted: onSubmit
      });
    }
  };

  const isError = [addTerminalError, updateTerminalError].some(e => e);
  const errors = [addTerminalError, updateTerminalError].filter((e): e is ApolloError =>
    Boolean(e)
  );
  return (
    <Drawer
      header={
        <Heading className="setting-form-heading" type={TextTypes.H2}>
          {(isStateAdd ? "Add New" : "Update") + " Terminals"}
        </Heading>
      }
      borders={false}
      closeButton={false}
      sticky
      mask={true}
      open={true}
      maskClosable={false}
      size={Sizes.Medium}
      onClose={onClose}
      actions={[
        {
          label: "Cancel",
          action: onClose,
          props: {
            variant: Variants.Outlined
          }
        },
        {
          label: "Save",
          action: onSubmitClick,
          props: {
            disabled:
              !preFillTerminalDetails?.displayName?.trim() ||
              !preFillTerminalDetails?.gsapCode?.trim() ||
              !preFillTerminalDetails?.gsapName?.trim()
          }
        }
      ]}>
      {isLoading && <LoadingPanel />}

      {isError && <ApolloErrorViewer error={errors} />}

      {!isStateAdd && <p>Update Terminal</p>}

      <form>
        <FormField
          size={"medium"}
          id="gsap-code-label"
          mandatory={true}
          label="Terminal Code"
          bottomLeftHelper={{
            content: !preFillTerminalDetails?.gsapCode ? <b>Field can't be blank.</b> : "",
            sentiment: Sentiments.Negative
          }}>
          <TextInput
            onChange={event => {
              const tempObj = Object.create(preFillTerminalDetails);
              tempObj.gsapCode = event.target.value.trim();
              setPreFillTerminalDetails(tempObj);
            }}
            value={preFillTerminalDetails?.gsapCode ?? ""}
            invalid={!preFillTerminalDetails?.gsapCode}
          />
        </FormField>
        <FormField
          size={"medium"}
          id="gsap-name-label"
          label="Terminal Name"
          mandatory={true}
          bottomLeftHelper={{
            content: !preFillTerminalDetails?.gsapName ? <b>Field can't be blank.</b> : "",
            sentiment: Sentiments.Negative
          }}>
          <TextInput
            onChange={event => {
              const tempObj = Object.create(preFillTerminalDetails);
              tempObj.gsapName = event.target.value;
              setPreFillTerminalDetails(tempObj);
            }}
            value={preFillTerminalDetails?.gsapName ?? ""}
            invalid={!preFillTerminalDetails?.gsapName}
          />
        </FormField>

        <FormField
          size={"medium"}
          id="display-name-label"
          label="Display Name"
          mandatory={true}
          bottomLeftHelper={{
            content: !preFillTerminalDetails?.displayName ? <b>Field can't be blank.</b> : "",
            sentiment: Sentiments.Negative
          }}>
          <TextInput
            onChange={event => {
              const tempObj = Object.create(preFillTerminalDetails);
              tempObj.displayName = event.target.value;
              setPreFillTerminalDetails(tempObj);
            }}
            value={preFillTerminalDetails?.displayName ?? ""}
            invalid={!preFillTerminalDetails?.displayName}
          />
        </FormField>
      </form>
    </Drawer>
  );
};

export default AddOrUpdateTerminal;
