import { User, WebStorageStateStore } from "oidc-client-ts";
import { AuthProviderProps } from "react-oidc-context";

declare global {
  interface Window {
    _env_: Record<string, string>;
  }
}

const SSO_URI =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_SSO_URI
    : window._env_.REACT_APP_SSO_URI;
const SSO_CLIENT_ID =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_SSO_CLIENT_ID
    : window._env_.REACT_APP_SSO_CLIENT_ID;
const FRONTEND_URI =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_FRONTEND_URI
    : window._env_.REACT_APP_FRONTEND_URI;

export const onLogin = (_user: User | void) => {
  window.history.replaceState({}, document.title, window.location.pathname);
};

export const oidcConfig: AuthProviderProps = {
  authority: SSO_URI,
  client_id: SSO_CLIENT_ID,
  redirect_uri: FRONTEND_URI,
  automaticSilentRenew: true,
  onSigninCallback: onLogin,
  userStore: new WebStorageStateStore({ store: window.localStorage })
};

export const getAuthHeader = (): string | null => {
  const oidcStorage = localStorage.getItem(`oidc.user:${SSO_URI}:${SSO_CLIENT_ID}`);
  if (!oidcStorage) {
    return null;
  }
  return `Bearer ${User.fromStorageString(oidcStorage)?.access_token}`;
};

export interface IMACkPrivilege {
  privilegeId: number;
  name: string;
  group: string;
  serviceName: string;
}

export interface IMACkRole {
  id: number;
  version: number;
  name: string;
  description?: string;
  active: boolean;
  users?: IMACkUser[] | null;
  privileges?: IMACkPrivilege[];
}
export interface IMACkRoleView {
  id: number;
  name: string;
}

export interface IMACkUser {
  id: string;
  version: number;
  firstName: string;
  lastName: string;
  middleName?: string;
  mobile?: string;
  email: string;
  status: string;
  defaultLegalEntityId?: string | null;
  registeredAt?: string;
  lastSignInAt?: string;
  roles?: IMACkRole[];
}

export enum MackPrivilege {
  Login = 1000,

  ViewRoles = 1100,
  AddRoles = 1101,
  ModifyRoles = 1102,

  ViewUsers = 1110,
  AddUsers = 1111,
  ModifyUsers = 1112,

  ViewSuppliers = 1200,
  AddSuppliers = 1201,
  ModifySuppliers = 1202,

  ViewCustomers = 1300,
  AddCustomers = 1301,
  ModifyCustomers = 1302,

  ViewTerminals = 1400,
  AddTerminals = 1401,
  ModifyTerminals = 1402,

  ViewProducts = 1500,
  AddProducts = 1501,
  ModifyProducts = 1502,

  ViewModeOfTransports = 1600,
  AddModeOfTransports = 1601,
  ModifyModeOfTransports = 1602,

  ViewUnitOfMeasures = 1700,
  AddUnitOfMeasures = 1701,
  ModifyUnitOfMeasures = 1702,

  ViewForecastTypes = 1800,
  AddForecastTypes = 1801,
  ModifyForecastTypes = 1802,

  ViewForecast = 2000,
  AddForecast = 2001,
  ModifyForecast = 2002,

  ViewGSEAutoExportSettings = 2020,
  ModifyGSEAutoExportSettings = 2021,

  ViewCoreRefData = 9001,
  AddCoreRefData = 9002,
  ModifyCoreRefData = 9003,

  ViewMovements = 10001,
  AddMovements = 10002,
  ModifyMovements = 10003,

  ViewTickets = 20001,
  AddTickets = 20002,
  ModifyTickets = 20003,

  ViewCISavings = 30001,
  AddCISavings = 30002,
  ModifyCISavings = 30003,

  ViewCISavingsCertificate = 30004,
  SendCISavingsCertificate = 30005,

  ViewCICompany = 30011,
  AddCICompany = 30012,
  ModifyCICompany = 30013,

  ViewCICustomer = 30021,
  AddCICustomer = 30022,
  ModifyCICustomer = 30023,

  ViewCIComponent = 30031,
  AddCIComponent = 30032,
  ModifyCIComponent = 30033,

  ViewCIGenericProduct = 30034,
  AddCIGenericProduct = 30035,
  ModifyCIGenericProduct = 30036,

  ViewCIProduct = 30037,
  AddCIProduct = 30038,
  ModifyCIProduct = 30039,

  ViewCITerminal = 30041,
  AddCITerminal = 30042,
  ModifyCITerminal = 30043,

  ViewCIWeightedAverages = 30051,
  AddCIWeightedAverages = 30052,
  ModifyCIWeightedAverages = 30053,

  ResendMovements = 90001,
  ViewOutboudMovementUpdates = 90002,
  ViewOutboundBatchUpdates = 90003,
  ViewInboundCargoUpdates = 90100,
  ViewInboundContractUpdates = 90101,
  ViewInboundRefUpdates = 90102
}

export class MackUser {
  mackUser: IMACkUser;
  constructor(mackUser: IMACkUser) {
    this.mackUser = mackUser;
  }

  shortName = () => `${this.mackUser?.firstName.charAt(0)}${this.mackUser?.lastName.charAt(0)}`;

  displayName = () => `${this.mackUser?.firstName ?? ""} ${this.mackUser?.lastName ?? ""}`;

  hasCarbonIQPrivilege() {
    return this.hasAnyPrivilege([
      MackPrivilege.ViewCISavings,
      MackPrivilege.ViewCICompany,
      MackPrivilege.ViewCICustomer,
      MackPrivilege.ViewCIComponent,
      MackPrivilege.ViewCIGenericProduct,
      MackPrivilege.ViewCIProduct,
      MackPrivilege.ViewCITerminal,
      MackPrivilege.ViewCIWeightedAverages
    ]);
  }

  hasForcastPrivilege() {
    return this.hasAnyPrivilege([MackPrivilege.ViewForecast, MackPrivilege.ViewForecastTypes]);
  }

  hasMovementsOrTicketingPrivilege() {
    return this.hasAnyPrivilege([MackPrivilege.ViewMovements, MackPrivilege.ViewTickets]);
  }

  hasEndurConnectorPrivilege() {
    return this.hasAnyPrivilege([
      MackPrivilege.ViewOutboudMovementUpdates,
      MackPrivilege.ViewOutboundBatchUpdates,
      MackPrivilege.ViewInboundCargoUpdates,
      MackPrivilege.ViewInboundContractUpdates,
      MackPrivilege.ViewInboundRefUpdates
    ]);
  }

  hasPrivilege(privilge: MackPrivilege) {
    return !!this.mackUser?.roles
      ?.flatMap(r => r.privileges)
      ?.some(p => p && p.privilegeId === privilge);
  }

  hasAnyPrivilege(privileges: MackPrivilege[]) {
    return !!this.mackUser?.roles
      ?.flatMap(r => r.privileges?.map(p => p.privilegeId))
      ?.some(pId => pId && privileges.includes(pId));
  }

  hasAnyPrivilegeFromService(name: string) {
    return !!this.mackUser?.roles
      ?.flatMap(r => r.privileges)
      ?.some(p => p && p.serviceName.toUpperCase() === name.toUpperCase());
  }
}
