import { useMutation } from "@apollo/client";
import {
  Button,
  FormField,
  Modal,
  TextInput,
  Variants
} from "@sede-x/shell-ds-react-framework";
import { TSavingLetterFilterData } from "carbonIQ/carbonIQtypes";
import { loader } from "graphql.macro";
import { useState } from "react";
import { ApolloErrorViewer } from "shared/components/ApolloErrorViewer";
import LoadingPanel from "shared/components/LoadingPanel";

const resendCarbonSavingsDocumentEmail = loader("../graphql/sendEmail.graphql");

const SendEmail = ({
  onClose,
  userData
}: {
  onClose: () => void;
  userData: TSavingLetterFilterData;
}) => {
  const [reSendEmail, { loading: reSendEmailLoading, error: reSendEmailError }] = useMutation(
    resendCarbonSavingsDocumentEmail,
    {
      onCompleted: onClose
    }
  );

  const [toEmailList, setToEmailList] = useState([userData?.customer?.email ?? ""]);
  const [ccEmailList, setCcEmailList] = useState([""]);
  const isLoading = [reSendEmailLoading].some(elm => elm);

  return (
    <Modal
      title="Send Email"
      width={"700px"}
      height={"600px"}
      mask={true}
      maskClosable={false}
      open={true}
      onClose={onClose}>
      {isLoading && <LoadingPanel />}
      {reSendEmailError && <ApolloErrorViewer error={reSendEmailError} />}
      <br />
      <form
        onSubmit={event => {
          event.preventDefault();
          if (toEmailList.length || ccEmailList.length) {
            reSendEmail({
              variables: {
                documentId: userData.id,
                toEmailAddress: toEmailList,
                ccEmailAddress: ccEmailList
              }
            });
            onClose();
          }
        }}>
        <FormField size={"medium"} id="customer-name" label="To Email ID(s)">
          <TextInput
            size={"medium"}
            id="to-email"
            placeholder="Email ID"
            value={toEmailList[0]}
            onChange={event => setToEmailList([event.target.value.trim()])}
          />
        </FormField>

        <FormField size={"medium"} id="customer-name" label="CC Email ID(s)">
          <TextInput
            size={"medium"}
            id="cc-email"
            placeholder="Email ID"
            value={ccEmailList[0]}
            onChange={event => setCcEmailList([event.target.value.trim()])}
          />
        </FormField>
        <div className="from-button-wrap">
          <Button variant={Variants.Outlined} onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit">Send</Button>
        </div>
      </form>
    </Modal>
  );
};

export default SendEmail;
