import { IForecastState } from "../../_redux/IReduxState";
import * as enums from "../../shared/enums";
import store from "../../store";
import { IForecastBase, IForecastDailySummary } from "../models";
const conversionFactor = 42;

export const convertToGridData = (original: any) => {
  const forecast: IForecastState = store.getState().forecast;
  const uom = forecast.unitOfMeasure;

  const originalData = original?.data
    ? original.data.content
      ? original.data.content
      : original.data
    : [];

  return originalData?.map((item: Record<string, unknown>, index: number) => {
    item["Id"] = item.id ?? index; // this line is required since the summary data does not have a unique key
    convertItemUom(uom, item);
    return item;
  });
};

// new-fetched data is a flat array (grid data), default UoM is GAL
export const convertItemUom = (uom: string, item: Record<string, unknown>) => {
  if (uom === "BBL") {
    item[enums.FORECAST_APPROVED_VOLUME] = (
      +(item[enums.FORECAST_APPROVED_VOLUME] as number) / conversionFactor
    ).toString();
    item[enums.FORECAST_VOLUME] = (+(item[enums.FORECAST_VOLUME] as number) / conversionFactor).toString();
    item["UoM"] = uom;
  }
};

// used before updating data to DB, UoM in DB should be always GAL
export const convertVolumeToGAL = (item: IForecastBase | IForecastDailySummary) => {
  let volume: number = +item[enums.FORECAST_APPROVED_VOLUME];
  if (item["UoM"] === "BBL") {
    return volume * conversionFactor;
  }
  return volume;
};

export const convertDataUom = (uom: string) => {
  let forecast: IForecastState = store.getState().forecast;

  forecast.monthlySummary && recursiveUomConverter(uom, forecast.monthlySummary.data);
  forecast.dailySummary && recursiveUomConverter(uom, forecast.dailySummary.data);

  return forecast;
};

const recursiveUomConverter = (uom: string, data: any[]) => {
  let av = enums.FORECAST_APPROVED_VOLUME;
  let fv = enums.FORECAST_VOLUME;
  // debugger;
  data.forEach(item => {
    if (item.details) {
      recursiveUomConverter(uom, item.details.data);
    }
    if (item.base) {
      recursiveUomConverter(uom, item.base.data);
    }
    if (uom === "BBL") {
      item[av] = item[av] / conversionFactor;
      item[fv] = item[fv] / conversionFactor;
      if (item.old !== undefined && item.old !== null) {
        item.old[av] = item.old[av] / conversionFactor;
        item.old[fv] = item.old[fv] / conversionFactor;
      }
    } else {
      item[av] = item[av] * conversionFactor;
      item[fv] = item[fv] * conversionFactor;
      if (item.old !== undefined && item.old !== null) {
        item.old[av] = item.old[av] * conversionFactor;
        item.old[fv] = item.old[fv] * conversionFactor;
      }
    }
    item["unitOfMeasure"]["name"] = uom;
  });
};
