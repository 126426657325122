import { Dialog } from "@progress/kendo-react-dialogs";
import { ButtonLink, Flexbox, Label } from "@sede-x/shell-ds-react-framework";
import { Download } from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";
import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import type { DocumentCallback } from "react-pdf/dist/cjs/shared/types";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

const DocumentTitle = ({ file, allowDownload }: { file: File; allowDownload?: boolean }) => {
  if (allowDownload) {
    return (
      <Flexbox alignItems="center" justifyContent="space-between" style={{ width: "100%" }}>
        <Label type="span">{file.name}</Label>
        <ButtonLink
          icon={<Download />}
          iconOnly
          size="xsmall"
          href={window.URL.createObjectURL(file)}
          download={file.name}></ButtonLink>
      </Flexbox>
    );
  }
  return <Label type="span">{file.name}</Label>;
};

export const DocumentViewer = (props: {
  file: File;
  onClose: () => void;
  allowDownload?: boolean;
}) => {
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);

  function onDocumentLoadSuccess({ numPages: nextNumPages }: DocumentCallback): void {
    setNumPages(nextNumPages);
  }

  return (
    <Dialog
      id="pdfViewerDialog"
      style={{ padding: "4px 2px", color: "black" }}
      title={<DocumentTitle file={props.file} allowDownload={props.allowDownload} />}
      onClose={() => props.onClose()}>
      <Document file={props.file} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber || 1} width={600} scale={1} />
        <div className="page-controls">
          <button
            disabled={pageNumber <= 1}
            onClick={() => setPageNumber(pageNumber - 1)}
            type="button"
            style={{ cursor: "pointer" }}
            aria-label="Previous page">
            ‹
          </button>
          <span>
            {pageNumber} of {numPages}
          </span>
          <button
            disabled={pageNumber >= numPages}
            onClick={() => setPageNumber(pageNumber + 1)}
            type="button"
            style={{ cursor: "pointer" }}
            aria-label="Next page">
            ›
          </button>
        </div>
      </Document>
    </Dialog>
  );
};
