import { useQuery } from "@apollo/client";
import { Accordion, Modal, Sizes, Variants } from "@sede-x/shell-ds-react-framework";
import {
  TCISavingsCalcDetails,
  TCISavingsDetails,
  TCISavingsDetailsExtendedFormated,
  TSavingLetterFilterData
} from "carbonIQ/carbonIQtypes";
import { loader } from "graphql.macro";
import { useState } from "react";
import { ApolloErrorViewer } from "shared/components/ApolloErrorViewer";
import EmptyTable from "shared/components/basetable/EmptyTable";
import LoadingPanel from "shared/components/LoadingPanel";
import { GqlResponse } from "types";

const carbonSavingsDocuments = loader("../graphql/carbonSavingsFilterBy.graphql");

type TCarbonSavingsFilterBy = GqlResponse<TCISavingsDetails[], "carbonSavingsFilterBy">;

const ShowDetails = ({
  details,
  onClose
}: {
  details: TSavingLetterFilterData;
  onClose: () => void;
}) => {
  const [savingsDocumentData, setSavingsDocumentData] = useState<TCISavingsDetails[]>();
  const [savingsDocumentFormatedData, setSavingsDocumentFormatedData] =
    useState<TCISavingsDetailsExtendedFormated>();

  const totalSumData = {
    emissionUnblendedFuel: 0,
    emissionBlendedFuel: 0,
    emissionSaving: 0
  };

  //Fetching Filtered Carbon IQ document data
  const { loading: lCIQSearchDocument, error: eCIQSearchDocument } =
    useQuery<TCarbonSavingsFilterBy>(carbonSavingsDocuments, {
      variables: {
        filter: {
          customerId: {
            eq: details.customer.id
          },
          deliveryMonth: {
            eq: details.month
          }
        }
      },
      onCompleted: data => {
        setSavingsDocumentData(data.carbonSavingsFilterBy);
        if (data.carbonSavingsFilterBy?.length) {
          // taking out "productGsapName" & "terminalGsapName"
          const groupedData = Object.groupBy(
            data.carbonSavingsFilterBy.map(elm => ({
              ...elm,
              productGsapName: elm.calcDetails[0].productGsapName,
              terminalGsapName: elm.calcDetails[0].terminalGsapName
            })),
            ({ terminalGsapName }) => terminalGsapName
          );
          setSavingsDocumentFormatedData(groupedData);
        }
      }
    });

  const twoDecimal = (num: number) => +(Math.round(num * 100) / 100).toFixed(2);

  const isLoading = [lCIQSearchDocument].some(elm => elm);
  const isError = [eCIQSearchDocument].some(e => e);

  return (
    <Modal
      title="Terminal wise Details"
      width={"1000px"}
      height={"600px"}
      mask={true}
      open={true}
      maskClosable={false}
      contentScrollable
      onClose={onClose}
      actions={[
        {
          label: "Close",
          action: onClose,
          props: {
            variant: Variants.Outlined
          }
        }
      ]}>
      <>
        {isError && <ApolloErrorViewer error={eCIQSearchDocument} />}

        {isLoading && <LoadingPanel />}

        {savingsDocumentData && savingsDocumentData.length > 0 ? (
          <>
            {savingsDocumentFormatedData &&
              Object.keys(savingsDocumentFormatedData).map(
                (terminal: string, terminalIndex: number) => {
                  totalSumData.emissionUnblendedFuel = 0;
                  totalSumData.emissionBlendedFuel = 0;
                  totalSumData.emissionSaving = 0;
                  return (
                    <div key={terminal + "_wrapper"}>
                      <Accordion
                        size={Sizes.Medium}
                        defaultActiveKey={["1"]}
                        items={[
                          {
                            label: terminal,
                            key: terminalIndex + 1,
                            children: (
                              <table
                                border={1}
                                key={terminal + "_table"}
                                className="savings-details-table">
                                <tbody key={`${terminal}${terminalIndex}_tbody`}>
                                  <tr>
                                    <th>Product</th>
                                    <th>Volume (L)</th>
                                    <th>Component</th>
                                    <th>Component % </th>
                                    <th>Avg CI</th>
                                    <th>Energy Density</th>
                                    <th>Emissions unblended Fuel (MT)</th>
                                    <th>Emissions Blended Fuel (MT)</th>
                                    <th>Emissions Savings (MT)</th>
                                  </tr>
                                  {savingsDocumentFormatedData[terminal]?.map(
                                    (parentElm, parentElmIndex) => {
                                      totalSumData.emissionUnblendedFuel =
                                        totalSumData.emissionUnblendedFuel +
                                        twoDecimal(parentElm.emissionUnblendedFuel);
                                      totalSumData.emissionBlendedFuel =
                                        totalSumData.emissionBlendedFuel +
                                        twoDecimal(parentElm.emissionBlendedFuel);
                                      totalSumData.emissionSaving =
                                        totalSumData.emissionSaving +
                                        twoDecimal(parentElm.emissionSaving);

                                      const formatedCalcDetails = parentElm.calcDetails.filter(
                                        (elem: TCISavingsCalcDetails) =>
                                          elem.calcMode === "Blended Fuel"
                                      );
                                      const formatedCalcDetailsLen = formatedCalcDetails.length;
                                      return formatedCalcDetails.map(
                                        (
                                          elm: TCISavingsCalcDetails,
                                          calcDetailsIndex: number
                                        ) => {
                                          return calcDetailsIndex === 0 ? (
                                            <tr
                                              key={`${
                                                terminal +
                                                parentElm.productGsapName +
                                                calcDetailsIndex
                                              }_row`}
                                              className={
                                                parentElmIndex % 2 === 0
                                                  ? "product-row-highlight"
                                                  : ""
                                              }>
                                              <td rowSpan={formatedCalcDetailsLen}>
                                                {parentElm.productGsapName}
                                              </td>
                                              <td rowSpan={formatedCalcDetailsLen}>
                                                {parentElm.monthlyVolume}
                                              </td>
                                              <td rowSpan={1}>{elm.componentName}</td>
                                              <td rowSpan={1}>{elm.componentPercent}</td>
                                              <td rowSpan={1}>{elm.averageCI}</td>
                                              <td rowSpan={1}>{elm.energyDensity}</td>
                                              <td rowSpan={formatedCalcDetailsLen}>
                                                {twoDecimal(parentElm.emissionUnblendedFuel)}
                                              </td>
                                              <td rowSpan={formatedCalcDetailsLen}>
                                                {twoDecimal(parentElm.emissionBlendedFuel)}
                                              </td>
                                              <td rowSpan={formatedCalcDetailsLen}>
                                                {twoDecimal(parentElm.emissionSaving)}
                                              </td>
                                            </tr>
                                          ) : (
                                            <tr
                                              key={`${
                                                terminal +
                                                parentElm.productGsapName +
                                                calcDetailsIndex
                                              }_row`}
                                              className={
                                                parentElmIndex % 2 === 0
                                                  ? "product-row-highlight"
                                                  : ""
                                              }>
                                              <td rowSpan={1}>{elm.componentName}</td>
                                              <td rowSpan={1}>{elm.componentPercent}</td>
                                              <td rowSpan={1}>{elm.averageCI}</td>
                                              <td rowSpan={1}>{elm.energyDensity}</td>
                                            </tr>
                                          );
                                        }
                                      );
                                    }
                                  )}
                                  <tr key={`${terminal}${terminalIndex}_row_total`}>
                                    <td colSpan={6}>Total</td>
                                    <td rowSpan={1}>
                                      {twoDecimal(totalSumData.emissionUnblendedFuel)}
                                    </td>
                                    <td rowSpan={1}>
                                      {twoDecimal(totalSumData.emissionBlendedFuel)}
                                    </td>
                                    <td rowSpan={1}>
                                      {twoDecimal(totalSumData.emissionSaving)}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            )
                          }
                        ]}
                      />
                    </div>
                  );
                }
              )}
          </>
        ) : (
          <EmptyTable />
        )}
      </>
    </Modal>
  );
};

export default ShowDetails;
