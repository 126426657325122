import { useMutation, useQuery } from "@apollo/client";
import { getter } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridItemChangeEvent,
  GridNoRecords,
  GridToolbar
} from "@progress/kendo-react-grid";
import { NumericTextBoxProps, NumericTextBoxPropsContext } from "@progress/kendo-react-inputs";
import { loader } from "graphql.macro";
import { useCallback, useState } from "react";
import { ApolloErrorViewer } from "shared/components/ApolloErrorViewer";
import InlineLoadingPanel from "shared/components/InlineLoadingPanel";
import { usePicklists } from "ticketing/contexts/picklists/PicklistContextProvider";
import { GqlResponse, Picklists, TMovement, TMovementGroup } from "ticketing/ticketing.types";
import { equalsIgnoreCase } from "ticketing/utils";

type DropDownListCellProps = GridCellProps & {
  data?: Picklists.TUnitOfMeasure[] | Picklists.PMeasurementType[];
};

const DropDownListCell = (props: DropDownListCellProps) => {
  const { ariaColumnIndex, columnIndex, dataItem, field, data } = props;
  const isInEdit = dataItem.inEdit;
  const valueGetter = field && getter(field);
  const value = valueGetter ? valueGetter(dataItem) : "";

  const onChange = (e: DropDownListChangeEvent) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        value: e.value,
        syntheticEvent: e.syntheticEvent
      });
    }
  };

  return (
    <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex} role={"gridcell"}>
      {isInEdit ? (
        <div>
          <DropDownList data={data} defaultValue={value} onChange={onChange} textField="name" />
        </div>
      ) : (
        <span>{value.name}</span>
      )}
    </td>
  );
};

const getCell = (data?: Picklists.TUnitOfMeasure[] | Picklists.PMeasurementType[]) => {
  return (props: GridCellProps) => <DropDownListCell {...props} data={data} />;
};

const QUERY_MOVEMENT_MEASURES = loader("../../ticketing-graphql/measuresOfMovement.graphql");

const UPDATE_MOVEMENT_MEASURES = loader(
  "../../ticketing-graphql/updateMeasuresOfMovements.graphql"
);
type Response = GqlResponse<Partial<TMovement[]>, "movements">;

type TMovementMeasureId = {
  id?: string;
  version?: number;
  movementId?: string;
  movementVersion?: number;
};

type TMovementMeasureGroup = {
  id: string;
  measurementType?: Picklists.PMeasurementType;
  unitOfMeasure?: Picklists.TUnitOfMeasure;
  value?: number;
  sortId: number;
  movementMeasureId?: [TMovementMeasureId];
  inEdit: boolean;
};

type TMovementMeasureUpdate = {
  id: string;
  version: number;
  measurementTypeId: number;
  unitOfMeasureId: number;
  value: number;
};

type TMovementMeasureInputUpdate = {
  id: string;
  version: number;
  measures: TMovementMeasureUpdate[];
};

const transformInput = (movements: Partial<TMovement[]>): TMovementMeasureGroup[] => {
  //Get all measures from alll sides of the movement
  const measures = movements.flatMap(m =>
    // m &&
    m?.measures?.map(mm => ({
      ...mm,
      movementId: m.id,
      movementVersion: m.version
    }))
  );

  //group measurement groups by measurement type id
  const measureGroups = measures.reduce<Record<string, TMovementMeasureGroup>>(
    (group, item) => {
      const measurementTypeId: string = `Id:${item!.measurementType.id}`;
      if (group[measurementTypeId] == null) {
        group[measurementTypeId] = {
          id: measurementTypeId,
          measurementType: item?.measurementType,
          unitOfMeasure: item?.unitOfMeasure,
          value: item?.value,
          movementMeasureId: [
            {
              id: item?.id,
              version: item?.version,
              movementId: item?.movementId,
              movementVersion: item?.movementVersion
            }
          ],
          sortId: equalsIgnoreCase(item?.measurementType?.name, "Temperature") ? 0 : +item?.id!,
          inEdit: !equalsIgnoreCase(item?.measurementType?.name, "Temperature")
        };
        return group;
      }
      group[measurementTypeId].movementMeasureId?.push({
        id: item?.id,
        version: item?.version
      });

      return group;
    },
    {}
  );

  return Object.values(measureGroups).sort((g1, g2) => g1.sortId - g2.sortId);
};

const transformOutput = (movementMeasures: TMovementMeasureGroup[]) => {
  const movementMeasureUpdate = movementMeasures?.flatMap(m =>
    m.movementMeasureId?.map(mm => ({
      id: mm.movementId,
      version: mm.movementVersion,
      measures: {
        id: mm.id!,
        version: mm.version!,
        measurementTypeId: m.measurementType?.id!,
        unitOfMeasureId: m.unitOfMeasure?.id!,
        value: m.value!
      }
    }))
  );

  const movementMeasureUpdateGroup = movementMeasureUpdate?.reduce<
    Record<string, TMovementMeasureInputUpdate>
  >((movementIdGroup, item) => {
    if (movementIdGroup[item!.id!] == null) {
      movementIdGroup[item!.id!] = {
        id: item?.id!,
        version: item?.version!,
        measures: [item?.measures!]
      };
      return movementIdGroup;
    }
    movementIdGroup[item!.id!].measures.push(item?.measures!);
    return movementIdGroup;
  }, {});

  return Object.values(movementMeasureUpdateGroup);
};

type MovementMeasuresProps = {
  movementGroup: TMovementGroup;
  editDisabled: boolean;
  onClose: () => void;
};

/**
 *
 * @param param0
 * @returns
 */
export const MovementMeasures = ({
  movementGroup,
  editDisabled,
  onClose
}: MovementMeasuresProps) => {
  const numericTextBoxPropsCallback = useCallback(
    (numericTextBoxProps: NumericTextBoxProps) => ({
      ...numericTextBoxProps,
      spinners: false
    }),
    []
  );
  const { picklists } = usePicklists();
  const [movementMeasures, setMovementMeasures] = useState<TMovementMeasureGroup[]>();

  const { loading, error, refetch } = useQuery<Response>(QUERY_MOVEMENT_MEASURES, {
    fetchPolicy: "no-cache",
    variables: { ids: movementGroup.movements.map(m => m.id) },
    onCompleted: data => onCompleted(data.movements)
  });

  const [updateMeasures, { loading: loadingMeasures, error: errorMeasures }] =
    useMutation<Response>(UPDATE_MOVEMENT_MEASURES, {
      onCompleted: data => {
        onCompleted(data.movements);
      }
    });

  const onCompleted = (movements: Partial<TMovement[]>) => {
    setMovementMeasures(transformInput(movements));
  };

  const onReset = () => {
    refetch({ ids: movementGroup.movements.map(m => m.id) });
  };

  const itemChange = (e: GridItemChangeEvent) => {
    setMovementMeasures(measures =>
      measures?.map(m => {
        if (m.id === e.dataItem.id) {
          return { ...m, [e.field!]: e.value };
        }
        return m;
      })
    );
  };

  const onSave = () => {
    if (movementMeasures) {
      const measures = transformOutput(movementMeasures);
      updateMeasures({
        variables: {
          measures
        }
      });
    }
  };

  const onSaveAndClose = () => {
    onSave();
    onClose();
  };
  if (loading || loadingMeasures) {
    return <InlineLoadingPanel />;
  }
  if (error) {
    return <ApolloErrorViewer error={error} />;
  }
  if (errorMeasures) {
    return <ApolloErrorViewer error={errorMeasures} />;
  }

  return (
    <div className={"content-wrapper"}>
      <div className={"grid-wrapper"}>
        <div className={"card-container"}>
          <NumericTextBoxPropsContext.Provider value={numericTextBoxPropsCallback}>
            <Grid
              data={movementMeasures}
              resizable
              dataItemKey="code"
              editField="inEdit"
              onItemChange={itemChange}>
              <GridToolbar>
                <div style={{ flexGrow: "1" }}>
                  <Button
                    disabled={editDisabled}
                    onClick={onReset}
                    style={{ backgroundColor: "#ffc600" }}>
                    Reset
                  </Button>
                </div>
                <Button
                  onClick={onSave}
                  disabled={editDisabled || (movementMeasures?.length ?? 0) <= 0}>
                  Save
                </Button>
                <Button
                  onClick={onSaveAndClose}
                  disabled={editDisabled || (movementMeasures?.length ?? 0) <= 0}>
                  Save & Close
                </Button>
                <Button themeColor={"primary"} onClick={onClose}>
                  Close
                </Button>
              </GridToolbar>
              <GridNoRecords>
                <div style={{ color: "gray" }}>There is no data available</div>
              </GridNoRecords>
              <GridColumn
                title="Measurement Type"
                field="measurementType"
                cell={getCell(picklists?.measurementTypes)}
              />
              <GridColumn
                title="Unit of Measure"
                field="unitOfMeasure"
                cell={getCell(picklists?.unitOfMeasures)}
              />
              <GridColumn title="value" field="value" editor="numeric" />
            </Grid>
          </NumericTextBoxPropsContext.Provider>
        </div>
      </div>
    </div>
  );
};
