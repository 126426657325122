import { combineReducers } from "redux";
import forecastReducer from "../forecast/state/forecastReducer";
import notificationsReducer from "../notification/state/notificationsReducer";
import { IAppState } from "./IReduxState";

const initialState: unknown = {};

export default combineReducers({
  app: appReducer,
  forecast: forecastReducer,
  notification: notificationsReducer
});

function appReducer(state = initialState, action: { type: string }): IAppState {
  // The reducer normally looks at the action type field to decide what happens
  if (action.type === "@@INIT") {
    // We need to return a new state object
    // that has all the existing state data
    // but has a new array for the `search` field
    return {
      ...(state as IAppState)
    };
  }

  // If this reducer doesn't recognize the action type, or doesn't
  // care about this specific action, return the existing state unchanged
  return state as IAppState;
}
