import { useLazyQuery } from "@apollo/client";
import { MutationFetchPolicy } from "@apollo/client/core/watchQueryOptions";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Label } from "@progress/kendo-react-labels";
import { loader } from "graphql.macro";
import { useEffect, useState } from "react";
import { ApolloErrorViewer } from "shared/components/ApolloErrorViewer";
import InlineLoadingPanel from "shared/components/InlineLoadingPanel";
import { GqlResponse, TLinkedTicket, TMovementGroup, TTicket } from "ticketing/ticketing.types";
import { LinkedTicket } from "./LinkedTicket";
import "./movements.css";
const LINKED_TICKETS = loader("../../ticketing-graphql/linkedTickets.graphql");
type TMovementTicket = {
  tickets: TLinkedTicket[];
};
type LinkedTicketsResponse = GqlResponse<TMovementTicket, "movement">;
const FETCH_POLICY_NO_CACHE = {
  fetchPolicy: "no-cache" as MutationFetchPolicy
};
const transformLinkedTickets = (tickets: TLinkedTicket[]) => {
  return tickets.map(t => ({ ...t, startDate: new Date(t.startDate) }));
};

const LinkedTicketContainer = ({
  activeMovementGroup,
  disableLinks,
  onUnlinkTickets,
  onShowTicketRevisions,
  onEditTicket,
  refreshTickets,
  showDocument
}: {
  activeMovementGroup?: TMovementGroup | null;
  disableLinks: boolean;
  onUnlinkTickets: (ticket: TLinkedTicket[]) => void;
  onShowTicketRevisions: (ticketId: string) => void;
  onEditTicket: (ticket: TTicket) => void;
  refreshTickets?: number;
  showDocument: (documentId: string) => void;
}) => {
  const [fetchLinkedTicketsOfMovement, { loading, error }] =
    useLazyQuery<LinkedTicketsResponse>(LINKED_TICKETS, {
      ...FETCH_POLICY_NO_CACHE,
      onCompleted: data => setLinkedTickets(transformLinkedTickets(data.movement.tickets))
    });
  const [linkedTickets, setLinkedTickets] = useState<TLinkedTicket[] | null>();
  const [unlinkTicketsShowConfirmation, setUnlinkTicketsShowConfirmation] = useState(false);

  useEffect(() => {
    setLinkedTickets([]);
    const movementId =
      activeMovementGroup?.activeChild?.id ?? activeMovementGroup?.activeMovement.id;
    if (movementId) {
      fetchLinkedTicketsOfMovement({
        variables: {
          movementId
        }
      });
    }
  }, [
    activeMovementGroup?.activeMovement,
    activeMovementGroup?.activeChild,
    refreshTickets,
    fetchLinkedTicketsOfMovement
  ]);

  const onUnlinkTicket = (ticket: TLinkedTicket) => onUnlinkTickets([ticket]);

  const unlinkAllTickets = () => {
    setUnlinkTicketsShowConfirmation(false);
    if (linkedTickets) {
      onUnlinkTickets(linkedTickets);
    }
  };
  const cancelUnlinkAllTickets = () => setUnlinkTicketsShowConfirmation(false);

  const title = `Linked Tickets (Count:${linkedTickets?.length ?? 0})`;
  return (
    <>
      <div className="container-component-title" style={{ display: "flex" }}>
        <Label
          style={{
            display: "flex",
            alignItems: "center",
            flexGrow: "1"
          }}>
          {title}
        </Label>
        <Button
          fillMode="flat"
          themeColor={"primary"}
          title="Unlink all tickets"
          disabled={disableLinks}
          icon="close-outline"
          onClick={() => setUnlinkTicketsShowConfirmation(true)}
          hidden={
            (linkedTickets?.length ?? 0) === 0 || activeMovementGroup?.activeChild != null
          }></Button>
      </div>
      <div>
        {loading && <InlineLoadingPanel />}
        {error && <ApolloErrorViewer error={error} />}
        {!loading && (linkedTickets?.length ?? 0) === 0 && (
          <div>
            <span className="availabel-tkt-container-wrapper">No data available.</span>
          </div>
        )}
        {linkedTickets
          ?.sort((t1, t2) => t1.id.localeCompare(t2.id))
          ?.map(lt => {
            return (
              <LinkedTicket
                activeMovementGroup={activeMovementGroup}
                ticket={lt}
                key={lt.id}
                disableLinks={disableLinks}
                onShowTicketRevisions={onShowTicketRevisions}
                onUnlinkTicket={onUnlinkTicket}
                onEditTicket={onEditTicket}
                showDocument={showDocument}
              />
            );
          })}
      </div>
      {unlinkTicketsShowConfirmation && (
        <Dialog title={"Unlink Tickets"} onClose={cancelUnlinkAllTickets}>
          <div className="component-title" style={{ padding: "16px" }}>
            Are you sure you want to unlink all tickets linked to this Movement?
          </div>
          <DialogActionsBar>
            <Button icon="cancel" onClick={cancelUnlinkAllTickets}>
              Cancel
            </Button>
            <Button themeColor={"primary"} icon="check" onClick={unlinkAllTickets}>
              Unlink
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}
    </>
  );
};

export default LinkedTicketContainer;
