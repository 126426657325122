import { ApolloError, useMutation, useQuery } from "@apollo/client";
import {
  Button,
  FormField,
  Modal,
  Select,
  Sentiments,
  TextInput,
  Variants
} from "@sede-x/shell-ds-react-framework";
import {
  Add,
  MinusCircle
} from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";
import {
  TComponentsFilter,
  TProductBlendFetchComponentsData,
  TProductBlendFetchData
} from "carbonIQ/carbonIQtypes";
import { loader } from "graphql.macro";
import { useEffect, useState, WheelEvent } from "react";
import { ApolloErrorViewer } from "shared/components/ApolloErrorViewer";
import LoadingPanel from "shared/components/LoadingPanel";
import { GqlResponse } from "types";

const CarbonGenericProductsList = loader("../graphql/lookupComponents.graphql");

const ciAddNewGenericProduct = loader("../graphql/mutation-CarbonAddNewGenericProduct.graphql");

const ciUpdateGenericProduct = loader("../graphql/mutation-CarbonUpdateGenericProduct.graphql");

type TComponentsFilterByResponse = GqlResponse<TComponentsFilter[], "ciComponentsFilterBy">;

const AddOrUpdateProductBlend = ({
  details,
  onClose,
  onSubmit
}: {
  details: TProductBlendFetchData | null;
  onClose: () => void;
  onSubmit: () => void;
}) => {
  const numberInputOnWheelPreventChange = (e: WheelEvent<HTMLInputElement>) => {
    // Prevent the input value change
    (e.target as HTMLInputElement).blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    // Refocus immediately, on the next tick (after the current function is done)
    setTimeout(() => (e.target as HTMLInputElement).focus(), 0);
  };
  const [components, setComponents] = useState<TComponentsFilter[]>([]);

  //Fetching Carbon IQ  Product Blend Components List
  const { loading: lCIQDocument, error: eCIQDocument } = useQuery<TComponentsFilterByResponse>(
    CarbonGenericProductsList,
    {
      onCompleted: data => setComponents(data.ciComponentsFilterBy)
    }
  );

  const [totalSumOfPercentage, setTotalSumOfPercentage] = useState(0);

  const isStateAdd = details === null || details === undefined;

  // used spread operator to avoid call be refrence
  const [preFillBlendPercentDetails, setPreFillBlendPercentDetails] = useState<
    TProductBlendFetchComponentsData[] | []
  >(!details ? [] : [...details.components]);

  const [preFillProductName, setPreFillProductName] = useState(isStateAdd ? "" : details?.name);

  useEffect(() => {
    const totalPercent = preFillBlendPercentDetails.reduce(
      (total, detail) => total + detail.blendPercent,
      0
    );
    setTotalSumOfPercentage(Math.round((totalPercent + Number.EPSILON) * 100) / 100);
  }, [preFillBlendPercentDetails]);

  // Add New Generic Product
  const [
    addNewGenericProduct,
    { loading: addNewGenericProductLoading, error: addNewGenericProductlError }
  ] = useMutation(ciAddNewGenericProduct);

  // Update New Generic Product
  const [
    updateGenericProduct,
    { loading: updateGenericProductLoading, error: updateGenericProductError }
  ] = useMutation(ciUpdateGenericProduct);

  const handleDeleteBlend = (index: number) => {
    preFillBlendPercentDetails.splice(index, 1);
    setPreFillBlendPercentDetails([...preFillBlendPercentDetails]);
  };

  const isLoading = [
    lCIQDocument,
    addNewGenericProductLoading,
    updateGenericProductLoading
  ].some(elm => elm);

  const isError = [eCIQDocument, addNewGenericProductlError, updateGenericProductError].some(
    e => e
  );
  const errors = [eCIQDocument, addNewGenericProductlError, updateGenericProductError].filter(
    (e): e is ApolloError => Boolean(e)
  );

  return (
    <Modal
      title={(isStateAdd ? "Add New" : "Update") + " Product"}
      // description= 'You should accept this fake text if you want to continue',
      width={"700px"}
      height={"600px"}
      mask={true}
      open={true}
      maskClosable={false}
      onClose={onClose}>
      {isLoading && <LoadingPanel />}
      {isError && <ApolloErrorViewer error={errors} />}
      {!isStateAdd && <p>Update product</p>}
      <form
        onSubmit={event => {
          event.preventDefault();
          const tempdata = {
            name: preFillProductName?.trim(),
            components: preFillBlendPercentDetails
              .filter(elm => elm.blendPercent >= 0)
              .map(elm => ({
                blendPercent: elm.blendPercent,
                componentId: elm.id
              }))
          };

          isStateAdd
            ? addNewGenericProduct({
                variables: {
                  product: tempdata
                },
                onCompleted: onSubmit
              })
            : updateGenericProduct({
                variables: {
                  product: {
                    ...tempdata,
                    id: details?.id,
                    version: details?.version
                  }
                },
                onCompleted: onSubmit
              });
        }}>
        <FormField
          size={"medium"}
          id="product-name-label"
          label="Generic Product Name *"
          bottomLeftHelper={{
            content: preFillProductName === "" ? "Field can't be blank." : "",
            sentiment: Sentiments.Negative
          }}>
          <TextInput
            onChange={event => setPreFillProductName(event.target.value)}
            invalid={preFillProductName === ""}
            value={preFillProductName}
            required
          />
        </FormField>

        {preFillBlendPercentDetails.map((element, index) => {
          return (
            <div className="form-flex-container" key={"form-flex-container_" + element.id}>
              <div className="left">
                <FormField
                  size={"medium"}
                  id="generic-product-name-label"
                  label={`Blend ${index + 1}`}
                  mandatory={true}
                  key={element.id}>
                  <Select
                    options={components
                      .filter((elm: TComponentsFilter) => {
                        return !preFillBlendPercentDetails
                          .map((el: TProductBlendFetchComponentsData) => el.id)
                          .includes(elm.id);
                      })
                      .map((prod: TComponentsFilter) => ({
                        value: prod.name,
                        label: prod.name,
                        key: prod.id
                      }))}
                    size={"medium"}
                    id="generic-product-name-input"
                    placeholder="Select a product"
                    optionLabelProp="label"
                    filterOption={true}
                    optionFilterProp="label"
                    labelInValue={true}
                    allowClear={false}
                    value={preFillBlendPercentDetails[index]?.name ?? undefined}
                    onChange={data => {
                      preFillBlendPercentDetails.splice(index, 1, {
                        id: data.key,
                        name: data.value,
                        blendPercent: 0
                      });
                      setPreFillBlendPercentDetails([...preFillBlendPercentDetails]);
                    }}
                  />
                </FormField>
              </div>
              <div className="right">
                <span className="delete-icon" onClick={() => handleDeleteBlend(index)}>
                  <MinusCircle fill={"#ff0000"} />
                </span>
                <FormField size={"medium"} key={"11"} id={"product-percent-11"} label={" (%)"}>
                  <TextInput
                    type={"number"}
                    value={preFillBlendPercentDetails[index].blendPercent ?? 0}
                    max={100}
                    min={0}
                    step=".01"
                    onWheel={numberInputOnWheelPreventChange}
                    onChange={event => {
                      preFillBlendPercentDetails.splice(index, 1, {
                        id: preFillBlendPercentDetails[index].id,
                        name: preFillBlendPercentDetails[index].name,

                        blendPercent:
                          Math.round((Number(event.target.value) + Number.EPSILON) * 100) / 100
                      });
                      setPreFillBlendPercentDetails([...preFillBlendPercentDetails]);
                    }}
                  />
                </FormField>
              </div>
            </div>
          );
        })}

        {preFillBlendPercentDetails.length < components.length && (
          <div>
            <Button
              size={"xsmall"}
              icon={<Add />}
              variant={Variants.Outlined}
              onClick={() => {
                setPreFillBlendPercentDetails([
                  ...preFillBlendPercentDetails,
                  { id: null, name: null, blendPercent: 0 }
                ]);
              }}>
              Add New Blend
            </Button>
          </div>
        )}

        {preFillBlendPercentDetails.length > 0 && (
          <p>Total percentage: {totalSumOfPercentage} %</p>
        )}
        <div className="from-button-wrap">
          <Button variant={Variants.Outlined} onClick={onClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={totalSumOfPercentage !== 100 || preFillProductName === ""}>
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default AddOrUpdateProductBlend;
