import { ApolloError } from "@apollo/client";
import { Alert, Sentiments, Sizes, Text } from "@sede-x/shell-ds-react-framework";
import { useEffect, useRef, useState } from "react";

type TApolloErrorViewerProps = {
  error?: ApolloError | ApolloError[];
  label?: string;
  customErrorMessage?: string;
  autoDismissable?: boolean;
  timeOutMilli?: number;
  onClose?: () => void;
};

export const ApolloErrorViewer = (props: TApolloErrorViewerProps) => {
  const { error, label, customErrorMessage, onClose } = props;
  const autoDismissableTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const errors = Array.isArray(error) ? error : [error];
  const [showError, setShowError] = useState(false);

  const graphQLErrorMessages = new Set(
    errors
      .flatMap(e => e?.graphQLErrors?.map(graphqlError => graphqlError.message))
      .filter(Boolean)
  );

  const netWorkErrorMessages = new Set(
    errors.flatMap(e => e?.networkError?.message).filter(Boolean)
  );

  const errorMessages = new Set(errors.flatMap(e => e?.message).filter(Boolean));

  useEffect(() => {
    setShowError(true);
    if (autoDismissableTimeout.current) {
      clearTimeout(autoDismissableTimeout.current);
    }

    autoDismissableTimeout.current = props.autoDismissable
      ? setTimeout(() => {
          props.onClose?.();
          setShowError(false);
        }, props.timeOutMilli)
      : null;

    // cleanup function
    return () => {
      if (autoDismissableTimeout.current !== null) {
        clearTimeout(autoDismissableTimeout.current);
      }
    };
  }, [props]);

  if (!showError) {
    return <></>;
  }

  return (
    <Alert
      size={Sizes.Small}
      label={label}
      sentiment={Sentiments.Negative}
      solidBgColor
      iconVisibility={false}
      dismissible={true}
      onDismissClick={onClose}>
      {customErrorMessage && (
        <Text size="small" type="span">
          {customErrorMessage}
        </Text>
      )}

      {[...graphQLErrorMessages].map(msg => (
        <Text size="small" key={msg} type="span">
          {msg}
        </Text>
      ))}

      {[...netWorkErrorMessages].map(msg => (
        <Text size="small" key={msg} type="span">
          `[Network error]: ${msg}`
        </Text>
      ))}

      {[...errorMessages].map(msg => (
        <Text size="small" key={msg} type="span">
          {msg}
        </Text>
      ))}
    </Alert>
  );
};
