import {
  Button,
  Checkbox,
  FormField,
  Modal,
  Select,
  Sizes,
  Variants
} from "@sede-x/shell-ds-react-framework";
import { useState } from "react";
import { CIQOption, TProductsFetchData, TProductsFilterData } from "../carbonIQtypes";

const ProductSearch = ({
  onClose,
  onSubmit,
  masterData
}: {
  onClose: () => void;
  onSubmit: (data: TProductsFilterData) => void;
  masterData: TProductsFetchData[];
}) => {
  const gsapCodeList = masterData.map(elm => {
    return {
      value: elm.gsapCode,
      label: elm.gsapCode + " / " + elm.gsapName,
      key: elm.id
    };
  });

  const genericProductNameList = [
    ...new Map(masterData.map(item => [item.genericProduct?.name, item])).values()
  ].map(elm => {
    return {
      value: elm?.genericProduct?.name,
      label: elm?.genericProduct?.name,
      key: elm?.genericProduct?.id
    };
  });

  const [activeFilter, setActiveFilter] = useState<boolean>(true);
  const [selectedFilter, setSelectedFilter] = useState<{
    genericProduct?: CIQOption[];
    gsap?: CIQOption[];
    activeFilter?: boolean;
  }>();

  return (
    <Modal
      title="Search Product"
      width={"700px"}
      height={"600px"}
      mask={true}
      open={true}
      onClose={onClose}
      maskClosable={false}>
      <form
        onSubmit={event => {
          event.preventDefault();
          onSubmit({
            selectedGsap: selectedFilter?.gsap ?? null,
            selectedGenericPRoduct: selectedFilter?.genericProduct ?? null,
            selectedActive: !!activeFilter
          });
        }}>
        <FormField size={"medium"} id="gsap-code-label" label="GSAP Code / GSAP Name">
          <Select
            options={gsapCodeList}
            size={"medium"}
            id="gsap-code-select"
            placeholder="Select GSAP Code / GSAP Name"
            optionLabelProp="label"
            filterOption={true}
            optionFilterProp="label"
            mode="tags"
            labelInValue={true}
            onChange={(data: CIQOption[]) => {
              setSelectedFilter({
                ...selectedFilter,
                gsap: data.filter(o => Boolean(o.key))
              });
            }}
          />
        </FormField>

        <FormField size={"medium"} id="gsap-name-label" label="Generic Product Name">
          <Select
            options={genericProductNameList}
            size={"medium"}
            id="gsap-name-select"
            placeholder="Select Generic Product Name"
            optionLabelProp="label"
            filterOption={true}
            optionFilterProp="label"
            mode="tags"
            labelInValue={true}
            onChange={(data: CIQOption[]) => {
              setSelectedFilter({
                ...selectedFilter,
                genericProduct: data.filter(o => Boolean(o.key))
              });
            }}
          />
        </FormField>
        <Checkbox
          label="Active"
          size={Sizes.Large}
          checked={activeFilter}
          onChange={() => {
            setActiveFilter(!activeFilter);
          }}
        />

        <div style={{ paddingBottom: "200px" }}></div>

        <div className="from-button-wrap">
          <Button variant={Variants.Outlined} onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit">Search</Button>
        </div>
      </form>
    </Modal>
  );
};

export default ProductSearch;
