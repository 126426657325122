import { Button, FormField, Modal, Select, Variants } from "@sede-x/shell-ds-react-framework";
import { useState } from "react";

import { CIQOption, TCICustomer, TCustomerFilter } from "../carbonIQtypes";

const CustomerLookup = ({
  onClose,
  onSubmit,
  customerData
}: {
  onClose: () => void;
  onSubmit: (data: TCustomerFilter) => void;
  customerData: TCICustomer[];
}) => {
  const customersList = customerData.map(cust => ({
    value: cust.displayName,
    label: cust.displayName,
    id: cust.id
  }));
  const [selectedCustomer, setSelectedCustomer] = useState<CIQOption[]>([]);

  return (
    <Modal
      title="Search for Customer(s)"
      width={"700px"}
      height={"600px"}
      mask={true}
      open={true}
      maskClosable={false}
      onClose={onClose}>
      <form
        onSubmit={event => {
          event.preventDefault();
          onSubmit({
            customerIDs: selectedCustomer
          });
        }}>
        <FormField size={"medium"} id="customer-name" label="Customer Name">
          <Select
            options={customersList}
            size={"medium"}
            id="customer-name"
            placeholder="Name, ID"
            optionLabelProp="label"
            mode="tags"
            filterOption={true}
            optionFilterProp="value"
            onChange={(data: CIQOption[]) =>
              setSelectedCustomer(data.filter(o => Boolean(o.key)))
            }
          />
        </FormField>
        <div style={{ paddingBottom: "200px" }}></div>
        <div className="from-button-wrap">
          <Button variant={Variants.Outlined} onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit">Search</Button>
        </div>
      </form>
    </Modal>
  );
};

export default CustomerLookup;
