import { useQuery } from "@apollo/client";
import {
  Badge,
  BaseTable,
  Button,
  Sentiments,
  Sizes,
  Variants
} from "@sede-x/shell-ds-react-framework";
import {
  AddSquare,
  CrossCircle,
  EditOutlined,
  Search
} from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";
import {
  CellContext,
  ColumnDef,
  RowData,
  SortingState,
  getPaginationRowModel,
  getSortedRowModel
} from "@tanstack/react-table";
import { MackPrivilege } from "auth";
import { useMackAuth } from "auth/AuthenticationProvider";
import { ROWS_PER_PAGE } from "carbonIQ/CarbonIQConstants";
import {
  CIQOption,
  TProductBlendFetchComponentsData,
  TProductBlendFetchData
} from "carbonIQ/carbonIQtypes";
import { loader } from "graphql.macro";
import { useState } from "react";
import { ApolloErrorViewer } from "shared/components/ApolloErrorViewer";
import GlobalHeader from "shared/components/GlobalHeader";
import LoadingPanel from "shared/components/LoadingPanel";
import NoDataTableImage from "shared/components/basetable/NoDataTableImage";
import ColumnText from "shared/components/basetable/cells/ColumnText";
import { GqlResponse } from "types";
import AddOrUpdateProductBlend from "./AddOrUpdateProductBlend";
import ProductBlendSearch from "./ProductBlendSearch";

const carbonGenericProductsFilterBy = loader(
  "../graphql/query-CarbonGenericProductsBlendFilterBy.graphql"
);

type TGenericProductsFilterByResponse = GqlResponse<
  TProductBlendFetchData[],
  "ciGenericProductsFilterBy"
>;

type TButtonClickHandler<TData extends RowData> = (val: TData) => void;

const gridColumns: ColumnDef<TProductBlendFetchData>[] = [
  {
    header: "Generic Product Name",
    accessorKey: "name",
    enableSorting: true,
    size: 300,
    cell: arg => <ColumnText width={"300px"}>{arg.getValue() as string}</ColumnText>
  },

  {
    header: "Component",
    accessorKey: "components",
    size: 500,
    enableSorting: true,
    cell: arg => {
      const tempVal = arg.getValue() as TProductBlendFetchComponentsData[];
      return (
        <p>
          {tempVal
            .filter((elm: { blendPercent: number }) => elm.blendPercent > 0)
            .map((elm: TProductBlendFetchComponentsData) => {
              return `${elm.name} - ${elm.blendPercent}%`;
            })
            .join(", ")}
        </p>
      );
    }
  }
];

const gridColumnsGenerate = (
  clickcallback: TButtonClickHandler<TProductBlendFetchData>,
  editSelected: number | undefined,
  hasEditPermission: boolean | undefined = false
) => {
  if (!hasEditPermission) {
    return gridColumns;
  } else {
    const additionalGridColumn = {
      header: "",
      accessorKey: "id",
      cell: (arg: CellContext<TProductBlendFetchData, unknown>) => (
        <Button
          className={arg.row.original.id === editSelected ? "editedRow" : ""}
          title="Edit"
          size={Sizes.Small}
          onClick={() => clickcallback(arg.row.original)}>
          <EditOutlined />
          Edit
        </Button>
      ),
      size: 40,
      enableSorting: false
    };
    return [...gridColumns, additionalGridColumn];
  }
};

const CarbonIQProductBlend = () => {
  //Fetching Carbon IQ  Product Blend data
  const {
    loading: lCIQDocument,
    error: eCIQDocument,
    data: genericProducts,
    refetch
  } = useQuery<TGenericProductsFilterByResponse>(carbonGenericProductsFilterBy);

  const { mackUser } = useMackAuth();
  const hasAddProductBlendPrivilege = mackUser?.hasPrivilege(MackPrivilege.AddCIGenericProduct);
  const hasModifyProductBlendPrivilege = mackUser?.hasPrivilege(
    MackPrivilege.ModifyCIGenericProduct
  );

  const [searchOpen, setSearchOpen] = useState(false);
  const [selectedSearchFilter, setSelectedSearchFilter] = useState<CIQOption[]>([]);
  const [editSelected, setEditSelected] = useState<number>();
  const [addOrUpdateOpen, setAddOrUpdateOpen] = useState(false);
  const [updateOpenProductDetails, setUpdateOpenProductDetails] =
    useState<TProductBlendFetchData | null>(null);

  const columns = gridColumnsGenerate(
    arg => {
      setEditSelected(arg.id);
      setUpdateOpenProductDetails(arg);
      setAddOrUpdateOpen(true);
    },
    editSelected,
    hasModifyProductBlendPrivilege
  );

  const [sorting, setSorting] = useState<SortingState>([{ desc: false, id: "name" }]);

  const [pagination, setPagination] = useState({
    pageIndex: 0, //initial page index
    pageSize: ROWS_PER_PAGE //default page size
  });

  const tableOptions = {
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      sorting,
      pagination
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    autoResetPageIndex: false
  };

  const handlAddOrUpdateSubmit = () => {
    refetch();
    setAddOrUpdateOpen(false);
  };

  const handleAddNewProductClick = () => {
    setUpdateOpenProductDetails(null);
    setAddOrUpdateOpen(true);
  };

  const handleSearchSubmit = (data: CIQOption[]) => {
    setSelectedSearchFilter(data);
    setSearchOpen(false);
  };

  const filteredProducts = genericProducts?.ciGenericProductsFilterBy?.filter(
    p => !selectedSearchFilter?.length || selectedSearchFilter.some(fp => fp.key === p.id)
  );

  const isLoading = [lCIQDocument].some(elm => elm);
  const isError = [eCIQDocument].some(e => e);

  return (
    <>
      {isLoading && <LoadingPanel />}
      {isError && <ApolloErrorViewer error={eCIQDocument} />}

      <GlobalHeader
        pageName="Product Blend"
        filterButtonContent={[
          Object.keys(selectedSearchFilter).length > 0 && (
            <span className="customer-Search-current-filter">
              <span className="customer-Search-current-filter-txt">
                {selectedSearchFilter?.map((elm: CIQOption) => elm.value).join(", ")}
              </span>
              <button
                className="customer-Search-reset"
                onClick={() => {
                  setSelectedSearchFilter([]);
                  setPagination({
                    pageIndex: 0,
                    pageSize: ROWS_PER_PAGE
                  });
                }}>
                <CrossCircle />
                Clear Filter
              </button>
            </span>
          )
        ]}
        descriptiontxt="Please use this screen to add Generic Products and their associated components."
        buttonContent={[
          <Badge
            key={1}
            icon={<Search />}
            sentiment={Sentiments.Information}
            variant={Variants.Filled}
            onClick={() => setSearchOpen(!searchOpen)}
          />,
          hasAddProductBlendPrivilege && (
            <Badge
              key={2}
              icon={<AddSquare />}
              sentiment={Sentiments.Information}
              variant={Variants.Filled}
              onClick={() => handleAddNewProductClick()}>
              Add New Product Blend
            </Badge>
          )
        ]}
      />

      <BaseTable
        columns={columns}
        data={filteredProducts}
        className="carboniq-data-table"
        tableOptions={tableOptions}
        key={"productsBlend-table-key"}
        emptyStateProps={{ image: <NoDataTableImage /> }}
      />

      {addOrUpdateOpen && (
        <AddOrUpdateProductBlend
          onClose={() => setAddOrUpdateOpen(!addOrUpdateOpen)}
          onSubmit={() => handlAddOrUpdateSubmit()}
          details={updateOpenProductDetails}
        />
      )}
      {searchOpen && (
        <ProductBlendSearch
          onClose={() => setSearchOpen(false)}
          onSubmit={handleSearchSubmit}
          masterData={genericProducts?.ciGenericProductsFilterBy}
        />
      )}
    </>
  );
};

export default CarbonIQProductBlend;
